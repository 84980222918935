import styles from '@/styles/footer.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

export default function Footer() {
  function handlePageJump(url: string, e: any) {
    // 阻止a标签默认行为
    e.preventDefault()

    window.location.href = `${url}`
  }

  const helpData = [
    {
      title: 'Company Info',
      helpList: [
        { title: 'About Us', link: `${apiDomain}/article/AboutUs` },
        { title: 'Terms & Conditions', link: '/article/TermsAndConditions' },
        { title: 'Privacy Statement', link: '/article/PrivacyStatement' },
        { title: 'Warranty Policy', link: '/article/CrazysalesWarrantyPolicy' },
        { title: 'Site Map', link: '/sitemap' }
      ]
    }, {
      title: 'Customer Service',
      helpList: [
        { title: 'Contact Us', link: '/contact-us' },
        { title: 'My Account', link: '/myaccount' },
        { title: 'Frequently Asked Questions(FAQ)', link: '/legal/faq' },
        { title: 'Afterpay', link: '/afterpay' },
        { title: 'Crazy Sales reviews', link: '/review/' },
        { title: 'Zippay', link: '/zippay' }
      ]
    }, {
      title: 'Shipping & Returns',
      helpList: [
        { title: 'Shipping Guide', link: '/legal/delivery-brand' },
        { title: 'Return Policy', link: '/article/CrazysalesReturnPolicy' },
        { title: 'Track Your Order', link: '/myaccount/order/0' }
      ]
    }, {
      title: 'Resources',
      helpList: [
        { title: 'Follow us on Twitter', link: 'http://twitter.com/crazysalesaus' },
        { title: 'Become a Supplier', link: '/supplier' },
        { title: 'Read our Blog!', link: 'http://blog.crazysales.com.au' },
        { title: 'Newsletter', link: '/newsletters/index' },
        { title: 'Affiliate Program', link: '/article/affiliateProgram' },
        { title: 'Buying Guides', link: '/buying-guides/' },
        { title: 'Mobile Site', link: 'https://m.crazysales.com.au' }
      ]
    }
  ]
  const helpItems = helpData.map(item =>
    <div key={item.title} className={styles['help-item']}>
      <div className={styles['help-title']}>{item.title}</div>
      <ul className={styles['child-list']}>
        {
          item.helpList.map(help => (
            <li key={help.link}>
              <Link 
                key={help.title}
                href={help.link}
                passHref
                legacyBehavior
              >
                <a 
                  onClick={(e: any) => handlePageJump(`${help.link}`, e)} 
                  rel="noopener noreferrer"
                >{help.title}</a>
              </Link>
            </li>
          ))
        }
      </ul>
    </div>
  )
  
  const paymentLogoList = [
    {
      title: 'PayPal',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/pay/icon-payment-logo-paypal-Color.svg',
      width: 65,
      height: 37
    }, {
      title: 'visa',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/pay/icon-payment-logo-visa-Color.svg',
      width: 55,
      height: 31
    }, {
      title: 'Master',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/pay/icon-payment-logo-Master-Color.svg',
      width: 55,
      height: 31
    }, {
      title: 'Bpay',
      imageUrl: '	https://img.crazysales.com.au/images/2022v/img/pay/icon-payment-logo-Bpay-Color.svg',
      width: 50,
      height: 28
    }, {
      title: 'AMEX',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/pay/icon-payment-logo-AMEX-Color.svg',
      width: 50,
      height: 28
    }, {
      title: 'zip',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/pay/icon-payment-logo-zip-Color.svg',
      width: 55,
      height: 31
    }, {
      title: 'afterpay',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/pay/icon-payment-logo-afterpay-Color.svg',
      width: 80,
      height: 45
    }
  ]
  const paymentLogoItem = paymentLogoList.map(item =>
    <Image 
      key={item.imageUrl}
      className={styles['payment-item']}
      alt={item.title}
      width={item.width}
      height={item.height}
      src={item.imageUrl} />
  )
  
  const honourLogoList = [
    {
      title: 'Top10Website',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/index/Top10Website_2008.png',
      width: 69,
      height: 78
    }, {
      title: 'Top10Website',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/index/Top10Website_2009.png',
      width: 69,
      height: 78
    }, {
      title: 'Top10Website',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/index/Top10Website_2010.png',
      width: 69,
      height: 78
    }, {
      title: 'Top10Website',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/index/Top10Website_2010-2.png',
      width: 69,
      height: 78
    }, {
      title: 'Secure',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/public/Secure_logo-Comodo.png',
      width: 74,
      height: 48
    }, {
      title: 'Secure',
      imageUrl: 'https://img.crazysales.com.au/images/2022v/img/public/Secure_logo-Norton.png',
      width: 69,
      height: 78
    }
  ]
  const honourLogoItem = honourLogoList.map(item =>
    <Image 
      key={item.imageUrl}
      className={styles['honour-item']}
      alt={item.title}
      width={item.width}
      height={item.height}
      src={item.imageUrl} />
  )

  return (
    <div className={styles['footer-box']} id="footerBox">
      <div className={styles['help-box']}>
        <div className={styles['content']}>
          {helpItems}
        </div>
      </div>
      <div className={styles['information-box']}>
        <div className={styles['content']}>
          <div className={styles['logo']}>
            <Link 
              href="/"
              
              passHref
              legacyBehavior
            >
              <a title="logo">
                <Image
                  width={246}
                  height={71}
                  alt="logo"
                  src='https://img.crazysales.com.au/images/2022v/img/logo.svg' />
              </a>
            </Link>
          </div>

          <div className={styles['payment-title']}>We proudly accept:</div>

          <div className={styles['payment-logo']}>
            {paymentLogoItem}
          </div>
        </div>

        <div className={styles['honour-list']}>
          {honourLogoItem}
        </div>


        <div className={styles['text']}>
          Copyright @ 2007 - 2024 Crazy Sales 
          <Link 
            href="/"
            passHref
            legacyBehavior
          >
            <a title="logo">Online Shopping Australia</a>
          </Link>
          . All rights reserved.ABN 66 166 623 169 | PO BOX 1016 Springvale, VIC 3171, Australia
        </div>
      </div>
    </div>
  )
}
