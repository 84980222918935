import { useEffect, useState, useRef } from 'react'

export function useClickOutside(ref, callback) {
    const handleClick = (e) => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback()
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick)
        }
    })
}


export function useCallbackState(od) {
    const cbRef = useRef();
    const [data, setData] = useState(od);

    useEffect(() => {
        cbRef.current && cbRef.current(data);
    }, [data]);

    return [data, function (d, callback) {
        setData(d);
        cbRef.current = callback;
    }];
}