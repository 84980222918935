import Cookies from 'js-cookie'

export function getCookie(cookieKey) {
  return Cookies.get(cookieKey)
}

export function setCookie(cookieKey, cookieValue, params) {
  return Cookies.set(cookieKey, cookieValue, params)
}

export function removeCookie(cookieKey) {
  return Cookies.remove(cookieKey)
}
