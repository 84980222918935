import styles from '@/styles/menu.module.scss'
import Image from 'next/image'
import Link from 'next/link'
import { useState, useEffect } from "react"
import {
  CaretDownOutlined,
  MenuOutlined
} from '@ant-design/icons'
import getConfig from 'next/config'
import { useRouter } from 'next/router'

const { publicRuntimeConfig } = getConfig()
const apiDomain = publicRuntimeConfig.apiDomain
const imageDomain = publicRuntimeConfig.imageDomain

interface props {
  wideScreen?: boolean;
  categoryData?: any;
  useChristmasTheme?: boolean;
}

export default function Menu({ wideScreen, categoryData, useChristmasTheme }: props) {
  function handlePageJump(url: string, e: any) {
    // 阻止冒泡
    // e.stopPropagation()

    // 阻止a标签默认行为
    e.preventDefault()


    window.location.href = `${apiDomain}${url}`
  }

  /**
   * 一级分类点击跳转
   * 先判断是否为ipad屏幕分辨率，不是则直接跳转
   * 是ipad则先添加active类名，再次点击才跳转
   */
  const [categoryId, setCategoryId] = useState(0)
  const [showCategoryDetail, setShowCategoryDetail] = useState(false)
  function handleClickMenu(cateId: number, url: string, e: any) {
    // 阻止冒泡
    // e.stopPropagation()

    // 阻止a标签默认行为
    e.preventDefault()

    function isTablet() {
      const mediaQuery = window.matchMedia('(max-width: 1024px)');
      return mediaQuery.matches;
    }

    if (isTablet()) {
      const divElement = document.getElementById(`cate${cateId}`);
      if (
        divElement 
        && divElement.classList.contains("active")
        && categoryId == cateId
      ) {
        window.location.href = `${apiDomain}/${url}`
        divElement.classList.remove('active');
      } else if (divElement) {
        divElement.classList.add('active');
        setCategoryId(cateId)
      }
    } else {
      window.location.href = `${apiDomain}/${url}`
    }
  }

  function handleClickTopMenu(url: string, e: any) {
    // 阻止a标签默认行为
    e.preventDefault()

    window.location.href = `${url}`
  }

  const categorieslist = [
    { name: 'Holiday Deals', icon: 'csicon-category-2975', link: 'holiday-deals/', categoryId: 2975 },
    { name: 'Appliances', icon: 'csicon-category-2107', link: 'appliances/', categoryId: 2107 },
    { name: 'Furniture', icon: 'csicon-category-57', link: 'furniture/', categoryId: 57 },
    { name: 'Home & Garden', icon: 'csicon-category-147', link: 'home-garden/', categoryId: 147 },
    { name: 'Sports & Fitness', icon: 'csicon-category-4', link: 'sports-fitness/', categoryId: 4 },
    { name: 'Pet Supplies', icon: 'csicon-category-73', link: 'pet-supplies/', categoryId: 73 },
    { name: 'Outdoor & Leisure', icon: 'csicon-category-334', link: 'outdoor-leisure/', categoryId: 334 },
    { name: 'Baby, Kids & Toys', icon: 'csicon-category-2959', link: 'baby-kids-toys/', categoryId: 2959 },
    { name: 'Bedding & Bath', icon: 'csicon-category-258', link: 'bedding-bath/', categoryId: 258 },
    { name: 'Health & Beauty', icon: 'csicon-category-62', link: 'health-beauty/', categoryId: 62 },
    { name: 'Tools & Auto', icon: 'csicon-category-2957', link: 'tools-auto/', categoryId: 2957 },
    { name: 'Electronics', icon: 'csicon-category-50', link: 'electronics/', categoryId: 50 },
    { name: 'Novelties & Gifts', icon: 'csicon-category-65', link: 'novelties-gifts/', categoryId: 65 },
    { name: 'Musical Instruments', icon: 'csicon-category-35', link: 'musical-instruments/', categoryId: 35 },
    { name: 'Jewellery', icon: 'csicon-category-991', link: 'jewellery/', categoryId: 991 },
    { name: 'LED Lights', icon: 'csicon-category-3781', link: 'led-lights/', categoryId: 3781 },
    { name: 'Fashion & Bags', icon: 'csicon-category-109', link: 'fashion-bags/', categoryId: 109 },
    { name: 'Computers & IT', icon: 'csicon-category-70', link: 'computers-it/', categoryId: 70 }
  ]

  useEffect(() => {
    categorieslist.forEach(cate => {
      setTimeout(() => {
        waterfall(cate.categoryId)
      }, 30)
    })

    // 避免waterfall没有整理好，做延迟2s才展示二级菜单浮窗
    setTimeout(() => {
      setShowCategoryDetail(true)
      console.log(true);
    }, 500)
  }, [])

  const waterfall = (categoryId: number) => {
    const wfAreaBox = document.querySelector(`#cateDetail${categoryId}`) as HTMLElement;
    const wfArea = document.querySelector(`#cateDetailContent${categoryId}`) as HTMLElement;
    const wfList = Array.from(document.querySelectorAll(`#cateDetailContent${categoryId} > div`));
    const wfListLen = wfList.length;
    const wWidth = window.innerWidth;
    const wfWidth = 180;
    const wfArr: number[] = new Array(wfListLen < 12 || wWidth <= 768 ? 3 : 4).fill(0);
    let fwMaxHeight = 0;
  
    wfList.forEach((item, i) => {
      const currentItem = item as HTMLElement
      const colHeight = item.children.length * 24 + 12;
  
      if (i < wfArr.length) {
        wfArr[i] = colHeight;
        currentItem.style.top = '0'
        currentItem.style.left = `${i * wfWidth}px`
        fwMaxHeight = Math.max(fwMaxHeight, colHeight);
      } else {
        const minHeight = Math.min(...wfArr);
        const minCol = wfArr.indexOf(minHeight);
        wfArr[minCol] += colHeight;
        currentItem.style.top = `${minHeight}px`;
        currentItem.style.left = `${minCol * wfWidth}px`;
        fwMaxHeight = Math.max(fwMaxHeight, minHeight + colHeight);
      }
    })
    wfAreaBox.style.width = `${wfWidth * wfArr.length + 5}px`;
    wfAreaBox.style.height = `${fwMaxHeight}px`;
    wfArea.style.width = `${wfWidth * wfArr.length + 5}px`;
    wfArea.style.height = `${fwMaxHeight}px`;
  }

  interface CategoryDetailItem {
    categoryID: string;
    url: string;
    name: string;
    subCategories?: {
      url: string;
      name: string;
    }[];
  }
  
  const categoriesItems = categorieslist.map(cate => {
    let cateData = categoryData.filter((item:any) => item.categoryID == cate.categoryId)[0].subCategories
    return (
      <div 
        key={cate.categoryId}
        className={styles['categories-item']}
        id={`cate${cate.categoryId}`}
      >
        <Link
          href={`${apiDomain}/${cate.link}`}
          passHref
          legacyBehavior
        >
          <a 
            className={styles['item-title']}
            onClick={(e: any) => handleClickMenu(cate.categoryId, `${cate.link}`, e)} 
            rel="noopener noreferrer"
          >
            <div className={styles['title-content']}>
              <i className={`iconfont ${cate.icon} ${styles['icon-cate']} `} />
              <div className={styles['cate-name']}>{cate.name}</div>
            </div>
          </a>
        </Link>
        <div 
          className={styles['category-detail']} 
          id={`cateDetail${cate.categoryId}`}
          style={showCategoryDetail ? undefined : { visibility: 'hidden' }}
          >
          <div className={styles['category-detail-content']} id={`cateDetailContent${cate.categoryId}`}>
            {
              cateData.map((detailItem: CategoryDetailItem) => {
                return (
                  <div key={detailItem.categoryID} className={styles['categories-box']}>
                    {/* sub分类的标题 */}
                    <Link
                      href={`${apiDomain}/${detailItem.url}`}
                      passHref
                      legacyBehavior
                    >
                      <a 
                        className={styles['category-title']}
                        onClick={(e: any) => handlePageJump(`/${detailItem.url}`, e)}
                        rel="noopener noreferrer"
                      >
                        {detailItem.name}
                      </a>
                    </Link>

                    {/* sub分类的子分类 */}
                    {
                      detailItem.subCategories?.length &&
                      detailItem.subCategories.map(subItem =>
                        <Link
                          key={subItem.url}
                          href={`${apiDomain}/${subItem.url}`}
                          passHref
                          legacyBehavior
                        >
                          <a 
                            onClick={(e: any) => handlePageJump(`/${subItem.url}`, e)}
                            rel="noopener noreferrer"
                          >
                            <div className={styles['sub-category']}>{subItem.name}</div>
                          </a>
                        </Link>
                      )
                    }
                  </div>
                )
              })
            }
          </div>
        </div>
      </div>
    )
  })

  // 菜单列表组件
  const menuList = [
    { name: 'Home', linkTitle: 'Home', link: `${apiDomain}/` },
    { name: 'Clearance', linkTitle: 'clearance', link: `${apiDomain}/clearance` },
    { name: 'Free Shipping', linkTitle: 'free shipping', link: `${apiDomain}/free-shipping` },
    { name: 'New Arrivals', linkTitle: 'new arrivals', link: `${apiDomain}/new-arrivals` },
    { name: 'Hot Deals', linkTitle: 'hot deals', link: `${apiDomain}/hottest-deals` },
    { name: 'Top Review', linkTitle: 'top reviewed', link: `${apiDomain}/top-reviewed-1` }
  ]
  const menuItems = menuList.map(menu =>
    <Link 
      key={menu.name}
      href={menu.link}
      passHref
      legacyBehavior
    >
      <a 
        title={menu.name}
        rel="noopener noreferrer"
        onClick={(e: any) => handleClickTopMenu(`${menu.link}`, e)} 
      >{menu.name}</a>
    </Link>
  )

  // 判断当前页面是否为 首页，首页需要展开分类
  const router = useRouter()
  let showCate = false
  if (router.pathname === '/') {
    showCate = true
  }

  return (
    <div className={styles['menu-box']} id="menu">
      <div className={`${styles['menu-content']} ${ wideScreen ? styles['menu-content-wide'] : ''} `}>
        <div className={`${styles['all-categories']} ${ showCate ? styles['show'] : ''} `}>
          <div className={styles['all-categories-title']}>
            <MenuOutlined className={styles['icon-menu']} />
            All Categories
            <CaretDownOutlined className={styles['icon-down']} />
          </div>

          <div className={styles['categories-list']}>
            {categoriesItems}
          </div>
        </div>

        <div className={styles['menu-list']}>
          {menuItems}
        </div>

        {
          useChristmasTheme &&
          <Image
            className={styles['nav-doll']}
            alt="doll"
            width={109}
            height={152}
            src={`${imageDomain}/christmas-theme/nav-doll.png`} />
          }
      </div>
    </div>
  )
}
