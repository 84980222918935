const categoryData = [
  {
    categoryID: 2975,
    name: "Holiday Deals",
    parent: 1,
    productsCount: 4561,
    path: "1/2975",
    subCategories: [
      {
        categoryID: 2989,
        name: "Christmas Decorations",
        parent: 2975,
        productsCount: 2023,
        path: "1/2975/2989",
        url: "christmas-decorations/",
        subCategories: [
          {
            categoryID: 2991,
            name: "Christmas Trees",
            parent: 2989,
            productsCount: 1123,
            path: "1/2975/2989/2991",
            url: "christmas-trees/",
          },
          {
            categoryID: 2997,
            name: "Christmas Santa",
            parent: 2989,
            productsCount: 36,
            path: "1/2975/2989/2997",
            url: "christmas-santa/",
          },
        ],
      },
      {
        categoryID: 2977,
        name: "Christmas Lights",
        parent: 2975,
        productsCount: 1474,
        path: "1/2975/2977",
        subCategories: [
          {
            categoryID: 2981,
            name: "Solar Christmas Lights",
            parent: 2977,
            productsCount: 100,
            path: "1/2975/2977/2981",
            url: "solar-christmas-lights/",
          },
          {
            categoryID: 2983,
            name: "Christmas Rope Lights",
            parent: 2977,
            productsCount: 8,
            path: "1/2975/2977/2983",
            url: "christmas-rope-lights/",
          },
          {
            categoryID: 2979,
            name: "LED Christmas Lights",
            parent: 2977,
            productsCount: 643,
            path: "1/2975/2977/2979",
            url: "led-christmas-lights/",
          },
        ],
        url: "christmas-lights/",
      },
      {
        categoryID: 2999,
        name: "Christmas Gift Ideas",
        parent: 2975,
        productsCount: 294,
        path: "1/2975/2999",
        url: "christmas-gift-ideas/",
      },
      {
        categoryID: 3867,
        name: "Halloween Costume",
        parent: 2975,
        productsCount: 199,
        path: "1/2975/3867",
        url: "halloween-costume/",
      },
      {
        categoryID: 3865,
        name: "Halloween Decoration",
        parent: 2975,
        productsCount: 381,
        path: "1/2975/3865",
        url: "halloween-decoration/",
      },
      {
        categoryID: 4037,
        name: "Exclusive Offer",
        parent: 2975,
        productsCount: 19,
        path: "1/2975/4037",
        url: "exclusive-offer/",
      },
    ],
    url: "holiday-deals/",
  },
  {
    categoryID: 2107,
    name: "Appliances",
    parent: 1,
    productsCount: 4783,
    path: "1/2107",
    subCategories: [
      {
        categoryID: 4119,
        name: "Carpet Cleaner",
        parent: 2107,
        productsCount: 2,
        path: "1/2107/4119",
        url: "carpet-cleaner/",
      },
      {
        categoryID: 4117,
        name: "Floor Cleaner",
        parent: 2107,
        productsCount: 9,
        path: "1/2107/4117",
        url: "floor-cleaner/",
      },
      {
        categoryID: 4001,
        name: "Cooktops",
        parent: 2107,
        productsCount: 41,
        path: "1/2107/4001",
        url: "cooktops/",
      },
      {
        categoryID: 2119,
        name: "Small Kitchen Appliances",
        parent: 2107,
        productsCount: 1925,
        path: "1/2107/2119",
        subCategories: [
          {
            categoryID: 3021,
            name: "Air Fryer",
            parent: 2119,
            productsCount: 174,
            path: "1/2107/2119/3021",
            url: "air-fryer/",
          },
          {
            categoryID: 2121,
            name: "Blenders",
            parent: 2119,
            productsCount: 114,
            path: "1/2107/2119/2121",
            url: "blenders/",
          },
          {
            categoryID: 3943,
            name: "Dishwasher",
            parent: 2119,
            productsCount: 10,
            path: "1/2107/2119/3943",
            url: "dishwasher/",
          },
          {
            categoryID: 2495,
            name: "Chocolate Fountain",
            parent: 2119,
            productsCount: 8,
            path: "1/2107/2119/2495",
            url: "chocolate-fountain/",
          },
          {
            categoryID: 2123,
            name: "Dehydrators",
            parent: 2119,
            productsCount: 67,
            path: "1/2107/2119/2123",
            url: "dehydrators/",
          },
          {
            categoryID: 2125,
            name: "Fondue Sets",
            parent: 2119,
            productsCount: 2,
            path: "1/2107/2119/2125",
            url: "fondue-sets/",
          },
          {
            categoryID: 2127,
            name: "Grills",
            parent: 2119,
            productsCount: 73,
            path: "1/2107/2119/2127",
            url: "grills/",
          },
          {
            categoryID: 2129,
            name: "Hot Plates",
            parent: 2119,
            productsCount: 32,
            path: "1/2107/2119/2129",
            url: "hot-plates/",
          },
          {
            categoryID: 2131,
            name: "Ice Cream Makers",
            parent: 2119,
            productsCount: 11,
            path: "1/2107/2119/2131",
            url: "ice-cream-makers/",
          },
          {
            categoryID: 2133,
            name: "Ice Makers",
            parent: 2119,
            productsCount: 117,
            path: "1/2107/2119/2133",
            url: "ice-makers/",
          },
          {
            categoryID: 2135,
            name: "Juicers",
            parent: 2119,
            productsCount: 78,
            path: "1/2107/2119/2135",
            url: "juicers/",
          },
          {
            categoryID: 2137,
            name: "Kettles",
            parent: 2119,
            productsCount: 70,
            path: "1/2107/2119/2137",
            url: "kettles/",
          },
          {
            categoryID: 2139,
            name: "Mincers",
            parent: 2119,
            productsCount: 34,
            path: "1/2107/2119/2139",
            url: "mincers/",
          },
          {
            categoryID: 2141,
            name: "Mixers",
            parent: 2119,
            productsCount: 35,
            path: "1/2107/2119/2141",
            url: "mixers/",
          },
          {
            categoryID: 2143,
            name: "Pizza Makers",
            parent: 2119,
            productsCount: 14,
            path: "1/2107/2119/2143",
            url: "pizza-makers/",
          },
          {
            categoryID: 2147,
            name: "Rice Cookers & Steamers",
            parent: 2119,
            productsCount: 41,
            path: "1/2107/2119/2147",
            url: "rice-cookers-steamers/",
          },
          {
            categoryID: 2145,
            name: "Slow Cookers",
            parent: 2119,
            productsCount: 24,
            path: "1/2107/2119/2145",
            url: "slow-cookers/",
          },
          {
            categoryID: 2149,
            name: "Slushie Makers",
            parent: 2119,
            productsCount: 1,
            path: "1/2107/2119/2149",
            url: "slushie-makers/",
          },
          {
            categoryID: 2503,
            name: "Vacuum Sealer",
            parent: 2119,
            productsCount: 169,
            path: "1/2107/2119/2503",
            url: "vacuum-sealer/",
          },
          {
            categoryID: 2153,
            name: "Toasters",
            parent: 2119,
            productsCount: 12,
            path: "1/2107/2119/2153",
            url: "toasters/",
          },
          {
            categoryID: 2155,
            name: "Water Filters",
            parent: 2119,
            productsCount: 66,
            path: "1/2107/2119/2155",
            url: "water-filters/",
          },
          {
            categoryID: 2157,
            name: "Other Appliances",
            parent: 2119,
            productsCount: 250,
            path: "1/2107/2119/2157",
            url: "other-appliances/",
          },
        ],
        url: "small-kitchen-appliances/",
      },
      {
        categoryID: 101,
        name: "Heaters",
        parent: 2107,
        productsCount: 297,
        path: "1/2107/101",
        subCategories: [
          {
            categoryID: 3031,
            name: "Electric Heaters",
            parent: 101,
            productsCount: 167,
            path: "1/2107/101/3031",
            url: "electric-heaters/",
          },
          {
            categoryID: 3029,
            name: "Gas Heaters",
            parent: 101,
            productsCount: 18,
            path: "1/2107/101/3029",
            url: "gas-heaters/",
          },
          {
            categoryID: 3033,
            name: "Oil Heaters",
            parent: 101,
            productsCount: 20,
            path: "1/2107/101/3033",
            url: "oil-heaters/",
          },
        ],
        url: "heaters/",
      },
      {
        categoryID: 2039,
        name: "Vacuum Cleaner",
        parent: 2107,
        productsCount: 785,
        path: "1/2107/2039",
        subCategories: [
          {
            categoryID: 4039,
            name: "Parts & Accessories",
            parent: 2039,
            productsCount: 150,
            path: "1/2107/2039/4039",
            url: "parts-accessories/",
          },
          {
            categoryID: 3895,
            name: "Bagless Vacuum Cleaner",
            parent: 2039,
            productsCount: 14,
            path: "1/2107/2039/3895",
            url: "bagless-vacuum-cleaner/",
          },
          {
            categoryID: 3023,
            name: "Cordless Vacuum Cleaner",
            parent: 2039,
            productsCount: 128,
            path: "1/2107/2039/3023",
            url: "cordless-vacuum-cleaner/",
          },
          {
            categoryID: 3027,
            name: "Robot Vacuum Cleaner",
            parent: 2039,
            productsCount: 65,
            path: "1/2107/2039/3027",
            url: "robot-vacuum-cleaner/",
          },
          {
            categoryID: 3025,
            name: "Handheld Vacuum",
            parent: 2039,
            productsCount: 63,
            path: "1/2107/2039/3025",
            url: "handheld-vacuum/",
          },
        ],
        url: "vacuum-cleaner/",
      },
      {
        categoryID: 2037,
        name: "Steam Cleaners & Accessories",
        parent: 2107,
        productsCount: 69,
        path: "1/2107/2037",
        url: "steam-cleaners-accessories/",
      },
      {
        categoryID: 2499,
        name: "Steam Mops & Accessories",
        parent: 2107,
        productsCount: 65,
        path: "1/2107/2499",
        url: "steam-mops-accessories/",
      },
      {
        categoryID: 2113,
        name: "Ovens",
        parent: 2107,
        productsCount: 73,
        path: "1/2107/2113",
        url: "ovens/",
      },
      {
        categoryID: 2109,
        name: "Portable Air Conditioner",
        parent: 2107,
        productsCount: 179,
        path: "1/2107/2109",
        url: "portable-air-conditioner/",
      },
      {
        categoryID: 1369,
        name: "Sewing Machines",
        parent: 2107,
        productsCount: 79,
        path: "1/2107/1369",
        url: "sewing-machines/",
      },
      {
        categoryID: 2501,
        name: "Floor Sweepers & Polishers",
        parent: 2107,
        productsCount: 30,
        path: "1/2107/2501",
        url: "floor-sweepers-polishers/",
      },
      {
        categoryID: 249,
        name: "Fans",
        parent: 2107,
        productsCount: 436,
        path: "1/2107/249",
        subCategories: [
          {
            categoryID: 3035,
            name: "Ceiling Fans",
            parent: 249,
            productsCount: 35,
            path: "1/2107/249/3035",
            url: "ceiling-fans/",
          },
          {
            categoryID: 3039,
            name: "Desk Fan",
            parent: 249,
            productsCount: 122,
            path: "1/2107/249/3039",
            url: "desk-fan/",
          },
          {
            categoryID: 4123,
            name: "Exhaust Fan",
            parent: 249,
            productsCount: 10,
            path: "1/2107/249/4123",
            url: "exhaust-fan/",
          },
          {
            categoryID: 3037,
            name: "Pedestal Fan",
            parent: 249,
            productsCount: 48,
            path: "1/2107/249/3037",
            url: "pedestal-fan/",
          },
          {
            categoryID: 3041,
            name: "Wall Mounted Fans",
            parent: 249,
            productsCount: 4,
            path: "1/2107/249/3041",
            url: "wall-mounted-fans/",
          },
          {
            categoryID: 3555,
            name: "Solar Fan Hat",
            parent: 249,
            productsCount: 1,
            path: "1/2107/249/3555",
            url: "solar-fan-hat/",
          },
        ],
        url: "fans/",
      },
      {
        categoryID: 2117,
        name: "Washing Machine",
        parent: 2107,
        productsCount: 228,
        path: "1/2107/2117",
        url: "washing-machine/",
      },
      {
        categoryID: 2713,
        name: "Espresso & Coffee Machines ",
        parent: 2107,
        productsCount: 65,
        path: "1/2107/2713",
        url: "espresso-coffee-machines/",
      },
      {
        categoryID: 2169,
        name: "Rangehoods",
        parent: 2107,
        productsCount: 49,
        path: "1/2107/2169",
        url: "rangehoods/",
      },
      {
        categoryID: 2725,
        name: "Catering Equipment",
        parent: 2107,
        productsCount: 17,
        path: "1/2107/2725",
        url: "catering-equipment/",
      },
      {
        categoryID: 3043,
        name: "Humidifier",
        parent: 2107,
        productsCount: 266,
        path: "1/2107/3043",
        url: "humidifier/",
      },
      {
        categoryID: 2111,
        name: "Microwave Ovens",
        parent: 2107,
        productsCount: 17,
        path: "1/2107/2111",
        url: "microwave-ovens/",
      },
      {
        categoryID: 2115,
        name: "Fridge Freezer",
        parent: 2107,
        productsCount: 112,
        path: "1/2107/2115",
        url: "fridge-freezer/",
      },
    ],
    url: "appliances/",
  },
  {
    categoryID: 57,
    name: "Furniture",
    parent: 1,
    productsCount: 22201,
    path: "1/57",
    subCategories: [
      {
        categoryID: 3911,
        name: "Room Dividers & Screens",
        parent: 57,
        productsCount: 466,
        path: "1/57/3911",
        url: "room-dividers-screens/",
      },
      {
        categoryID: 2041,
        name: "Bedroom Furniture",
        parent: 57,
        productsCount: 3056,
        path: "1/57/2041",
        subCategories: [
          {
            categoryID: 3139,
            name: "Bedside Tables",
            parent: 2041,
            productsCount: 1358,
            path: "1/57/2041/3139",
            url: "bedside-tables/",
          },
          {
            categoryID: 3141,
            name: "Clothes Rack",
            parent: 2041,
            productsCount: 97,
            path: "1/57/2041/3141",
            url: "clothes-rack/",
          },
          {
            categoryID: 3143,
            name: "Clothes Drying Rack",
            parent: 2041,
            productsCount: 17,
            path: "1/57/2041/3143",
            url: "clothes-drying-rack/",
          },
          {
            categoryID: 3145,
            name: "Shoe Storage",
            parent: 2041,
            productsCount: 49,
            path: "1/57/2041/3145",
            url: "shoe-storage/",
          },
          {
            categoryID: 3137,
            name: "Wardrobes",
            parent: 2041,
            productsCount: 180,
            path: "1/57/2041/3137",
            url: "wardrobes/",
          },
        ],
        url: "bedroom-furniture/",
      },
      {
        categoryID: 3147,
        name: "Beds",
        parent: 57,
        productsCount: 1767,
        path: "1/57/3147",
        subCategories: [
          {
            categoryID: 3157,
            name: "Bed Frames",
            parent: 3147,
            productsCount: 1642,
            path: "1/57/3147/3157",
            url: "bed-frames/",
          },
          {
            categoryID: 3149,
            name: "Double Bed",
            parent: 3147,
            productsCount: 14,
            path: "1/57/3147/3149",
            url: "double-bed/",
          },
          {
            categoryID: 3151,
            name: "King Bed",
            parent: 3147,
            productsCount: 15,
            path: "1/57/3147/3151",
            url: "king-bed/",
          },
          {
            categoryID: 3153,
            name: "Queen Bed",
            parent: 3147,
            productsCount: 24,
            path: "1/57/3147/3153",
            url: "queen-bed/",
          },
          {
            categoryID: 3155,
            name: "Folding Bed",
            parent: 3147,
            productsCount: 36,
            path: "1/57/3147/3155",
            url: "folding-bed/",
          },
        ],
        url: "beds/",
      },
      {
        categoryID: 2053,
        name: "Outdoor Furniture",
        parent: 57,
        productsCount: 7129,
        path: "1/57/2053",
        subCategories: [
          {
            categoryID: 3185,
            name: "Storage Baskets",
            parent: 2053,
            productsCount: 43,
            path: "1/57/2053/3185",
            url: "storage-baskets/",
          },
          {
            categoryID: 3207,
            name: "Picnic Basket",
            parent: 2053,
            productsCount: 53,
            path: "1/57/2053/3207",
            url: "picnic-basket/",
          },
          {
            categoryID: 3191,
            name: "Beach Chairs",
            parent: 2053,
            productsCount: 80,
            path: "1/57/2053/3191",
            url: "beach-chairs/",
          },
          {
            categoryID: 3209,
            name: "Storage Bench",
            parent: 2053,
            productsCount: 2402,
            path: "1/57/2053/3209",
            url: "storage-bench/",
          },
          {
            categoryID: 3205,
            name: "Letter Boxes",
            parent: 2053,
            productsCount: 39,
            path: "1/57/2053/3205",
            url: "letter-boxes/",
          },
          {
            categoryID: 3193,
            name: "Camping Chairs",
            parent: 2053,
            productsCount: 41,
            path: "1/57/2053/3193",
            url: "camping-chairs/",
          },
          {
            categoryID: 3187,
            name: "Hammock Chair",
            parent: 2053,
            productsCount: 46,
            path: "1/57/2053/3187",
            url: "hammock-chair/",
          },
          {
            categoryID: 3197,
            name: "Garden Chairs",
            parent: 2053,
            productsCount: 1897,
            path: "1/57/2053/3197",
            url: "garden-chairs/",
          },
          {
            categoryID: 3195,
            name: "Outdoor Furniture Covers",
            parent: 2053,
            productsCount: 83,
            path: "1/57/2053/3195",
            url: "outdoor-furniture-covers/",
          },
          {
            categoryID: 3201,
            name: "Wicker Outdoor Furniture",
            parent: 2053,
            productsCount: 206,
            path: "1/57/2053/3201",
            url: "wicker-outdoor-furniture/",
          },
          {
            categoryID: 3203,
            name: "Wicker Furniture",
            parent: 2053,
            productsCount: 49,
            path: "1/57/2053/3203",
            url: "wicker-furniture/",
          },
          {
            categoryID: 3211,
            name: "Garden Sheds",
            parent: 2053,
            productsCount: 33,
            path: "1/57/2053/3211",
            url: "garden-sheds/",
          },
          {
            categoryID: 3189,
            name: "Hammock",
            parent: 2053,
            productsCount: 143,
            path: "1/57/2053/3189",
            url: "hammock/",
          },
          {
            categoryID: 3183,
            name: "Outdoor Table",
            parent: 2053,
            productsCount: 872,
            path: "1/57/2053/3183",
            url: "outdoor-table/",
          },
          {
            categoryID: 3199,
            name: "Tents",
            parent: 2053,
            productsCount: 172,
            path: "1/57/2053/3199",
            url: "tents/",
          },
        ],
        url: "outdoor-furniture/",
      },
      {
        categoryID: 2045,
        name: "Cabinet",
        parent: 57,
        productsCount: 4183,
        path: "1/57/2045",
        subCategories: [
          {
            categoryID: 247,
            name: "TV Cabinets",
            parent: 2045,
            productsCount: 771,
            path: "1/57/2045/247",
            url: "tv-cabinets/",
          },
          {
            categoryID: 3169,
            name: "Bathroom Cabinets",
            parent: 2045,
            productsCount: 425,
            path: "1/57/2045/3169",
            url: "bathroom-cabinets/",
          },
          {
            categoryID: 3167,
            name: "Storage Cabinets",
            parent: 2045,
            productsCount: 1196,
            path: "1/57/2045/3167",
            url: "storage-cabinets/",
          },
          {
            categoryID: 3163,
            name: "Buffet Cabinet",
            parent: 2045,
            productsCount: 1560,
            path: "1/57/2045/3163",
            url: "buffet-cabinet/",
          },
          {
            categoryID: 3165,
            name: "High Gloss Cabinet",
            parent: 2045,
            productsCount: 55,
            path: "1/57/2045/3165",
            url: "high-gloss-cabinet/",
          },
          {
            categoryID: 3171,
            name: "Mirror Jewellery Cabinet",
            parent: 2045,
            productsCount: 139,
            path: "1/57/2045/3171",
            url: "mirror-jewellery-cabinet/",
          },
        ],
        url: "cabinet/",
      },
      {
        categoryID: 85,
        name: "Chairs",
        parent: 57,
        productsCount: 3679,
        path: "1/57/85",
        subCategories: [
          {
            categoryID: 3117,
            name: "Arm Chairs",
            parent: 85,
            productsCount: 578,
            path: "1/57/85/3117",
            url: "arm-chairs/",
          },
          {
            categoryID: 3109,
            name: "Dining Chairs",
            parent: 85,
            productsCount: 1628,
            path: "1/57/85/3109",
            url: "dining-chairs/",
          },
          {
            categoryID: 3113,
            name: "Recliner Chairs",
            parent: 85,
            productsCount: 137,
            path: "1/57/85/3113",
            url: "recliner-chairs/",
          },
          {
            categoryID: 3115,
            name: "Rocking Chair",
            parent: 85,
            productsCount: 128,
            path: "1/57/85/3115",
            url: "rocking-chair/",
          },
          {
            categoryID: 3119,
            name: "Tub Chairs",
            parent: 85,
            productsCount: 49,
            path: "1/57/85/3119",
            url: "tub-chairs/",
          },
          {
            categoryID: 3111,
            name: "Office Chairs",
            parent: 85,
            productsCount: 819,
            path: "1/57/85/3111",
            url: "office-chairs/",
          },
          {
            categoryID: 3121,
            name: "Eames Chair",
            parent: 85,
            productsCount: 73,
            path: "1/57/85/3121",
            url: "eames-chair/",
          },
          {
            categoryID: 3123,
            name: "Lorraine Chair",
            parent: 85,
            productsCount: 11,
            path: "1/57/85/3123",
            url: "lorraine-chair/",
          },
        ],
        url: "chairs/",
      },
      {
        categoryID: 315,
        name: "Office Furniture",
        parent: 57,
        productsCount: 1606,
        path: "1/57/315",
        subCategories: [
          {
            categoryID: 3253,
            name: "Bubble Wrap",
            parent: 315,
            productsCount: 3,
            path: "1/57/315/3253",
            url: "bubble-wrap/",
          },
          {
            categoryID: 3251,
            name: "Office Chair Mat",
            parent: 315,
            productsCount: 404,
            path: "1/57/315/3251",
            url: "office-chair-mat/",
          },
          {
            categoryID: 3255,
            name: "Paper Shredder",
            parent: 315,
            productsCount: 5,
            path: "1/57/315/3255",
            url: "paper-shredder/",
          },
          {
            categoryID: 2335,
            name: "Computer Desk",
            parent: 315,
            productsCount: 459,
            path: "1/57/315/2335",
            url: "computer-desk/",
          },
          {
            categoryID: 314,
            name: "Office Supplies & Stationary & Wrapping Paper",
            parent: 315,
            productsCount: 226,
            path: "1/57/315/314",
            subCategories: [
              {
                categoryID: 2339,
                name: "Binders",
                parent: 314,
                productsCount: 7,
                path: "1/57/315/314/2339",
                url: "binders/",
              },
              {
                categoryID: 2341,
                name: "Calculators",
                parent: 314,
                productsCount: 11,
                path: "1/57/315/314/2341",
                url: "calculators/",
              },
              {
                categoryID: 2343,
                name: "File Folders",
                parent: 314,
                productsCount: 27,
                path: "1/57/315/314/2343",
                url: "file-folders/",
              },
              {
                categoryID: 2345,
                name: "Laminators",
                parent: 314,
                productsCount: 9,
                path: "1/57/315/314/2345",
                url: "laminators/",
              },
              {
                categoryID: 2603,
                name: "Printer Paper",
                parent: 314,
                productsCount: 51,
                path: "1/57/315/314/2603",
                url: "printer-paper/",
              },
              {
                categoryID: 2347,
                name: "Envelopes",
                parent: 314,
                productsCount: 12,
                path: "1/57/315/314/2347",
                url: "envelopes/",
              },
              {
                categoryID: 2351,
                name: "Shredders",
                parent: 314,
                productsCount: 9,
                path: "1/57/315/314/2351",
                url: "shredders/",
              },
              {
                categoryID: 2349,
                name: "Whiteboard",
                parent: 314,
                productsCount: 53,
                path: "1/57/315/314/2349",
                url: "whiteboard/",
              },
            ],
            url: "office-supplies-stationary-wrapping-paper/",
          },
        ],
        url: "office-furniture/",
      },
      {
        categoryID: 2491,
        name: "Bar Stools",
        parent: 57,
        productsCount: 1748,
        path: "1/57/2491",
        subCategories: [
          {
            categoryID: 3229,
            name: "Adjustable Stool",
            parent: 2491,
            productsCount: 70,
            path: "1/57/2491/3229",
            url: "adjustable-stool/",
          },
          {
            categoryID: 2943,
            name: "Metal Bar Stools",
            parent: 2491,
            productsCount: 33,
            path: "1/57/2491/2943",
            url: "metal-bar-stools/",
          },
          {
            categoryID: 3227,
            name: "Leather Bar Stools",
            parent: 2491,
            productsCount: 259,
            path: "1/57/2491/3227",
            url: "leather-bar-stools/",
          },
          {
            categoryID: 3231,
            name: "Bar Chairs",
            parent: 2491,
            productsCount: 209,
            path: "1/57/2491/3231",
            url: "bar-chairs/",
          },
          {
            categoryID: 3233,
            name: "Wicker Bar Stools",
            parent: 2491,
            productsCount: 6,
            path: "1/57/2491/3233",
            url: "wicker-bar-stools/",
          },
          {
            categoryID: 3237,
            name: "Solar Bar Stool",
            parent: 2491,
            productsCount: 2,
            path: "1/57/2491/3237",
            url: "solar-bar-stool/",
          },
          {
            categoryID: 3225,
            name: "Wooden Bar Stools",
            parent: 2491,
            productsCount: 131,
            path: "1/57/2491/3225",
            url: "wooden-bar-stools/",
          },
          {
            categoryID: 3223,
            name: "Kitchen Stools",
            parent: 2491,
            productsCount: 186,
            path: "1/57/2491/3223",
            url: "kitchen-stools/",
          },
          {
            categoryID: 3235,
            name: "Salon Stool",
            parent: 2491,
            productsCount: 63,
            path: "1/57/2491/3235",
            url: "salon-stool/",
          },
        ],
        url: "bar-stools/",
      },
      {
        categoryID: 2607,
        name: "Couch",
        parent: 57,
        productsCount: 1171,
        path: "1/57/2607",
        subCategories: [
          {
            categoryID: 3241,
            name: "Sofa Beds",
            parent: 2607,
            productsCount: 320,
            path: "1/57/2607/3241",
            url: "sofa-beds/",
          },
          {
            categoryID: 3243,
            name: "Inflatable Couch",
            parent: 2607,
            productsCount: 5,
            path: "1/57/2607/3243",
            url: "inflatable-couch/",
          },
          {
            categoryID: 3247,
            name: "Couch Covers",
            parent: 2607,
            productsCount: 183,
            path: "1/57/2607/3247",
            url: "couch-covers/",
          },
          {
            categoryID: 3249,
            name: "Fabric Sofas",
            parent: 2607,
            productsCount: 106,
            path: "1/57/2607/3249",
            url: "fabric-sofas/",
          },
          {
            categoryID: 3245,
            name: "Leather Sofa",
            parent: 2607,
            productsCount: 351,
            path: "1/57/2607/3245",
            url: "leather-sofa/",
          },
          {
            categoryID: 3239,
            name: "Storage Ottoman",
            parent: 2607,
            productsCount: 127,
            path: "1/57/2607/3239",
            url: "storage-ottoman/",
          },
        ],
        url: "couch/",
      },
      {
        categoryID: 2051,
        name: "Kitchen Furniture",
        parent: 57,
        productsCount: 456,
        path: "1/57/2051",
        subCategories: [
          {
            categoryID: 3177,
            name: "Kitchen Bench",
            parent: 2051,
            productsCount: 26,
            path: "1/57/2051/3177",
            url: "kitchen-bench/",
          },
          {
            categoryID: 3175,
            name: "Kitchen Trolley",
            parent: 2051,
            productsCount: 68,
            path: "1/57/2051/3175",
            url: "kitchen-trolley/",
          },
          {
            categoryID: 3179,
            name: "Kitchen Sinks",
            parent: 2051,
            productsCount: 112,
            path: "1/57/2051/3179",
            url: "kitchen-sinks/",
          },
          {
            categoryID: 3181,
            name: "Kitchen Shelves",
            parent: 2051,
            productsCount: 167,
            path: "1/57/2051/3181",
            url: "kitchen-shelves/",
          },
        ],
        url: "kitchen-furniture/",
      },
      {
        categoryID: 2049,
        name: "Dining Room Furniture",
        parent: 57,
        productsCount: 719,
        path: "1/57/2049",
        subCategories: [
          {
            categoryID: 4095,
            name: "Drinks & Tea Trolleys",
            parent: 2049,
            productsCount: 51,
            path: "1/57/2049/4095",
            url: "drinks-tea-trolleys/",
          },
        ],
        url: "dining-room-furniture/",
      },
      {
        categoryID: 3135,
        name: "Dressing Table",
        parent: 57,
        productsCount: 278,
        path: "1/57/3135",
        url: "dressing-table/",
      },
      {
        categoryID: 2055,
        name: "Tables",
        parent: 57,
        productsCount: 3810,
        path: "1/57/2055",
        subCategories: [
          {
            categoryID: 3217,
            name: "Bar Table",
            parent: 2055,
            productsCount: 145,
            path: "1/57/2055/3217",
            url: "bar-table/",
          },
          {
            categoryID: 3221,
            name: "Coffee Tables",
            parent: 2055,
            productsCount: 1697,
            path: "1/57/2055/3221",
            url: "coffee-tables/",
          },
          {
            categoryID: 4093,
            name: "Console Table",
            parent: 2055,
            productsCount: 65,
            path: "1/57/2055/4093",
            url: "console-table/",
          },
          {
            categoryID: 3215,
            name: "Round Dining Table",
            parent: 2055,
            productsCount: 3,
            path: "1/57/2055/3215",
            url: "round-dining-table/",
          },
          {
            categoryID: 3213,
            name: "Dining Tables",
            parent: 2055,
            productsCount: 714,
            path: "1/57/2055/3213",
            url: "dining-tables/",
          },
          {
            categoryID: 3219,
            name: "Nest Of Tables",
            parent: 2055,
            productsCount: 17,
            path: "1/57/2055/3219",
            url: "nest-of-tables/",
          },
        ],
        url: "tables/",
      },
      {
        categoryID: 2047,
        name: "DVD Storage",
        parent: 57,
        productsCount: 42,
        path: "1/57/2047",
        url: "dvd-storage/",
      },
      {
        categoryID: 3159,
        name: "Magazine Rack",
        parent: 57,
        productsCount: 25,
        path: "1/57/3159",
        url: "magazine-rack/",
      },
      {
        categoryID: 2741,
        name: "Laptop Table",
        parent: 57,
        productsCount: 81,
        path: "1/57/2741",
        url: "laptop-table/",
      },
      {
        categoryID: 253,
        name: "Kids Furniture",
        parent: 57,
        productsCount: 571,
        path: "1/57/253",
        subCategories: [
          {
            categoryID: 3127,
            name: "Kids Beds",
            parent: 253,
            productsCount: 26,
            path: "1/57/253/3127",
            url: "kids-beds/",
          },
          {
            categoryID: 3129,
            name: "Kids Table And Chairs",
            parent: 253,
            productsCount: 312,
            path: "1/57/253/3129",
            url: "kids-table-and-chairs/",
          },
          {
            categoryID: 3131,
            name: "Kids Chest Of Drawers",
            parent: 253,
            productsCount: 26,
            path: "1/57/253/3131",
            url: "kids-chest-of-drawers/",
          },
        ],
        url: "kids-furniture/",
      },
      {
        categoryID: 3161,
        name: "Toy Storage",
        parent: 57,
        productsCount: 72,
        path: "1/57/3161",
        url: "toy-storage/",
      },
      {
        categoryID: 2043,
        name: "Bookshelves",
        parent: 57,
        productsCount: 2341,
        path: "1/57/2043",
        url: "bookshelves/",
      },
      {
        categoryID: 2773,
        name: "Chest Of Drawers",
        parent: 57,
        productsCount: 199,
        path: "1/57/2773",
        url: "chest-of-drawers/",
      },
      {
        categoryID: 3173,
        name: "Tallboy",
        parent: 57,
        productsCount: 15,
        path: "1/57/3173",
        url: "tallboy/",
      },
      {
        categoryID: 207,
        name: "Bean Bags",
        parent: 57,
        productsCount: 57,
        path: "1/57/207",
        url: "bean-bags/",
      },
      {
        categoryID: 3125,
        name: "Bean Bag Covers",
        parent: 57,
        productsCount: 22,
        path: "1/57/3125",
        url: "bean-bag-covers/",
      },
    ],
    url: "furniture/",
  },
  {
    categoryID: 147,
    name: "Home & Garden",
    parent: 1,
    productsCount: 21818,
    path: "1/147",
    subCategories: [
      {
        categoryID: 4061,
        name: "Building Materials & DIY",
        parent: 147,
        productsCount: 73,
        path: "1/147/4061",
        subCategories: [
          {
            categoryID: 4129,
            name: "Insulation & Accessories",
            parent: 4061,
            productsCount: 1,
            path: "1/147/4061/4129",
            url: "insulation-accessories/",
          },
          {
            categoryID: 4063,
            name: "Cabinet Hardware",
            parent: 4061,
            productsCount: 12,
            path: "1/147/4061/4063",
            url: "cabinet-hardware/",
            subCategories: [
              {
                categoryID: 4065,
                name: "Drawer Slides",
                parent: 4063,
                productsCount: 12,
                path: "1/147/4061/4063/4065",
                url: "drawer-slides/",
              },
            ],
          },
        ],
        url: "building-materials-diy/",
      },
      {
        categoryID: 4111,
        name: "Wedding Supplies",
        parent: 147,
        productsCount: 18,
        path: "1/147/4111",
        subCategories: [
          {
            categoryID: 4113,
            name: "Wedding Backdrop Stand",
            parent: 4111,
            productsCount: 17,
            path: "1/147/4111/4113",
            url: "wedding-backdrop-stand/",
          },
        ],
        url: "wedding-supplies/",
      },
      {
        categoryID: 336,
        name: "Garden Supplies",
        parent: 147,
        productsCount: 5229,
        path: "1/147/336",
        subCategories: [
          {
            categoryID: 3599,
            name: "Garden Arch",
            parent: 336,
            productsCount: 3,
            path: "1/147/336/3599",
            url: "garden-arch/",
          },
          {
            categoryID: 3595,
            name: "Artificial Grass",
            parent: 336,
            productsCount: 261,
            path: "1/147/336/3595",
            url: "artificial-grass/",
          },
          {
            categoryID: 3607,
            name: "Wooden Bench",
            parent: 336,
            productsCount: 58,
            path: "1/147/336/3607",
            url: "wooden-bench/",
          },
          {
            categoryID: 3611,
            name: "Compost Bins",
            parent: 336,
            productsCount: 34,
            path: "1/147/336/3611",
            url: "compost-bins/",
          },
          {
            categoryID: 3625,
            name: "Leaf Blower",
            parent: 336,
            productsCount: 42,
            path: "1/147/336/3625",
            url: "leaf-blower/",
          },
          {
            categoryID: 3589,
            name: "Garden Bridge",
            parent: 336,
            productsCount: 10,
            path: "1/147/336/3589",
            url: "garden-bridge/",
          },
          {
            categoryID: 3609,
            name: "Brush Cutter",
            parent: 336,
            productsCount: 23,
            path: "1/147/336/3609",
            url: "brush-cutter/",
          },
          {
            categoryID: 3617,
            name: "Chainsaw",
            parent: 336,
            productsCount: 12,
            path: "1/147/336/3617",
            url: "chainsaw/",
          },
          {
            categoryID: 3971,
            name: "Wooden Chairs",
            parent: 336,
            productsCount: 6,
            path: "1/147/336/3971",
            url: "wooden-chairs/",
          },
          {
            categoryID: 3947,
            name: "Shade Cloth",
            parent: 336,
            productsCount: 80,
            path: "1/147/336/3947",
            url: "shade-cloth/",
          },
          {
            categoryID: 3601,
            name: "Decking Tiles",
            parent: 336,
            productsCount: 3,
            path: "1/147/336/3601",
            url: "decking-tiles/",
          },
          {
            categoryID: 2267,
            name: "Garden Decor",
            parent: 336,
            productsCount: 795,
            path: "1/147/336/2267",
            url: "garden-decor/",
          },
          {
            categoryID: 3603,
            name: "Electric Fence",
            parent: 336,
            productsCount: 77,
            path: "1/147/336/3603",
            url: "electric-fence/",
          },
          {
            categoryID: 3579,
            name: "Garden Sprayer",
            parent: 336,
            productsCount: 73,
            path: "1/147/336/3579",
            url: "garden-sprayer/",
          },
          {
            categoryID: 3605,
            name: "Solar Garden Lights",
            parent: 336,
            productsCount: 252,
            path: "1/147/336/3605",
            url: "solar-garden-lights/",
          },
          {
            categoryID: 4103,
            name: "Seedling Heat Mat",
            parent: 336,
            productsCount: 3,
            path: "1/147/336/4103",
            url: "seedling-heat-mat/",
          },
          {
            categoryID: 3615,
            name: "Hedge Trimmer",
            parent: 336,
            productsCount: 50,
            path: "1/147/336/3615",
            url: "hedge-trimmer/",
          },
          {
            categoryID: 3583,
            name: "Hose Reel",
            parent: 336,
            productsCount: 85,
            path: "1/147/336/3583",
            url: "hose-reel/",
          },
          {
            categoryID: 3585,
            name: "Pocket Hose",
            parent: 336,
            productsCount: 13,
            path: "1/147/336/3585",
            url: "pocket-hose/",
          },
          {
            categoryID: 3621,
            name: "Solar Panel Kits",
            parent: 336,
            productsCount: 79,
            path: "1/147/336/3621",
            url: "solar-panel-kits/",
          },
          {
            categoryID: 3623,
            name: "Lawn Mowers",
            parent: 336,
            productsCount: 99,
            path: "1/147/336/3623",
            url: "lawn-mowers/",
          },
          {
            categoryID: 3015,
            name: "Weed Mat",
            parent: 336,
            productsCount: 11,
            path: "1/147/336/3015",
            url: "weed-mat/",
          },
          {
            categoryID: 3619,
            name: "Pressure Washer",
            parent: 336,
            productsCount: 118,
            path: "1/147/336/3619",
            url: "pressure-washer/",
          },
          {
            categoryID: 3581,
            name: "Weed Sprayer",
            parent: 336,
            productsCount: 60,
            path: "1/147/336/3581",
            url: "weed-sprayer/",
          },
          {
            categoryID: 3877,
            name: "Tiller",
            parent: 336,
            productsCount: 33,
            path: "1/147/336/3877",
            url: "tiller/",
          },
          {
            categoryID: 3591,
            name: "Wishing Well",
            parent: 336,
            productsCount: 7,
            path: "1/147/336/3591",
            url: "wishing-well/",
          },
          {
            categoryID: 2269,
            name: "Greenhouse",
            parent: 336,
            productsCount: 320,
            path: "1/147/336/2269",
            url: "greenhouse/",
          },
          {
            categoryID: 2775,
            name: "Garden Tools",
            parent: 336,
            productsCount: 335,
            path: "1/147/336/2775",
            url: "garden-tools/",
          },
          {
            categoryID: 2273,
            name: "Outdoor Power Equipment",
            parent: 336,
            productsCount: 176,
            path: "1/147/336/2273",
            url: "outdoor-power-equipment/",
          },
          {
            categoryID: 2271,
            name: "Plant Pots",
            parent: 336,
            productsCount: 1193,
            path: "1/147/336/2271",
            url: "plant-pots/",
          },
          {
            categoryID: 2777,
            name: "Watering Equipment",
            parent: 336,
            productsCount: 171,
            path: "1/147/336/2777",
            url: "watering-equipment/",
          },
          {
            categoryID: 1563,
            name: "Water Pumps",
            parent: 336,
            productsCount: 481,
            path: "1/147/336/1563",
            url: "water-pumps/",
          },
        ],
        url: "garden-supplies/",
      },
      {
        categoryID: 503,
        name: "Cleaning Supplies",
        parent: 147,
        productsCount: 787,
        path: "1/147/503",
        subCategories: [
          {
            categoryID: 2027,
            name: "Bins",
            parent: 503,
            productsCount: 310,
            path: "1/147/503/2027",
            url: "bins/",
          },
          {
            categoryID: 3635,
            name: "Lint Remover",
            parent: 503,
            productsCount: 16,
            path: "1/147/503/3635",
            url: "lint-remover/",
          },
          {
            categoryID: 2029,
            name: "Dusters",
            parent: 503,
            productsCount: 10,
            path: "1/147/503/2029",
            url: "dusters/",
          },
          {
            categoryID: 2033,
            name: "Laundry",
            parent: 503,
            productsCount: 88,
            path: "1/147/503/2033",
            url: "laundry/",
          },
          {
            categoryID: 2445,
            name: "Jewellery Cleaner",
            parent: 503,
            productsCount: 64,
            path: "1/147/503/2445",
            url: "jewellery-cleaner/",
          },
          {
            categoryID: 2035,
            name: "Mop",
            parent: 503,
            productsCount: 108,
            path: "1/147/503/2035",
            url: "mop/",
          },
        ],
        url: "cleaning-supplies/",
      },
      {
        categoryID: 87,
        name: "Home Organisation",
        parent: 147,
        productsCount: 3881,
        path: "1/147/87",
        subCategories: [
          {
            categoryID: 2099,
            name: "Closet Organisers",
            parent: 87,
            productsCount: 46,
            path: "1/147/87/2099",
            url: "closet-organisers/",
          },
          {
            categoryID: 2779,
            name: "Coat Rack",
            parent: 87,
            productsCount: 109,
            path: "1/147/87/2779",
            url: "coat-rack/",
          },
          {
            categoryID: 3553,
            name: "Plastic Storage Drawers",
            parent: 87,
            productsCount: 40,
            path: "1/147/87/3553",
            url: "plastic-storage-drawers/",
          },
          {
            categoryID: 2781,
            name: "Hanger",
            parent: 87,
            productsCount: 124,
            path: "1/147/87/2781",
            url: "hanger/",
          },
          {
            categoryID: 4027,
            name: "Kitchen Racks & Holders",
            parent: 87,
            productsCount: 82,
            path: "1/147/87/4027",
            url: "kitchen-racks-holders/",
          },
          {
            categoryID: 2101,
            name: "Shoe Rack & Cabinet",
            parent: 87,
            productsCount: 852,
            path: "1/147/87/2101",
            url: "shoe-rack-cabinet/",
          },
          {
            categoryID: 2103,
            name: "Storage Boxes",
            parent: 87,
            productsCount: 586,
            path: "1/147/87/2103",
            url: "storage-boxes/",
          },
          {
            categoryID: 2105,
            name: "Vacuum Storage Bags",
            parent: 87,
            productsCount: 65,
            path: "1/147/87/2105",
            url: "vacuum-storage-bags/",
          },
          {
            categoryID: 2483,
            name: "Storage Trolley",
            parent: 87,
            productsCount: 125,
            path: "1/147/87/2483",
            url: "storage-trolley/",
          },
        ],
        url: "home-organisation/",
      },
      {
        categoryID: 2221,
        name: "Pest Control",
        parent: 147,
        productsCount: 395,
        path: "1/147/2221",
        subCategories: [
          {
            categoryID: 3645,
            name: "Animal Traps",
            parent: 2221,
            productsCount: 36,
            path: "1/147/2221/3645",
            url: "animal-traps/",
          },
          {
            categoryID: 3871,
            name: "Bird Repellent",
            parent: 2221,
            productsCount: 23,
            path: "1/147/2221/3871",
            url: "bird-repellent/",
          },
          {
            categoryID: 3641,
            name: "Bug Zapper",
            parent: 2221,
            productsCount: 56,
            path: "1/147/2221/3641",
            url: "bug-zapper/",
          },
          {
            categoryID: 3647,
            name: "Mouse Trap",
            parent: 2221,
            productsCount: 16,
            path: "1/147/2221/3647",
            url: "mouse-trap/",
          },
          {
            categoryID: 3643,
            name: "Pest Repeller",
            parent: 2221,
            productsCount: 170,
            path: "1/147/2221/3643",
            url: "pest-repeller/",
          },
          {
            categoryID: 3639,
            name: "Snake Repellent",
            parent: 2221,
            productsCount: 43,
            path: "1/147/2221/3639",
            url: "snake-repellent/",
          },
        ],
        url: "pest-control/",
      },
      {
        categoryID: 2079,
        name: "Safes",
        parent: 147,
        productsCount: 98,
        path: "1/147/2079",
        subCategories: [
          {
            categoryID: 3563,
            name: "Safe Box",
            parent: 2079,
            productsCount: 37,
            path: "1/147/2079/3563",
            url: "safe-box/",
          },
          {
            categoryID: 3561,
            name: "Gun Safe",
            parent: 2079,
            productsCount: 40,
            path: "1/147/2079/3561",
            url: "gun-safe/",
          },
        ],
        url: "safes/",
      },
      {
        categoryID: 501,
        name: "Lights & Lighting",
        parent: 147,
        productsCount: 644,
        path: "1/147/501",
        subCategories: [
          {
            categoryID: 3631,
            name: "Bedside Lamps",
            parent: 501,
            productsCount: 21,
            path: "1/147/501/3631",
            url: "bedside-lamps/",
          },
          {
            categoryID: 3627,
            name: "Desk Lamp",
            parent: 501,
            productsCount: 116,
            path: "1/147/501/3627",
            url: "desk-lamp/",
          },
          {
            categoryID: 3629,
            name: "Floor Lamps",
            parent: 501,
            productsCount: 110,
            path: "1/147/501/3629",
            url: "floor-lamps/",
          },
          {
            categoryID: 3633,
            name: "Sensor Lights",
            parent: 501,
            productsCount: 35,
            path: "1/147/501/3633",
            url: "sensor-lights/",
          },
          {
            categoryID: 2227,
            name: "Outdoor Lighting",
            parent: 501,
            productsCount: 101,
            path: "1/147/501/2227",
            url: "outdoor-lighting/",
          },
        ],
        url: "lights-lighting/",
      },
      {
        categoryID: 144,
        name: "Home Security Systems",
        parent: 147,
        productsCount: 1107,
        path: "1/147/144",
        subCategories: [
          {
            categoryID: 3559,
            name: "security cameras",
            parent: 144,
            productsCount: 434,
            path: "1/147/144/3559",
            url: "security-cameras/",
          },
          {
            categoryID: 2073,
            name: "Cameras",
            parent: 144,
            productsCount: 172,
            path: "1/147/144/2073",
            url: "cameras/",
          },
          {
            categoryID: 3897,
            name: "Door Locks",
            parent: 144,
            productsCount: 100,
            path: "1/147/144/3897",
            url: "door-locks/",
          },
          {
            categoryID: 3557,
            name: "Intercom Systems",
            parent: 144,
            productsCount: 11,
            path: "1/147/144/3557",
            url: "intercom-systems/",
          },
          {
            categoryID: 2075,
            name: "Doorbell",
            parent: 144,
            productsCount: 109,
            path: "1/147/144/2075",
            url: "doorbell/",
          },
          {
            categoryID: 2077,
            name: "Intercoms",
            parent: 144,
            productsCount: 7,
            path: "1/147/144/2077",
            url: "intercoms/",
          },
          {
            categoryID: 2565,
            name: "Gate Openers",
            parent: 144,
            productsCount: 223,
            path: "1/147/144/2565",
            url: "gate-openers/",
          },
        ],
        url: "home-security-systems/",
      },
      {
        categoryID: 222,
        name: "Home Decor",
        parent: 147,
        productsCount: 3885,
        path: "1/147/222",
        subCategories: [
          {
            categoryID: 4023,
            name: "Art Paintings",
            parent: 222,
            productsCount: 210,
            path: "1/147/222/4023",
            url: "art-paintings/",
          },
          {
            categoryID: 3565,
            name: "Awnings",
            parent: 222,
            productsCount: 304,
            path: "1/147/222/3565",
            url: "awnings/",
          },
          {
            categoryID: 2723,
            name: "Curtains",
            parent: 222,
            productsCount: 980,
            path: "1/147/222/2723",
            url: "curtains/",
          },
          {
            categoryID: 3567,
            name: "Door Knobs",
            parent: 222,
            productsCount: 4,
            path: "1/147/222/3567",
            url: "door-knobs/",
          },
          {
            categoryID: 3575,
            name: "Magnetic Screen Door",
            parent: 222,
            productsCount: 2,
            path: "1/147/222/3575",
            url: "magnetic-screen-door/",
          },
          {
            categoryID: 3573,
            name: "Hair Dryer Holder",
            parent: 222,
            productsCount: 38,
            path: "1/147/222/3573",
            url: "hair-dryer-holder/",
          },
          {
            categoryID: 2057,
            name: "Ethanol Fireplace",
            parent: 222,
            productsCount: 61,
            path: "1/147/222/2057",
            url: "ethanol-fireplace/",
          },
          {
            categoryID: 3569,
            name: "Soap Holder",
            parent: 222,
            productsCount: 24,
            path: "1/147/222/3569",
            url: "soap-holder/",
          },
          {
            categoryID: 3571,
            name: "Paper Towel Holder",
            parent: 222,
            productsCount: 15,
            path: "1/147/222/3571",
            url: "paper-towel-holder/",
          },
          {
            categoryID: 925,
            name: "Christmas",
            parent: 222,
            productsCount: 83,
            path: "1/147/222/925",
            url: "christmas/",
          },
          {
            categoryID: 2059,
            name: "Clocks",
            parent: 222,
            productsCount: 415,
            path: "1/147/222/2059",
            url: "clocks/",
          },
          {
            categoryID: 2061,
            name: "Door Hardware",
            parent: 222,
            productsCount: 343,
            path: "1/147/222/2061",
            url: "door-hardware/",
          },
          {
            categoryID: 2063,
            name: "Plant Labels",
            parent: 222,
            productsCount: 39,
            path: "1/147/222/2063",
            url: "plant-labels/",
          },
          {
            categoryID: 2065,
            name: "Fountains",
            parent: 222,
            productsCount: 43,
            path: "1/147/222/2065",
            url: "fountains/",
          },
          {
            categoryID: 2067,
            name: "Photo Frames",
            parent: 222,
            productsCount: 142,
            path: "1/147/222/2067",
            url: "photo-frames/",
          },
          {
            categoryID: 2069,
            name: "Shower Shelf",
            parent: 222,
            productsCount: 218,
            path: "1/147/222/2069",
            url: "shower-shelf/",
          },
          {
            categoryID: 2071,
            name: "Wall Stickers",
            parent: 222,
            productsCount: 504,
            path: "1/147/222/2071",
            url: "wall-stickers/",
          },
        ],
        url: "home-decor/",
      },
      {
        categoryID: 61,
        name: "Kitchenware",
        parent: 147,
        productsCount: 3776,
        path: "1/147/61",
        subCategories: [
          {
            categoryID: 1457,
            name: "Wine Accessories",
            parent: 61,
            productsCount: 154,
            path: "1/147/61/1457",
            url: "wine-accessories/",
          },
          {
            categoryID: 3481,
            name: "Vacuum Sealer Bags",
            parent: 61,
            productsCount: 29,
            path: "1/147/61/3481",
            url: "vacuum-sealer-bags/",
          },
          {
            categoryID: 2783,
            name: "Bakeware",
            parent: 61,
            productsCount: 276,
            path: "1/147/61/2783",
            url: "bakeware/",
          },
          {
            categoryID: 3489,
            name: "Tea Box",
            parent: 61,
            productsCount: 20,
            path: "1/147/61/3489",
            url: "tea-box/",
          },
          {
            categoryID: 3529,
            name: "Burger Press",
            parent: 61,
            productsCount: 3,
            path: "1/147/61/3529",
            url: "burger-press/",
          },
          {
            categoryID: 3527,
            name: "Cake Supplies",
            parent: 61,
            productsCount: 51,
            path: "1/147/61/3527",
            url: "cake-supplies/",
          },
          {
            categoryID: 3483,
            name: "Canister Sets",
            parent: 61,
            productsCount: 12,
            path: "1/147/61/3483",
            url: "canister-sets/",
          },
          {
            categoryID: 3501,
            name: "Citrus Spray",
            parent: 61,
            productsCount: 1,
            path: "1/147/61/3501",
            url: "citrus-spray/",
          },
          {
            categoryID: 3479,
            name: "Cling Wrap Dispenser",
            parent: 61,
            productsCount: 10,
            path: "1/147/61/3479",
            url: "cling-wrap-dispenser/",
          },
          {
            categoryID: 3487,
            name: "Cookie Jar",
            parent: 61,
            productsCount: 14,
            path: "1/147/61/3487",
            url: "cookie-jar/",
          },
          {
            categoryID: 3511,
            name: "Egg Slicer",
            parent: 61,
            productsCount: 11,
            path: "1/147/61/3511",
            url: "egg-slicer/",
          },
          {
            categoryID: 3513,
            name: "Egg Separator",
            parent: 61,
            productsCount: 12,
            path: "1/147/61/3513",
            url: "egg-separator/",
          },
          {
            categoryID: 3509,
            name: "Egg Mould",
            parent: 61,
            productsCount: 10,
            path: "1/147/61/3509",
            url: "egg-mould/",
          },
          {
            categoryID: 3525,
            name: "Oven Gloves",
            parent: 61,
            productsCount: 22,
            path: "1/147/61/3525",
            url: "oven-gloves/",
          },
          {
            categoryID: 3523,
            name: "Spice Grinder",
            parent: 61,
            productsCount: 15,
            path: "1/147/61/3523",
            url: "spice-grinder/",
          },
          {
            categoryID: 3503,
            name: "Hand Juicer",
            parent: 61,
            productsCount: 10,
            path: "1/147/61/3503",
            url: "hand-juicer/",
          },
          {
            categoryID: 3497,
            name: "Kitchen Timer",
            parent: 61,
            productsCount: 46,
            path: "1/147/61/3497",
            url: "kitchen-timer/",
          },
          {
            categoryID: 3495,
            name: "Knife Sharpener",
            parent: 61,
            productsCount: 44,
            path: "1/147/61/3495",
            url: "knife-sharpener/",
          },
          {
            categoryID: 3521,
            name: "Potato Masher",
            parent: 61,
            productsCount: 5,
            path: "1/147/61/3521",
            url: "potato-masher/",
          },
          {
            categoryID: 3519,
            name: "Meat Tenderizer",
            parent: 61,
            productsCount: 11,
            path: "1/147/61/3519",
            url: "meat-tenderizer/",
          },
          {
            categoryID: 3499,
            name: "Peeler",
            parent: 61,
            productsCount: 20,
            path: "1/147/61/3499",
            url: "peeler/",
          },
          {
            categoryID: 3493,
            name: "Vegetable Slicer",
            parent: 61,
            productsCount: 103,
            path: "1/147/61/3493",
            url: "vegetable-slicer/",
          },
          {
            categoryID: 3533,
            name: "Tea Towels",
            parent: 61,
            productsCount: 9,
            path: "1/147/61/3533",
            url: "tea-towels/",
          },
          {
            categoryID: 3485,
            name: "Thermos",
            parent: 61,
            productsCount: 24,
            path: "1/147/61/3485",
            url: "thermos/",
          },
          {
            categoryID: 2955,
            name: "Beer",
            parent: 61,
            productsCount: 7,
            path: "1/147/61/2955",
            url: "beer/",
          },
          {
            categoryID: 2083,
            name: "Cookware",
            parent: 61,
            productsCount: 538,
            path: "1/147/61/2083",
            url: "cookware/",
          },
          {
            categoryID: 2087,
            name: "Cutting Boards",
            parent: 61,
            productsCount: 44,
            path: "1/147/61/2087",
            url: "cutting-boards/",
          },
          {
            categoryID: 2089,
            name: "Dinner Sets",
            parent: 61,
            productsCount: 301,
            path: "1/147/61/2089",
            subCategories: [
              {
                categoryID: 3551,
                name: "Party Packs",
                parent: 2089,
                productsCount: 10,
                path: "1/147/61/2089/3551",
                url: "party-packs/",
              },
            ],
            url: "dinner-sets/",
          },
          {
            categoryID: 2091,
            name: "Food Storage",
            parent: 61,
            productsCount: 361,
            path: "1/147/61/2091",
            url: "food-storage/",
          },
          {
            categoryID: 2727,
            name: "Kitchen Knives",
            parent: 61,
            productsCount: 179,
            path: "1/147/61/2727",
            url: "kitchen-knives/",
          },
          {
            categoryID: 2731,
            name: "Kitchen Scales",
            parent: 61,
            productsCount: 88,
            path: "1/147/61/2731",
            url: "kitchen-scales/",
          },
          {
            categoryID: 2093,
            name: "Kitchen Utensils",
            parent: 61,
            productsCount: 550,
            path: "1/147/61/2093",
            url: "kitchen-utensils/",
          },
          {
            categoryID: 2095,
            name: "Tablecloths",
            parent: 61,
            productsCount: 79,
            path: "1/147/61/2095",
            url: "tablecloths/",
          },
          {
            categoryID: 2151,
            name: "Coffee Maker",
            parent: 61,
            productsCount: 181,
            path: "1/147/61/2151",
            url: "coffee-maker/",
          },
          {
            categoryID: 2097,
            name: "Table Decorations",
            parent: 61,
            productsCount: 145,
            path: "1/147/61/2097",
            url: "table-decorations/",
          },
          {
            categoryID: 2733,
            name: "Salt & Pepper Mills",
            parent: 61,
            productsCount: 52,
            path: "1/147/61/2733",
            url: "salt-pepper-mills/",
          },
          {
            categoryID: 2785,
            name: "Mugs",
            parent: 61,
            productsCount: 94,
            path: "1/147/61/2785",
            url: "mugs/",
          },
        ],
        url: "kitchenware/",
      },
      {
        categoryID: 2535,
        name: "Rugs",
        parent: 147,
        productsCount: 1222,
        path: "1/147/2535",
        subCategories: [
          {
            categoryID: 2543,
            name: "165 x 115cm",
            parent: 2535,
            productsCount: 32,
            path: "1/147/2535/2543",
            url: "165-x-115cm/",
          },
          {
            categoryID: 2597,
            name: "170 x 120cm",
            parent: 2535,
            productsCount: 30,
            path: "1/147/2535/2597",
            url: "170-x-120cm/",
          },
          {
            categoryID: 2613,
            name: "145 X 75cm",
            parent: 2535,
            productsCount: 7,
            path: "1/147/2535/2613",
            url: "145-x-75cm/",
          },
          {
            categoryID: 2791,
            name: "150 x 80cm",
            parent: 2535,
            productsCount: 7,
            path: "1/147/2535/2791",
            url: "150-x-80cm/",
          },
          {
            categoryID: 2541,
            name: "225 x 155cm",
            parent: 2535,
            productsCount: 32,
            path: "1/147/2535/2541",
            url: "225-x-155cm/",
          },
          {
            categoryID: 2595,
            name: "290 x 200cm",
            parent: 2535,
            productsCount: 28,
            path: "1/147/2535/2595",
            url: "290-x-200cm/",
          },
          {
            categoryID: 2795,
            name: "330 x 240cm",
            parent: 2535,
            productsCount: 13,
            path: "1/147/2535/2795",
            url: "330-x-240cm/",
          },
          {
            categoryID: 2799,
            name: "400 x 300cm",
            parent: 2535,
            productsCount: 9,
            path: "1/147/2535/2799",
            url: "400-x-300cm/",
          },
          {
            categoryID: 2789,
            name: "300 x 80cm",
            parent: 2535,
            productsCount: 9,
            path: "1/147/2535/2789",
            url: "300-x-80cm/",
          },
          {
            categoryID: 2793,
            name: "400 x 80cm",
            parent: 2535,
            productsCount: 9,
            path: "1/147/2535/2793",
            url: "400-x-80cm/",
          },
          {
            categoryID: 3011,
            name: "Beige Rug",
            parent: 2535,
            productsCount: 47,
            path: "1/147/2535/3011",
            url: "beige-rug/",
          },
          {
            categoryID: 2539,
            name: "Black And White Rug",
            parent: 2535,
            productsCount: 66,
            path: "1/147/2535/2539",
            url: "black-and-white-rug/",
          },
          {
            categoryID: 3655,
            name: "Blue Rug",
            parent: 2535,
            productsCount: 21,
            path: "1/147/2535/3655",
            url: "blue-rug/",
          },
          {
            categoryID: 3649,
            name: "Brown Rug",
            parent: 2535,
            productsCount: 60,
            path: "1/147/2535/3649",
            url: "brown-rug/",
          },
          {
            categoryID: 2545,
            name: "Green Rug",
            parent: 2535,
            productsCount: 137,
            path: "1/147/2535/2545",
            url: "green-rug/",
          },
          {
            categoryID: 3651,
            name: "Orange Rug",
            parent: 2535,
            productsCount: 5,
            path: "1/147/2535/3651",
            url: "orange-rug/",
          },
          {
            categoryID: 3653,
            name: "Purple Rug",
            parent: 2535,
            productsCount: 14,
            path: "1/147/2535/3653",
            url: "purple-rug/",
          },
          {
            categoryID: 3009,
            name: "Red Rug",
            parent: 2535,
            productsCount: 106,
            path: "1/147/2535/3009",
            url: "red-rug/",
          },
          {
            categoryID: 3975,
            name: "Shaggy Rug",
            parent: 2535,
            productsCount: 289,
            path: "1/147/2535/3975",
            url: "shaggy-rug/",
          },
        ],
        url: "rugs/",
      },
      {
        categoryID: 2081,
        name: "Barware",
        parent: 147,
        productsCount: 273,
        path: "1/147/2081",
        subCategories: [
          {
            categoryID: 3543,
            name: "Wine Cabinet",
            parent: 2081,
            productsCount: 23,
            path: "1/147/2081/3543",
            url: "wine-cabinet/",
          },
          {
            categoryID: 3549,
            name: "Decanter",
            parent: 2081,
            productsCount: 1,
            path: "1/147/2081/3549",
            url: "decanter/",
          },
          {
            categoryID: 3545,
            name: "Wine Rack",
            parent: 2081,
            productsCount: 155,
            path: "1/147/2081/3545",
            url: "wine-rack/",
          },
        ],
        url: "barware/",
      },
      {
        categoryID: 2085,
        name: "Cutlery",
        parent: 147,
        productsCount: 182,
        path: "1/147/2085",
        subCategories: [
          {
            categoryID: 3539,
            name: "Knife Block Set",
            parent: 2085,
            productsCount: 41,
            path: "1/147/2085/3539",
            url: "knife-block-set/",
          },
          {
            categoryID: 3537,
            name: "Cutlery Sets",
            parent: 2085,
            productsCount: 76,
            path: "1/147/2085/3537",
            url: "cutlery-sets/",
          },
          {
            categoryID: 3541,
            name: "Kitchen Scissors",
            parent: 2085,
            productsCount: 10,
            path: "1/147/2085/3541",
            url: "kitchen-scissors/",
          },
          {
            categoryID: 3535,
            name: "Steak Knives",
            parent: 2085,
            productsCount: 9,
            path: "1/147/2085/3535",
            url: "steak-knives/",
          },
        ],
        url: "cutlery/",
      },
    ],
    url: "home-garden/",
  },
  {
    categoryID: 4,
    name: "Sports & Fitness",
    parent: 1,
    productsCount: 6078,
    path: "1/4",
    subCategories: [
      {
        categoryID: 3979,
        name: "Air Track",
        parent: 4,
        productsCount: 55,
        path: "1/4/3979",
        url: "air-track/",
      },
      {
        categoryID: 4125,
        name: "Archery Equipment",
        parent: 4,
        productsCount: 24,
        path: "1/4/4125",
        url: "archery-equipment/",
        subCategories: [
          {
            categoryID: 4127,
            name: "Archery Bows",
            parent: 4125,
            productsCount: 24,
            path: "1/4/4125/4127",
            url: "archery-bows/",
          },
        ],
      },
      {
        categoryID: 3961,
        name: "Hunting",
        parent: 4,
        productsCount: 68,
        path: "1/4/3961",
        url: "hunting/",
        subCategories: [
          {
            categoryID: 3963,
            name: "Rangefinder",
            parent: 3961,
            productsCount: 6,
            path: "1/4/3961/3963",
            url: "rangefinder/",
          },
        ],
      },
      {
        categoryID: 4107,
        name: "Table Tennis",
        parent: 4,
        productsCount: 4,
        path: "1/4/4107",
        url: "table-tennis/",
        subCategories: [
          {
            categoryID: 4109,
            name: "Table Tennis Table",
            parent: 4107,
            productsCount: 3,
            path: "1/4/4107/4109",
            url: "table-tennis-table/",
          },
        ],
      },
      {
        categoryID: 6,
        name: "Gym Equipment",
        parent: 4,
        productsCount: 1627,
        path: "1/4/6",
        subCategories: [
          {
            categoryID: 2301,
            name: "Abdominal Machines",
            parent: 6,
            productsCount: 49,
            path: "1/4/6/2301",
            url: "abdominal-machines/",
          },
          {
            categoryID: 3859,
            name: "Inversion Tables",
            parent: 6,
            productsCount: 20,
            path: "1/4/6/3859",
            url: "inversion-tables/",
          },
          {
            categoryID: 2309,
            name: "Vibration Machines",
            parent: 6,
            productsCount: 57,
            path: "1/4/6/2309",
            url: "vibration-machines/",
          },
          {
            categoryID: 2303,
            name: "Aerobic Steppers",
            parent: 6,
            productsCount: 28,
            path: "1/4/6/2303",
            url: "aerobic-steppers/",
          },
          {
            categoryID: 2305,
            name: "Elliptical Trainers",
            parent: 6,
            productsCount: 33,
            path: "1/4/6/2305",
            url: "elliptical-trainers/",
          },
          {
            categoryID: 2307,
            name: "Exercise Bikes",
            parent: 6,
            productsCount: 169,
            path: "1/4/6/2307",
            url: "exercise-bikes/",
          },
          {
            categoryID: 499,
            name: "Strength Training",
            parent: 6,
            productsCount: 428,
            path: "1/4/6/499",
            url: "strength-training/",
          },
          {
            categoryID: 2509,
            name: "Treadmill",
            parent: 6,
            productsCount: 94,
            path: "1/4/6/2509",
            url: "treadmill/",
          },
          {
            categoryID: 2311,
            name: "Fitness Accessories",
            parent: 6,
            productsCount: 191,
            path: "1/4/6/2311",
            url: "fitness-accessories/",
          },
          {
            categoryID: 2313,
            name: "Rowing Machine",
            parent: 6,
            productsCount: 88,
            path: "1/4/6/2313",
            url: "rowing-machine/",
          },
        ],
        url: "gym-equipment/",
      },
      {
        categoryID: 2587,
        name: "Pilates & Yoga",
        parent: 4,
        productsCount: 255,
        path: "1/4/2587",
        url: "pilates-yoga/",
      },
      {
        categoryID: 5,
        name: "Cycling",
        parent: 4,
        productsCount: 820,
        path: "1/4/5",
        subCategories: [
          {
            categoryID: 2297,
            name: "Bike Accessories",
            parent: 5,
            productsCount: 618,
            path: "1/4/5/2297",
            url: "bike-accessories/",
            subCategories: [
              {
                categoryID: 3909,
                name: "Bike Trailer",
                parent: 2297,
                productsCount: 26,
                path: "1/4/5/2297/3909",
                url: "bike-trailer/",
              },
              {
                categoryID: 4059,
                name: "Bike Rack",
                parent: 2297,
                productsCount: 24,
                path: "1/4/5/2297/4059",
                url: "bike-rack/",
              },
            ],
          },
          {
            categoryID: 2681,
            name: "Bikes",
            parent: 5,
            productsCount: 104,
            path: "1/4/5/2681",
            url: "bikes/",
          },
          {
            categoryID: 4025,
            name: "Electric Bikes",
            parent: 5,
            productsCount: 14,
            path: "1/4/5/4025",
            url: "electric-bikes/",
          },
          {
            categoryID: 2299,
            name: "Unicycles",
            parent: 5,
            productsCount: 13,
            path: "1/4/5/2299",
            url: "unicycles/",
          },
        ],
        url: "cycling/",
      },
      {
        categoryID: 2289,
        name: "Boxing",
        parent: 4,
        productsCount: 183,
        path: "1/4/2289",
        subCategories: [
          {
            categoryID: 2291,
            name: "Boxing Gloves",
            parent: 2289,
            productsCount: 44,
            path: "1/4/2289/2291",
            url: "boxing-gloves/",
          },
          {
            categoryID: 2293,
            name: "Punching Bags & Stands",
            parent: 2289,
            productsCount: 90,
            path: "1/4/2289/2293",
            url: "punching-bags-stands/",
          },
          {
            categoryID: 2295,
            name: "Protective Gear",
            parent: 2289,
            productsCount: 39,
            path: "1/4/2289/2295",
            url: "protective-gear/",
          },
        ],
        url: "boxing/",
      },
      {
        categoryID: 2321,
        name: "Sports Equipment",
        parent: 4,
        productsCount: 612,
        path: "1/4/2321",
        subCategories: [
          {
            categoryID: 4132,
            name: "Ball Storage",
            parent: 2321,
            productsCount: 8,
            path: "1/4/2321/4132",
            url: "ball-storage/",
          },
        ],
        url: "sports-equipment/",
      },
      {
        categoryID: 2945,
        name: "Boating, Water Sports",
        parent: 4,
        productsCount: 376,
        path: "1/4/2945",
        subCategories: [
          {
            categoryID: 2947,
            name: "Kayaks",
            parent: 2945,
            productsCount: 75,
            path: "1/4/2945/2947",
            url: "kayaks/",
          },
          {
            categoryID: 4053,
            name: "Surfboard",
            parent: 2945,
            productsCount: 75,
            path: "1/4/2945/4053",
            url: "surfboard/",
          },
        ],
        url: "boating-water-sports/",
      },
      {
        categoryID: 7,
        name: "Golf",
        parent: 4,
        productsCount: 245,
        path: "1/4/7",
        url: "golf/",
      },
      {
        categoryID: 355,
        name: "Trampolines",
        parent: 4,
        productsCount: 294,
        path: "1/4/355",
        url: "trampolines/",
      },
      {
        categoryID: 2279,
        name: "Ball Sports",
        parent: 4,
        productsCount: 144,
        path: "1/4/2279",
        subCategories: [
          {
            categoryID: 213,
            name: "Basketball",
            parent: 2279,
            productsCount: 75,
            path: "1/4/2279/213",
            url: "basketball/",
          },
          {
            categoryID: 2285,
            name: "Soccer",
            parent: 2279,
            productsCount: 39,
            path: "1/4/2279/2285",
            url: "soccer/",
          },
        ],
        url: "ball-sports/",
      },
      {
        categoryID: 230,
        name: "Fishing",
        parent: 4,
        productsCount: 657,
        path: "1/4/230",
        subCategories: [
          {
            categoryID: 3325,
            name: "Fishing Reels",
            parent: 230,
            productsCount: 105,
            path: "1/4/230/3325",
            url: "fishing-reels/",
          },
          {
            categoryID: 2315,
            name: "Fishing Rods",
            parent: 230,
            productsCount: 38,
            path: "1/4/230/2315",
            url: "fishing-rods/",
          },
          {
            categoryID: 2317,
            name: "Fishing Tackle",
            parent: 230,
            productsCount: 14,
            path: "1/4/230/2317",
            url: "fishing-tackle/",
          },
          {
            categoryID: 2319,
            name: "Fishing Accessories",
            parent: 230,
            productsCount: 189,
            path: "1/4/230/2319",
            url: "fishing-accessories/",
          },
        ],
        url: "fishing/",
      },
      {
        categoryID: 2323,
        name: "Sportswear",
        parent: 4,
        productsCount: 46,
        path: "1/4/2323",
        subCategories: [
          {
            categoryID: 2325,
            name: "Helmets",
            parent: 2323,
            productsCount: 28,
            path: "1/4/2323/2325",
            url: "helmets/",
          },
        ],
        url: "sportswear/",
      },
      {
        categoryID: 2327,
        name: "Compression Wear",
        parent: 4,
        productsCount: 56,
        path: "1/4/2327",
        subCategories: [
          {
            categoryID: 2719,
            name: "Women's Compression Wear",
            parent: 2327,
            productsCount: 19,
            path: "1/4/2327/2719",
            url: "women-s-compression-wear/",
          },
          {
            categoryID: 2717,
            name: "Men's Compression Wear",
            parent: 2327,
            productsCount: 37,
            path: "1/4/2327/2717",
            url: "men-s-compression-wear/",
          },
        ],
        url: "compression-wear/",
      },
      {
        categoryID: 2497,
        name: "Ripstik",
        parent: 4,
        productsCount: 1,
        path: "1/4/2497",
        url: "ripstik/",
      },
      {
        categoryID: 2329,
        name: "Scooters",
        parent: 4,
        productsCount: 186,
        path: "1/4/2329",
        subCategories: [
          {
            categoryID: 3323,
            name: "Electric Scooters",
            parent: 2329,
            productsCount: 86,
            path: "1/4/2329/3323",
            url: "electric-scooters/",
          },
        ],
        url: "scooters/",
      },
      {
        categoryID: 2275,
        name: "AFL",
        parent: 4,
        productsCount: 35,
        path: "1/4/2275",
        url: "afl/",
      },
      {
        categoryID: 238,
        name: "Skateboards",
        parent: 4,
        productsCount: 76,
        path: "1/4/238",
        url: "skateboards/",
      },
      {
        categoryID: 2287,
        name: "Tennis",
        parent: 4,
        productsCount: 33,
        path: "1/4/2287",
        url: "tennis/",
      },
      {
        categoryID: 1459,
        name: "Powerbands",
        parent: 4,
        productsCount: 96,
        path: "1/4/1459",
        url: "powerbands/",
      },
    ],
    url: "sports-fitness/",
  },
  {
    categoryID: 73,
    name: "Pet Supplies",
    parent: 1,
    productsCount: 7863,
    path: "1/73",
    subCategories: [
      {
        categoryID: 4005,
        name: "Beekeeping Supplies",
        parent: 73,
        productsCount: 10,
        path: "1/73/4005",
        url: "beekeeping-supplies/",
      },
      {
        categoryID: 4003,
        name: "Flea, Tick and Worming",
        parent: 73,
        productsCount: 17,
        path: "1/73/4003",
        url: "flea-tick-and-worming/",
      },
      {
        categoryID: 3985,
        name: "Pet Food",
        parent: 73,
        productsCount: 132,
        path: "1/73/3985",
        url: "pet-food/",
      },
      {
        categoryID: 2003,
        name: "Pet Grooming Supplies",
        parent: 73,
        productsCount: 260,
        path: "1/73/2003",
        subCategories: [
          {
            categoryID: 4115,
            name: "Dog Shower & Bath Supplies",
            parent: 2003,
            productsCount: 33,
            path: "1/73/2003/4115",
            url: "dog-shower-bath-supplies/",
          },
          {
            categoryID: 4069,
            name: "Pet Hair Clippers & Trimmers",
            parent: 2003,
            productsCount: 59,
            path: "1/73/2003/4069",
            subCategories: [
              {
                categoryID: 4075,
                name: "Hair Clippers",
                parent: 4069,
                productsCount: 23,
                path: "1/73/2003/4069/4075",
                url: "hair-clippers/",
              },
              {
                categoryID: 4071,
                name: "Pet Dryer",
                parent: 4069,
                productsCount: 6,
                path: "1/73/2003/4069/4071",
                url: "pet-dryer/",
              },
              {
                categoryID: 4073,
                name: "Nail Trimmers",
                parent: 4069,
                productsCount: 8,
                path: "1/73/2003/4069/4073",
                url: "nail-trimmers/",
              },
            ],
            url: "pet-hair-clippers-trimmers/",
          },
          {
            categoryID: 3999,
            name: "Shampoo and Conditioner",
            parent: 2003,
            productsCount: 14,
            path: "1/73/2003/3999",
            url: "shampoo-and-conditioner/",
          },
        ],
        url: "pet-grooming-supplies/",
      },
      {
        categoryID: 3937,
        name: "Pet Safety",
        parent: 73,
        productsCount: 105,
        path: "1/73/3937",
        subCategories: [
          {
            categoryID: 3939,
            name: "Safety Gate",
            parent: 3937,
            productsCount: 44,
            path: "1/73/3937/3939",
            url: "safety-gate/",
          },
          {
            categoryID: 3941,
            name: "Safety Playpen",
            parent: 3937,
            productsCount: 47,
            path: "1/73/3937/3941",
            url: "safety-playpen/",
          },
        ],
        url: "pet-safety/",
      },
      {
        categoryID: 74,
        name: "Dog Supplies",
        parent: 73,
        productsCount: 3252,
        path: "1/73/74",
        subCategories: [
          {
            categoryID: 4067,
            name: "Dog Beds ",
            parent: 74,
            productsCount: 412,
            path: "1/73/74/4067",
            url: "dog-beds/",
          },
          {
            categoryID: 3013,
            name: "Dog Collars & Harness",
            parent: 74,
            productsCount: 396,
            path: "1/73/74/3013",
            url: "dog-collars-harness/",
          },
          {
            categoryID: 1975,
            name: "Dog Crates",
            parent: 74,
            productsCount: 335,
            path: "1/73/74/1975",
            url: "dog-crates/",
          },
          {
            categoryID: 3951,
            name: "Dog Steps",
            parent: 74,
            productsCount: 108,
            path: "1/73/74/3951",
            url: "dog-steps/",
          },
          {
            categoryID: 4079,
            name: "Dog Kennel Enclosure",
            parent: 74,
            productsCount: 93,
            path: "1/73/74/4079",
            url: "dog-kennel-enclosure/",
          },
          {
            categoryID: 4077,
            name: "Dog House",
            parent: 74,
            productsCount: 26,
            path: "1/73/74/4077",
            url: "dog-house/",
          },
          {
            categoryID: 1977,
            name: "Dog Apparel",
            parent: 74,
            productsCount: 161,
            path: "1/73/74/1977",
            url: "dog-apparel/",
          },
          {
            categoryID: 1979,
            name: "Dog Doors",
            parent: 74,
            productsCount: 24,
            path: "1/73/74/1979",
            url: "dog-doors/",
          },
          {
            categoryID: 1981,
            name: "Dog Kennels",
            parent: 74,
            productsCount: 266,
            path: "1/73/74/1981",
            url: "dog-kennels/",
          },
          {
            categoryID: 1985,
            name: "Dog Training",
            parent: 74,
            productsCount: 595,
            path: "1/73/74/1985",
            url: "dog-training/",
          },
          {
            categoryID: 1987,
            name: "Dog Accessories",
            parent: 74,
            productsCount: 599,
            path: "1/73/74/1987",
            url: "dog-accessories/",
          },
        ],
        url: "dog-supplies/",
      },
      {
        categoryID: 75,
        name: "Cat Supplies",
        parent: 73,
        productsCount: 1088,
        path: "1/73/75",
        subCategories: [
          {
            categoryID: 4083,
            name: "Cat Beds",
            parent: 75,
            productsCount: 13,
            path: "1/73/75/4083",
            url: "cat-beds/",
          },
          {
            categoryID: 1967,
            name: "Cat Enclosures",
            parent: 75,
            productsCount: 86,
            path: "1/73/75/1967",
            url: "cat-enclosures/",
          },
          {
            categoryID: 1969,
            name: "Cat Flaps",
            parent: 75,
            productsCount: 11,
            path: "1/73/75/1969",
            url: "cat-flaps/",
          },
          {
            categoryID: 1971,
            name: "Cat Toys",
            parent: 75,
            productsCount: 119,
            path: "1/73/75/1971",
            url: "cat-toys/",
          },
          {
            categoryID: 2621,
            name: "Cat Litter",
            parent: 75,
            productsCount: 127,
            path: "1/73/75/2621",
            url: "cat-litter/",
          },
          {
            categoryID: 1973,
            name: "Cat Scratching Post & Cat Tree",
            parent: 75,
            productsCount: 564,
            path: "1/73/75/1973",
            url: "cat-scratching-post-cat-tree/",
          },
        ],
        url: "cat-supplies/",
      },
      {
        categoryID: 1983,
        name: "Puppy Playpen",
        parent: 73,
        productsCount: 178,
        path: "1/73/1983",
        url: "puppy-playpen/",
      },
      {
        categoryID: 1963,
        name: "Bird Supplies",
        parent: 73,
        productsCount: 272,
        path: "1/73/1963",
        subCategories: [
          {
            categoryID: 2747,
            name: "Bird Accessories",
            parent: 1963,
            productsCount: 5,
            path: "1/73/1963/2747",
            url: "bird-accessories/",
          },
          {
            categoryID: 1965,
            name: "Bird Cages",
            parent: 1963,
            productsCount: 117,
            path: "1/73/1963/1965",
            url: "bird-cages/",
          },
          {
            categoryID: 4085,
            name: "Bird Stands",
            parent: 1963,
            productsCount: 3,
            path: "1/73/1963/4085",
            url: "bird-stands/",
          },
          {
            categoryID: 3869,
            name: "Egg Incubators",
            parent: 1963,
            productsCount: 89,
            path: "1/73/1963/3869",
            url: "egg-incubators/",
          },
          {
            categoryID: 2507,
            name: "Bird Feeder",
            parent: 1963,
            productsCount: 58,
            path: "1/73/1963/2507",
            url: "bird-feeder/",
          },
        ],
        url: "bird-supplies/",
      },
      {
        categoryID: 1999,
        name: "Automatic Pet Feeder",
        parent: 73,
        productsCount: 396,
        path: "1/73/1999",
        subCategories: [
          {
            categoryID: 4081,
            name: "Chicken Feeder",
            parent: 1999,
            productsCount: 68,
            path: "1/73/1999/4081",
            url: "chicken-feeder/",
          },
        ],
        url: "automatic-pet-feeder/",
      },
      {
        categoryID: 2005,
        name: "Pet Travel",
        parent: 73,
        productsCount: 312,
        path: "1/73/2005",
        url: "pet-travel/",
      },
      {
        categoryID: 100,
        name: "Rabbit, Guinea Pig & Hamster Supplies",
        parent: 73,
        productsCount: 393,
        path: "1/73/100",
        subCategories: [
          {
            categoryID: 4089,
            name: "Chicken Fence",
            parent: 100,
            productsCount: 23,
            path: "1/73/100/4089",
            url: "chicken-fence/",
          },
          {
            categoryID: 4087,
            name: "Chicken Coop",
            parent: 100,
            productsCount: 133,
            path: "1/73/100/4087",
            url: "chicken-coop/",
          },
          {
            categoryID: 2007,
            name: "Hutches",
            parent: 100,
            productsCount: 187,
            path: "1/73/100/2007",
            url: "hutches/",
          },
        ],
        url: "rabbit-guinea-pig-hamster-supplies/",
      },
      {
        categoryID: 2001,
        name: "Pet Health Supplies",
        parent: 73,
        productsCount: 42,
        path: "1/73/2001",
        url: "pet-health-supplies/",
      },
      {
        categoryID: 1997,
        name: "Pet Beds",
        parent: 73,
        productsCount: 676,
        path: "1/73/1997",
        subCategories: [
          {
            categoryID: 4133,
            name: "Heated Pet Beds & Mats",
            parent: 1997,
            productsCount: 16,
            path: "1/73/1997/4133",
            url: "heated-pet-beds-mats/",
          },
        ],
        url: "pet-beds/",
      },
      {
        categoryID: 1989,
        name: "Fish Supplies",
        parent: 73,
        productsCount: 154,
        path: "1/73/1989",
        subCategories: [
          {
            categoryID: 1991,
            name: "Fish Bowls",
            parent: 1989,
            productsCount: 20,
            path: "1/73/1989/1991",
            url: "fish-bowls/",
          },
          {
            categoryID: 2489,
            name: "Fish Feeders",
            parent: 1989,
            productsCount: 1,
            path: "1/73/1989/2489",
            url: "fish-feeders/",
          },
          {
            categoryID: 4029,
            name: "Fish Lights",
            parent: 1989,
            productsCount: 25,
            path: "1/73/1989/4029",
            url: "fish-lights/",
          },
          {
            categoryID: 4031,
            name: "Fish Tank",
            parent: 1989,
            productsCount: 12,
            path: "1/73/1989/4031",
            url: "fish-tank/",
          },
        ],
        url: "fish-supplies/",
      },
      {
        categoryID: 233,
        name: "Horse Supplies",
        parent: 73,
        productsCount: 4,
        path: "1/73/233",
        url: "horse-supplies/",
      },
      {
        categoryID: 1993,
        name: "Mice & Rat Supplies",
        parent: 73,
        productsCount: 11,
        path: "1/73/1993",
        subCategories: [
          {
            categoryID: 1995,
            name: "Cages",
            parent: 1993,
            productsCount: 5,
            path: "1/73/1993/1995",
            url: "cages/",
          },
        ],
        url: "mice-rat-supplies/",
      },
    ],
    url: "pet-supplies/",
  },
  {
    categoryID: 2959,
    name: "Baby, Kids & Toys",
    parent: 1,
    productsCount: 10473,
    path: "1/2959",
    subCategories: [
      {
        categoryID: 45,
        name: "Toys & Hobbies",
        parent: 2959,
        productsCount: 10276,
        path: "1/2959/45",
        subCategories: [
          {
            categoryID: 3915,
            name: "Bubble Machines",
            parent: 45,
            productsCount: 24,
            path: "1/2959/45/3915",
            url: "bubble-machines/",
          },
          {
            categoryID: 3851,
            name: "Card Games",
            parent: 45,
            productsCount: 680,
            path: "1/2959/45/3851",
            url: "card-games/",
          },
          {
            categoryID: 2363,
            name: "Outdoor Toys",
            parent: 45,
            productsCount: 776,
            path: "1/2959/45/2363",
            url: "outdoor-toys/",
          },
          {
            categoryID: 2387,
            name: "Outdoor Activities",
            parent: 45,
            productsCount: 45,
            path: "1/2959/45/2387",
            url: "outdoor-activities/",
          },
          {
            categoryID: 286,
            name: "Ride On Toys",
            parent: 45,
            productsCount: 814,
            path: "1/2959/45/286",
            subCategories: [
              {
                categoryID: 2425,
                name: "Electric Ride On Toys",
                parent: 286,
                productsCount: 396,
                path: "1/2959/45/286/2425",
                url: "electric-ride-on-toys/",
              },
              {
                categoryID: 3321,
                name: "Kids Scooters",
                parent: 286,
                productsCount: 68,
                path: "1/2959/45/286/3321",
                url: "kids-scooters/",
              },
              {
                categoryID: 2429,
                name: "Other Ride On Toys",
                parent: 286,
                productsCount: 139,
                path: "1/2959/45/286/2429",
                url: "other-ride-on-toys/",
              },
            ],
            url: "ride-on-toys/",
          },
          {
            categoryID: 2427,
            name: "Tricycles",
            parent: 45,
            productsCount: 21,
            path: "1/2959/45/2427",
            url: "tricycles/",
          },
          {
            categoryID: 2399,
            name: "Kitchen",
            parent: 45,
            productsCount: 43,
            path: "1/2959/45/2399",
            url: "kitchen/",
          },
          {
            categoryID: 2389,
            name: "Pretend Play",
            parent: 45,
            productsCount: 450,
            path: "1/2959/45/2389",
            url: "pretend-play/",
          },
          {
            categoryID: 297,
            name: "Arts & Crafts",
            parent: 45,
            productsCount: 265,
            path: "1/2959/45/297",
            url: "arts-crafts/",
          },
          {
            categoryID: 2359,
            name: "Electronic Toys",
            parent: 45,
            productsCount: 436,
            path: "1/2959/45/2359",
            url: "electronic-toys/",
          },
          {
            categoryID: 2411,
            name: "Musical Toys",
            parent: 45,
            productsCount: 285,
            path: "1/2959/45/2411",
            url: "musical-toys/",
          },
          {
            categoryID: 2405,
            name: "Tool Sets",
            parent: 45,
            productsCount: 29,
            path: "1/2959/45/2405",
            url: "tool-sets/",
          },
          {
            categoryID: 421,
            name: "Board Games",
            parent: 45,
            productsCount: 163,
            path: "1/2959/45/421",
            url: "board-games/",
          },
          {
            categoryID: 2357,
            name: "Building Blocks & Construction",
            parent: 45,
            productsCount: 365,
            path: "1/2959/45/2357",
            url: "building-blocks-construction/",
          },
          {
            categoryID: 631,
            name: "Wooden Toys",
            parent: 45,
            productsCount: 88,
            path: "1/2959/45/631",
            url: "wooden-toys/",
          },
          {
            categoryID: 2391,
            name: "Activity Toys",
            parent: 45,
            productsCount: 373,
            path: "1/2959/45/2391",
            url: "activity-toys/",
          },
          {
            categoryID: 209,
            name: "Remote Control Toys",
            parent: 45,
            productsCount: 2566,
            path: "1/2959/45/209",
            subCategories: [
              {
                categoryID: 3049,
                name: "Remote Control Quadcopter",
                parent: 209,
                productsCount: 398,
                path: "1/2959/45/209/3049",
                url: "remote-control-quadcopter/",
              },
              {
                categoryID: 3053,
                name: "Remote Control Tank",
                parent: 209,
                productsCount: 4,
                path: "1/2959/45/209/3053",
                url: "remote-control-tank/",
              },
              {
                categoryID: 3051,
                name: "Remote Control Trucks",
                parent: 209,
                productsCount: 77,
                path: "1/2959/45/209/3051",
                url: "remote-control-trucks/",
              },
              {
                categoryID: 2419,
                name: "Remote Control Boats",
                parent: 209,
                productsCount: 140,
                path: "1/2959/45/209/2419",
                url: "remote-control-boats/",
              },
              {
                categoryID: 2417,
                name: "Remote Control Cars",
                parent: 209,
                productsCount: 1348,
                path: "1/2959/45/209/2417",
                url: "remote-control-cars/",
              },
              {
                categoryID: 2415,
                name: "Remote Control Helicopter",
                parent: 209,
                productsCount: 380,
                path: "1/2959/45/209/2415",
                url: "remote-control-helicopter/",
              },
            ],
            url: "remote-control-toys/",
          },
          {
            categoryID: 1543,
            name: "Preschool Toys",
            parent: 45,
            productsCount: 100,
            path: "1/2959/45/1543",
            url: "preschool-toys/",
          },
          {
            categoryID: 361,
            name: "Puzzles",
            parent: 45,
            productsCount: 199,
            path: "1/2959/45/361",
            url: "puzzles/",
          },
          {
            categoryID: 2393,
            name: "Cleaning",
            parent: 45,
            productsCount: 11,
            path: "1/2959/45/2393",
            url: "cleaning/",
          },
          {
            categoryID: 2355,
            name: "Action Figures & Toys",
            parent: 45,
            productsCount: 242,
            path: "1/2959/45/2355",
            url: "action-figures-toys/",
          },
          {
            categoryID: 2407,
            name: "Other Pretend Play",
            parent: 45,
            productsCount: 39,
            path: "1/2959/45/2407",
            url: "other-pretend-play/",
          },
          {
            categoryID: 277,
            name: "Soft Toys",
            parent: 45,
            productsCount: 355,
            path: "1/2959/45/277",
            url: "soft-toys/",
          },
          {
            categoryID: 349,
            name: "Water Toys",
            parent: 45,
            productsCount: 155,
            path: "1/2959/45/349",
            url: "water-toys/",
          },
          {
            categoryID: 348,
            name: "Educational Toys",
            parent: 45,
            productsCount: 697,
            path: "1/2959/45/348",
            url: "educational-toys/",
          },
          {
            categoryID: 2395,
            name: "Dolls",
            parent: 45,
            productsCount: 261,
            path: "1/2959/45/2395",
            url: "dolls/",
          },
          {
            categoryID: 2413,
            name: "Other Preschool Toys",
            parent: 45,
            productsCount: 54,
            path: "1/2959/45/2413",
            url: "other-preschool-toys/",
          },
          {
            categoryID: 2409,
            name: "Bathtime Toys",
            parent: 45,
            productsCount: 47,
            path: "1/2959/45/2409",
            url: "bathtime-toys/",
          },
          {
            categoryID: 2421,
            name: "Slot Cars",
            parent: 45,
            productsCount: 5,
            path: "1/2959/45/2421",
            url: "slot-cars/",
          },
          {
            categoryID: 459,
            name: "Collectables",
            parent: 45,
            productsCount: 113,
            path: "1/2959/45/459",
            url: "collectables/",
          },
          {
            categoryID: 2423,
            name: "Other Radio Control",
            parent: 45,
            productsCount: 19,
            path: "1/2959/45/2423",
            url: "other-radio-control/",
          },
          {
            categoryID: 2729,
            name: "Kids Costumes",
            parent: 45,
            productsCount: 59,
            path: "1/2959/45/2729",
            url: "kids-costumes/",
          },
          {
            categoryID: 2431,
            name: "Diecast Vehicles",
            parent: 45,
            productsCount: 46,
            path: "1/2959/45/2431",
            url: "diecast-vehicles/",
          },
          {
            categoryID: 2397,
            name: "Doll Playsets",
            parent: 45,
            productsCount: 35,
            path: "1/2959/45/2397",
            url: "doll-playsets/",
          },
          {
            categoryID: 2385,
            name: "Play Houses",
            parent: 45,
            productsCount: 46,
            path: "1/2959/45/2385",
            url: "play-houses/",
          },
          {
            categoryID: 2361,
            name: "Magic Tricks",
            parent: 45,
            productsCount: 49,
            path: "1/2959/45/2361",
            url: "magic-tricks/",
          },
          {
            categoryID: 2401,
            name: "Puppets",
            parent: 45,
            productsCount: 4,
            path: "1/2959/45/2401",
            url: "puppets/",
          },
          {
            categoryID: 2403,
            name: "Shops",
            parent: 45,
            productsCount: 3,
            path: "1/2959/45/2403",
            url: "shops/",
          },
        ],
        url: "toys-hobbies/",
      },
      {
        categoryID: 2,
        name: "Baby",
        parent: 2959,
        productsCount: 2290,
        path: "1/2959/2",
        subCategories: [
          {
            categoryID: 1463,
            name: "Toilet Training & Baby Bath Products",
            parent: 2,
            productsCount: 17,
            path: "1/2959/2/1463",
            url: "toilet-training-baby-bath-products/",
          },
          {
            categoryID: 3917,
            name: "Nappies",
            parent: 2,
            productsCount: 5,
            path: "1/2959/2/3917",
            url: "nappies/",
          },
          {
            categoryID: 310,
            name: "Baby Safety & Health",
            parent: 2,
            productsCount: 319,
            path: "1/2959/2/310",
            url: "baby-safety-health/",
          },
          {
            categoryID: 1517,
            name: "Baby Storage",
            parent: 2,
            productsCount: 30,
            path: "1/2959/2/1517",
            url: "baby-storage/",
          },
          {
            categoryID: 1477,
            name: "Baby Cups, Dishes, Utensils",
            parent: 2,
            productsCount: 113,
            path: "1/2959/2/1477",
            url: "baby-cups-dishes-utensils/",
          },
          {
            categoryID: 2529,
            name: "Baby Diapers",
            parent: 2,
            productsCount: 12,
            path: "1/2959/2/2529",
            url: "baby-diapers/",
          },
          {
            categoryID: 1487,
            name: "Baby Swings",
            parent: 2,
            productsCount: 9,
            path: "1/2959/2/1487",
            url: "baby-swings/",
          },
          {
            categoryID: 1525,
            name: "Baby Proofing",
            parent: 2,
            productsCount: 32,
            path: "1/2959/2/1525",
            url: "baby-proofing/",
          },
          {
            categoryID: 1547,
            name: "Nappy Disposal",
            parent: 2,
            productsCount: 7,
            path: "1/2959/2/1547",
            url: "nappy-disposal/",
          },
          {
            categoryID: 2525,
            name: "Baby Care",
            parent: 2,
            productsCount: 84,
            path: "1/2959/2/2525",
            url: "baby-care/",
          },
          {
            categoryID: 1479,
            name: "Baby High Chairs",
            parent: 2,
            productsCount: 32,
            path: "1/2959/2/1479",
            url: "baby-high-chairs/",
          },
          {
            categoryID: 2699,
            name: "Baby Change Table",
            parent: 2,
            productsCount: 78,
            path: "1/2959/2/2699",
            url: "baby-change-table/",
          },
          {
            categoryID: 1489,
            name: "Rockers, Bouncers",
            parent: 2,
            productsCount: 26,
            path: "1/2959/2/1489",
            url: "rockers-bouncers/",
          },
          {
            categoryID: 441,
            name: "Car Seats",
            parent: 2,
            productsCount: 97,
            path: "1/2959/2/441",
            url: "car-seats/",
          },
          {
            categoryID: 1519,
            name: "Mobiles",
            parent: 2,
            productsCount: 4,
            path: "1/2959/2/1519",
            url: "mobiles/",
          },
          {
            categoryID: 1533,
            name: "Baby Musical Toys",
            parent: 2,
            productsCount: 43,
            path: "1/2959/2/1533",
            url: "baby-musical-toys/",
          },
          {
            categoryID: 2449,
            name: "Other Baby Safety",
            parent: 2,
            productsCount: 49,
            path: "1/2959/2/2449",
            url: "other-baby-safety/",
          },
          {
            categoryID: 365,
            name: "Baby Feeding",
            parent: 2,
            productsCount: 69,
            path: "1/2959/2/365",
            url: "baby-feeding/",
          },
          {
            categoryID: 307,
            name: "Baby Bedding & Blankets",
            parent: 2,
            productsCount: 166,
            path: "1/2959/2/307",
            url: "baby-bedding-blankets/",
          },
          {
            categoryID: 1481,
            name: "Baby Sterilisers",
            parent: 2,
            productsCount: 8,
            path: "1/2959/2/1481",
            url: "baby-sterilisers/",
          },
          {
            categoryID: 2739,
            name: "Baby Clothes",
            parent: 2,
            productsCount: 18,
            path: "1/2959/2/2739",
            url: "baby-clothes/",
          },
          {
            categoryID: 1495,
            name: "Safety Chairs",
            parent: 2,
            productsCount: 17,
            path: "1/2959/2/1495",
            url: "safety-chairs/",
          },
          {
            categoryID: 1521,
            name: "Wall Decor",
            parent: 2,
            productsCount: 26,
            path: "1/2959/2/1521",
            url: "wall-decor/",
          },
          {
            categoryID: 309,
            name: "Baby Furniture & Decor",
            parent: 2,
            productsCount: 90,
            path: "1/2959/2/309",
            subCategories: [
              {
                categoryID: 3055,
                name: "Toy Box",
                parent: 309,
                productsCount: 53,
                path: "1/2959/2/309/3055",
                url: "toy-box/",
              },
            ],
            url: "baby-furniture-decor/",
          },
          {
            categoryID: 1537,
            name: "Baby Rattles",
            parent: 2,
            productsCount: 6,
            path: "1/2959/2/1537",
            url: "baby-rattles/",
          },
          {
            categoryID: 1483,
            name: "Breastfeeding Supplies",
            parent: 2,
            productsCount: 72,
            path: "1/2959/2/1483",
            url: "breastfeeding-supplies/",
          },
          {
            categoryID: 1539,
            name: "Baby Soft Toys",
            parent: 2,
            productsCount: 11,
            path: "1/2959/2/1539",
            url: "baby-soft-toys/",
          },
          {
            categoryID: 1465,
            name: "Baby Nursery Decor",
            parent: 2,
            productsCount: 66,
            path: "1/2959/2/1465",
            url: "baby-nursery-decor/",
          },
          {
            categoryID: 1473,
            name: "Baby Toilet Training",
            parent: 2,
            productsCount: 48,
            path: "1/2959/2/1473",
            url: "baby-toilet-training/",
          },
          {
            categoryID: 1541,
            name: "Baby Walker",
            parent: 2,
            productsCount: 18,
            path: "1/2959/2/1541",
            url: "baby-walker/",
          },
          {
            categoryID: 1523,
            name: "Baby Monitor",
            parent: 2,
            productsCount: 71,
            path: "1/2959/2/1523",
            url: "baby-monitor/",
          },
          {
            categoryID: 1529,
            name: "Baby Bath Toys",
            parent: 2,
            productsCount: 67,
            path: "1/2959/2/1529",
            url: "baby-bath-toys/",
          },
          {
            categoryID: 2465,
            name: "Baby Bath",
            parent: 2,
            productsCount: 53,
            path: "1/2959/2/2465",
            url: "baby-bath/",
          },
          {
            categoryID: 311,
            name: "Baby Toys",
            parent: 2,
            productsCount: 78,
            path: "1/2959/2/311",
            subCategories: [
              {
                categoryID: 3059,
                name: "Teething Toys",
                parent: 311,
                productsCount: 2,
                path: "1/2959/2/311/3059",
                url: "teething-toys/",
              },
            ],
            url: "baby-toys/",
          },
          {
            categoryID: 1531,
            name: "Baby Educational Toys",
            parent: 2,
            productsCount: 81,
            path: "1/2959/2/1531",
            url: "baby-educational-toys/",
          },
          {
            categoryID: 1467,
            name: "Baby Travel",
            parent: 2,
            productsCount: 35,
            path: "1/2959/2/1467",
            url: "baby-travel/",
          },
          {
            categoryID: 1469,
            name: "Nappies, Nappy Bags & Accessories",
            parent: 2,
            productsCount: 20,
            path: "1/2959/2/1469",
            url: "nappies-nappy-bags-accessories/",
          },
          {
            categoryID: 1535,
            name: "Baby Wooden Toys",
            parent: 2,
            productsCount: 2,
            path: "1/2959/2/1535",
            url: "baby-wooden-toys/",
          },
          {
            categoryID: 1471,
            name: "Prams & Strollers",
            parent: 2,
            productsCount: 107,
            path: "1/2959/2/1471",
            url: "prams-strollers/",
          },
          {
            categoryID: 1527,
            name: "Playmats, Play Gyms & Activity Centres",
            parent: 2,
            productsCount: 205,
            path: "1/2959/2/1527",
            url: "playmats-play-gyms-activity-centres/",
          },
          {
            categoryID: 1485,
            name: "Baby Bassinet",
            parent: 2,
            productsCount: 26,
            path: "1/2959/2/1485",
            url: "baby-bassinet/",
          },
          {
            categoryID: 1475,
            name: "Baby Bottle Warmers",
            parent: 2,
            productsCount: 16,
            path: "1/2959/2/1475",
            url: "baby-bottle-warmers/",
          },
          {
            categoryID: 2467,
            name: "Baby Carriers",
            parent: 2,
            productsCount: 31,
            path: "1/2959/2/2467",
            url: "baby-carriers/",
          },
          {
            categoryID: 439,
            name: "Baby Bags",
            parent: 2,
            productsCount: 26,
            path: "1/2959/2/439",
            url: "baby-bags/",
          },
          {
            categoryID: 1513,
            name: "Baby Lamps, Lights",
            parent: 2,
            productsCount: 18,
            path: "1/2959/2/1513",
            url: "baby-lamps-lights/",
          },
        ],
        url: "baby/",
      },
    ],
    url: "baby-kids-toys/",
  },
  {
    categoryID: 334,
    name: "Outdoor & Leisure",
    parent: 1,
    productsCount: 5630,
    path: "1/334",
    subCategories: [
      {
        categoryID: 511,
        name: "Pool Supplies",
        parent: 334,
        productsCount: 1009,
        path: "1/334/511",
        subCategories: [
          {
            categoryID: 3067,
            name: "Pool Cover Roller",
            parent: 511,
            productsCount: 21,
            path: "1/334/511/3067",
            url: "pool-cover-roller/",
          },
          {
            categoryID: 3907,
            name: "Glass Fencing",
            parent: 511,
            productsCount: 3,
            path: "1/334/511/3907",
            url: "glass-fencing/",
          },
          {
            categoryID: 3063,
            name: "Inflatable SPA",
            parent: 511,
            productsCount: 29,
            path: "1/334/511/3063",
            url: "inflatable-spa/",
          },
          {
            categoryID: 3873,
            name: "Pool Ladder",
            parent: 511,
            productsCount: 34,
            path: "1/334/511/3873",
            url: "pool-ladder/",
          },
          {
            categoryID: 3065,
            name: "Water Slides",
            parent: 511,
            productsCount: 4,
            path: "1/334/511/3065",
            url: "water-slides/",
          },
          {
            categoryID: 2239,
            name: "Pool Covers",
            parent: 511,
            productsCount: 204,
            path: "1/334/511/2239",
            url: "pool-covers/",
          },
          {
            categoryID: 2241,
            name: "Pool Cleaners",
            parent: 511,
            productsCount: 93,
            path: "1/334/511/2241",
            url: "pool-cleaners/",
          },
          {
            categoryID: 2243,
            name: "Pool Filters",
            parent: 511,
            productsCount: 112,
            path: "1/334/511/2243",
            url: "pool-filters/",
          },
          {
            categoryID: 2245,
            name: "Pool Toys",
            parent: 511,
            productsCount: 117,
            path: "1/334/511/2245",
            url: "pool-toys/",
          },
        ],
        url: "pool-supplies/",
      },
      {
        categoryID: 1841,
        name: "Camping Gear",
        parent: 334,
        productsCount: 2417,
        path: "1/334/1841",
        subCategories: [
          {
            categoryID: 1843,
            name: "Backpacks",
            parent: 1841,
            productsCount: 106,
            path: "1/334/1841/1843",
            url: "backpacks/",
          },
          {
            categoryID: 3095,
            name: "Camping Beds",
            parent: 1841,
            productsCount: 26,
            path: "1/334/1841/3095",
            url: "camping-beds/",
          },
          {
            categoryID: 3083,
            name: "Camping Toilet",
            parent: 1841,
            productsCount: 56,
            path: "1/334/1841/3083",
            url: "camping-toilet/",
          },
          {
            categoryID: 3087,
            name: "Camping Shower",
            parent: 1841,
            productsCount: 60,
            path: "1/334/1841/3087",
            url: "camping-shower/",
          },
          {
            categoryID: 3093,
            name: "Camping Mattress",
            parent: 1841,
            productsCount: 27,
            path: "1/334/1841/3093",
            url: "camping-mattress/",
          },
          {
            categoryID: 3089,
            name: "Portable Water Tanks",
            parent: 1841,
            productsCount: 34,
            path: "1/334/1841/3089",
            url: "portable-water-tanks/",
          },
          {
            categoryID: 2255,
            name: "Caravan Covers",
            parent: 1841,
            productsCount: 129,
            path: "1/334/1841/2255",
            url: "caravan-covers/",
          },
          {
            categoryID: 2257,
            name: "Compasses",
            parent: 1841,
            productsCount: 5,
            path: "1/334/1841/2257",
            url: "compasses/",
          },
          {
            categoryID: 2261,
            name: "Flashlight",
            parent: 1841,
            productsCount: 149,
            path: "1/334/1841/2261",
            url: "flashlight/",
          },
          {
            categoryID: 2263,
            name: "Sleeping Bags",
            parent: 1841,
            productsCount: 191,
            path: "1/334/1841/2263",
            url: "sleeping-bags/",
          },
          {
            categoryID: 2265,
            name: "Tents & Canopies",
            parent: 1841,
            productsCount: 703,
            path: "1/334/1841/2265",
            url: "tents-canopies/",
          },
        ],
        url: "camping-gear/",
      },
      {
        categoryID: 2237,
        name: "Pools",
        parent: 334,
        productsCount: 275,
        path: "1/334/2237",
        subCategories: [
          {
            categoryID: 3069,
            name: "Above Ground Pools",
            parent: 2237,
            productsCount: 110,
            path: "1/334/2237/3069",
            url: "above-ground-pools/",
          },
          {
            categoryID: 3071,
            name: "Inflatable Pool",
            parent: 2237,
            productsCount: 135,
            path: "1/334/2237/3071",
            url: "inflatable-pool/",
          },
          {
            categoryID: 3073,
            name: "Kids Pool",
            parent: 2237,
            productsCount: 25,
            path: "1/334/2237/3073",
            url: "kids-pool/",
          },
        ],
        url: "pools/",
      },
      {
        categoryID: 2611,
        name: "Boats & Kayaks",
        parent: 334,
        productsCount: 262,
        path: "1/334/2611",
        subCategories: [
          {
            categoryID: 4121,
            name: "Boat Parts & Accessories",
            parent: 2611,
            productsCount: 12,
            path: "1/334/2611/4121",
            url: "boat-parts-accessories/",
          },
          {
            categoryID: 3875,
            name: "Boat Bimini",
            parent: 2611,
            productsCount: 20,
            path: "1/334/2611/3875",
            url: "boat-bimini/",
          },
          {
            categoryID: 3103,
            name: "Boat Covers",
            parent: 2611,
            productsCount: 59,
            path: "1/334/2611/3103",
            url: "boat-covers/",
          },
          {
            categoryID: 3097,
            name: "Boat Seats",
            parent: 2611,
            productsCount: 62,
            path: "1/334/2611/3097",
            url: "boat-seats/",
          },
          {
            categoryID: 3101,
            name: "Inflatable Boats",
            parent: 2611,
            productsCount: 32,
            path: "1/334/2611/3101",
            url: "inflatable-boats/",
          },
          {
            categoryID: 3105,
            name: "Electric Outboard Motor",
            parent: 2611,
            productsCount: 15,
            path: "1/334/2611/3105",
            url: "electric-outboard-motor/",
          },
          {
            categoryID: 3107,
            name: "Fishing Rod Holders",
            parent: 2611,
            productsCount: 1,
            path: "1/334/2611/3107",
            url: "fishing-rod-holders/",
          },
          {
            categoryID: 3099,
            name: "Inflatable Kayak",
            parent: 2611,
            productsCount: 19,
            path: "1/334/2611/3099",
            url: "inflatable-kayak/",
          },
        ],
        url: "boats-kayaks/",
      },
      {
        categoryID: 2247,
        name: "Marquees",
        parent: 334,
        productsCount: 376,
        path: "1/334/2247",
        subCategories: [
          {
            categoryID: 3061,
            name: "Canopies",
            parent: 2247,
            productsCount: 210,
            path: "1/334/2247/3061",
            url: "canopies/",
          },
        ],
        url: "marquees/",
      },
      {
        categoryID: 1807,
        name: "Telescopes",
        parent: 334,
        productsCount: 252,
        path: "1/334/1807",
        subCategories: [
          {
            categoryID: 3075,
            name: "Astronomical Telescope",
            parent: 1807,
            productsCount: 22,
            path: "1/334/1807/3075",
            url: "astronomical-telescope/",
          },
          {
            categoryID: 3077,
            name: "Binoculars",
            parent: 1807,
            productsCount: 76,
            path: "1/334/1807/3077",
            url: "binoculars/",
          },
          {
            categoryID: 3081,
            name: "Monoculars",
            parent: 1807,
            productsCount: 51,
            path: "1/334/1807/3081",
            url: "monoculars/",
          },
        ],
        url: "telescopes/",
      },
      {
        categoryID: 338,
        name: "Umbrella",
        parent: 334,
        productsCount: 1343,
        path: "1/334/338",
        subCategories: [
          {
            categoryID: 2249,
            name: "Shade Sails",
            parent: 338,
            productsCount: 358,
            path: "1/334/338/2249",
            url: "shade-sails/",
          },
          {
            categoryID: 2251,
            name: "Outdoor Umbrella",
            parent: 338,
            productsCount: 734,
            path: "1/334/338/2251",
            url: "outdoor-umbrella/",
          },
          {
            categoryID: 2253,
            name: "Sun Tents",
            parent: 338,
            productsCount: 4,
            path: "1/334/338/2253",
            url: "sun-tents/",
          },
        ],
        url: "umbrella/",
      },
      {
        categoryID: 337,
        name: "BBQs",
        parent: 334,
        productsCount: 374,
        path: "1/334/337",
        subCategories: [
          {
            categoryID: 2223,
            name: "Outdoor Fire Pit",
            parent: 337,
            productsCount: 171,
            path: "1/334/337/2223",
            url: "outdoor-fire-pit/",
          },
          {
            categoryID: 2225,
            name: "Disposable Gloves",
            parent: 337,
            productsCount: 96,
            path: "1/334/337/2225",
            url: "disposable-gloves/",
          },
        ],
        url: "bbqs/",
      },
      {
        categoryID: 2231,
        name: "Picnicware",
        parent: 334,
        productsCount: 133,
        path: "1/334/2231",
        subCategories: [
          {
            categoryID: 2233,
            name: "Cooler Bags",
            parent: 2231,
            productsCount: 45,
            path: "1/334/2231/2233",
            url: "cooler-bags/",
          },
          {
            categoryID: 2235,
            name: "Vacuum Flasks",
            parent: 2231,
            productsCount: 59,
            path: "1/334/2231/2235",
            url: "vacuum-flasks/",
          },
          {
            categoryID: 2787,
            name: "Other Picnicware",
            parent: 2231,
            productsCount: 8,
            path: "1/334/2231/2787",
            url: "other-picnicware/",
          },
          {
            categoryID: 2703,
            name: "Picnic Sets",
            parent: 2231,
            productsCount: 13,
            path: "1/334/2231/2703",
            url: "picnic-sets/",
          },
          {
            categoryID: 2547,
            name: "Picnic Rugs",
            parent: 2231,
            productsCount: 9,
            path: "1/334/2231/2547",
            url: "picnic-rugs/",
          },
        ],
        url: "picnicware/",
      },
      {
        categoryID: 2593,
        name: "Outdoor Blinds",
        parent: 334,
        productsCount: 8,
        path: "1/334/2593",
        url: "outdoor-blinds/",
      },
      {
        categoryID: 339,
        name: "Camping",
        parent: 334,
        productsCount: 72,
        path: "1/334/339",
        url: "camping/",
      },
    ],
    url: "outdoor-leisure/",
  },
  {
    categoryID: 2957,
    name: "Tools & Auto",
    parent: 1,
    productsCount: 8616,
    path: "1/2957",
    subCategories: [
      {
        categoryID: 11,
        name: "Tools",
        parent: 2957,
        productsCount: 4896,
        path: "1/2957/11",
        subCategories: [
          {
            categoryID: 3891,
            name: "Engine",
            parent: 11,
            productsCount: 27,
            path: "1/2957/11/3891",
            url: "engine/",
          },
          {
            categoryID: 3003,
            name: "Storage Shelves",
            parent: 11,
            productsCount: 327,
            path: "1/2957/11/3003",
            url: "storage-shelves/",
          },
          {
            categoryID: 153,
            name: "Power Tools",
            parent: 11,
            productsCount: 820,
            path: "1/2957/11/153",
            subCategories: [
              {
                categoryID: 3285,
                name: "Air Compressors",
                parent: 153,
                productsCount: 81,
                path: "1/2957/11/153/3285",
                url: "air-compressors/",
              },
              {
                categoryID: 3893,
                name: "Auger",
                parent: 153,
                productsCount: 118,
                path: "1/2957/11/153/3893",
                url: "auger/",
              },
              {
                categoryID: 3889,
                name: "Drill Bit",
                parent: 153,
                productsCount: 23,
                path: "1/2957/11/153/3889",
                url: "drill-bit/",
              },
              {
                categoryID: 3287,
                name: "Cordless Drill",
                parent: 153,
                productsCount: 14,
                path: "1/2957/11/153/3287",
                url: "cordless-drill/",
              },
              {
                categoryID: 3289,
                name: "Hammer Drill",
                parent: 153,
                productsCount: 7,
                path: "1/2957/11/153/3289",
                url: "hammer-drill/",
              },
              {
                categoryID: 3957,
                name: "Jackhammer",
                parent: 153,
                productsCount: 26,
                path: "1/2957/11/153/3957",
                url: "jackhammer/",
              },
              {
                categoryID: 3959,
                name: "Log Splitter",
                parent: 153,
                productsCount: 20,
                path: "1/2957/11/153/3959",
                url: "log-splitter/",
              },
              {
                categoryID: 3955,
                name: "Welding Machine",
                parent: 153,
                productsCount: 69,
                path: "1/2957/11/153/3955",
                url: "welding-machine/",
              },
              {
                categoryID: 3887,
                name: "Sanders",
                parent: 153,
                productsCount: 26,
                path: "1/2957/11/153/3887",
                url: "sanders/",
              },
            ],
            url: "power-tools/",
          },
          {
            categoryID: 2493,
            name: "Chainsaws",
            parent: 11,
            productsCount: 430,
            path: "1/2957/11/2493",
            url: "chainsaws/",
          },
          {
            categoryID: 3779,
            name: "Voltage Converter",
            parent: 11,
            productsCount: 35,
            path: "1/2957/11/3779",
            url: "voltage-converter/",
          },
          {
            categoryID: 13,
            name: "Generators",
            parent: 11,
            productsCount: 87,
            path: "1/2957/11/13",
            subCategories: [
              {
                categoryID: 3293,
                name: "Diesel Generator",
                parent: 13,
                productsCount: 2,
                path: "1/2957/11/13/3293",
                url: "diesel-generator/",
              },
              {
                categoryID: 3291,
                name: "Inverter Generator",
                parent: 13,
                productsCount: 30,
                path: "1/2957/11/13/3291",
                url: "inverter-generator/",
              },
              {
                categoryID: 3295,
                name: "Petrol Generators",
                parent: 13,
                productsCount: 8,
                path: "1/2957/11/13/3295",
                url: "petrol-generators/",
              },
              {
                categoryID: 4097,
                name: "Power Station",
                parent: 13,
                productsCount: 24,
                path: "1/2957/11/13/4097",
                url: "power-station/",
              },
            ],
            url: "generators/",
          },
          {
            categoryID: 625,
            name: "Construction Tools",
            parent: 11,
            productsCount: 120,
            path: "1/2957/11/625",
            subCategories: [
              {
                categoryID: 3283,
                name: "Cement Mixer",
                parent: 625,
                productsCount: 23,
                path: "1/2957/11/625/3283",
                url: "cement-mixer/",
              },
              {
                categoryID: 3883,
                name: "Panel Lifter",
                parent: 625,
                productsCount: 27,
                path: "1/2957/11/625/3883",
                url: "panel-lifter/",
              },
              {
                categoryID: 3885,
                name: "Scaffolding",
                parent: 625,
                productsCount: 10,
                path: "1/2957/11/625/3885",
                url: "scaffolding/",
              },
            ],
            url: "construction-tools/",
          },
          {
            categoryID: 123,
            name: "Tool Boxes",
            parent: 11,
            productsCount: 367,
            path: "1/2957/11/123",
            url: "tool-boxes/",
          },
          {
            categoryID: 1559,
            name: "Ladders",
            parent: 11,
            productsCount: 37,
            path: "1/2957/11/1559",
            url: "ladders/",
          },
          {
            categoryID: 1557,
            name: "Measuring Tools",
            parent: 11,
            productsCount: 356,
            path: "1/2957/11/1557",
            subCategories: [
              {
                categoryID: 3299,
                name: "Laser Measure",
                parent: 1557,
                productsCount: 31,
                path: "1/2957/11/1557/3299",
                url: "laser-measure/",
              },
              {
                categoryID: 3297,
                name: "Laser Level",
                parent: 1557,
                productsCount: 7,
                path: "1/2957/11/1557/3297",
                url: "laser-level/",
              },
            ],
            url: "measuring-tools/",
          },
          {
            categoryID: 1561,
            name: "Other Tools",
            parent: 11,
            productsCount: 1044,
            path: "1/2957/11/1561",
            url: "other-tools/",
          },
          {
            categoryID: 10,
            name: "Winches & Hoists",
            parent: 11,
            productsCount: 104,
            path: "1/2957/11/10",
            subCategories: [
              {
                categoryID: 3305,
                name: "Anchor Winch",
                parent: 10,
                productsCount: 1,
                path: "1/2957/11/10/3305",
                url: "anchor-winch/",
              },
              {
                categoryID: 3303,
                name: "Boat Winch",
                parent: 10,
                productsCount: 8,
                path: "1/2957/11/10/3303",
                url: "boat-winch/",
              },
              {
                categoryID: 3307,
                name: "Electric Winch",
                parent: 10,
                productsCount: 47,
                path: "1/2957/11/10/3307",
                url: "electric-winch/",
              },
              {
                categoryID: 3301,
                name: "Hand Winch",
                parent: 10,
                productsCount: 11,
                path: "1/2957/11/10/3301",
                url: "hand-winch/",
              },
            ],
            url: "winches-hoists/",
          },
          {
            categoryID: 122,
            name: "Air Tools",
            parent: 11,
            productsCount: 226,
            path: "1/2957/11/122",
            url: "air-tools/",
          },
          {
            categoryID: 1567,
            name: "Safety Gear",
            parent: 11,
            productsCount: 61,
            path: "1/2957/11/1567",
            url: "safety-gear/",
          },
          {
            categoryID: 1565,
            name: "Trolleys",
            parent: 11,
            productsCount: 29,
            path: "1/2957/11/1565",
            url: "trolleys/",
          },
          {
            categoryID: 1555,
            name: "Hand Tools",
            parent: 11,
            productsCount: 625,
            path: "1/2957/11/1555",
            url: "hand-tools/",
          },
          {
            categoryID: 1569,
            name: "Work Lights",
            parent: 11,
            productsCount: 100,
            path: "1/2957/11/1569",
            url: "work-lights/",
          },
        ],
        url: "tools/",
      },
      {
        categoryID: 24,
        name: "Automotive",
        parent: 2957,
        productsCount: 3663,
        path: "1/2957/24",
        subCategories: [
          {
            categoryID: 30,
            name: "Car Audio, Video & Speakers",
            parent: 24,
            productsCount: 508,
            path: "1/2957/24/30",
            url: "car-audio-video-speakers/",
          },
          {
            categoryID: 3005,
            name: "Power Inverter",
            parent: 24,
            productsCount: 30,
            path: "1/2957/24/3005",
            url: "power-inverter/",
          },
          {
            categoryID: 1549,
            name: "Car Battery Chargers",
            parent: 24,
            productsCount: 174,
            path: "1/2957/24/1549",
            url: "car-battery-chargers/",
          },
          {
            categoryID: 455,
            name: "Car Exhausts",
            parent: 24,
            productsCount: 6,
            path: "1/2957/24/455",
            url: "car-exhausts/",
          },
          {
            categoryID: 453,
            name: "Car Awnings",
            parent: 24,
            productsCount: 237,
            path: "1/2957/24/453",
            url: "car-awnings/",
          },
          {
            categoryID: 457,
            name: "Car Accessories",
            parent: 24,
            productsCount: 1325,
            path: "1/2957/24/457",
            subCategories: [
              {
                categoryID: 3309,
                name: "Bluetooth Car Kit",
                parent: 457,
                productsCount: 21,
                path: "1/2957/24/457/3309",
                url: "bluetooth-car-kit/",
              },
            ],
            url: "car-accessories/",
          },
          {
            categoryID: 1551,
            name: "Car Parts",
            parent: 24,
            productsCount: 351,
            path: "1/2957/24/1551",
            url: "car-parts/",
          },
          {
            categoryID: 323,
            name: "Car Alarms & Security",
            parent: 24,
            productsCount: 74,
            path: "1/2957/24/323",
            url: "car-alarms-security/",
          },
          {
            categoryID: 1553,
            name: "Car Care & Cleaning",
            parent: 24,
            productsCount: 170,
            path: "1/2957/24/1553",
            url: "car-care-cleaning/",
          },
          {
            categoryID: 2591,
            name: "Car Tools",
            parent: 24,
            productsCount: 431,
            path: "1/2957/24/2591",
            url: "car-tools/",
          },
          {
            categoryID: 322,
            name: "Auto Meter",
            parent: 24,
            productsCount: 169,
            path: "1/2957/24/322",
            url: "auto-meter/",
          },
          {
            categoryID: 324,
            name: "GPS & Navigation Systems",
            parent: 24,
            productsCount: 137,
            path: "1/2957/24/324",
            url: "gps-navigation-systems/",
          },
        ],
        url: "automotive/",
      },
    ],
    url: "tools-auto/",
  },
  {
    categoryID: 258,
    name: "Bedding & Bath",
    parent: 1,
    productsCount: 8488,
    path: "1/258",
    subCategories: [
      {
        categoryID: 425,
        name: "Mattress & Beds",
        parent: 258,
        productsCount: 1605,
        path: "1/258/425",
        subCategories: [
          {
            categoryID: 1493,
            name: "Inflatable, Air Beds",
            parent: 425,
            productsCount: 161,
            path: "1/258/425/1493",
            url: "inflatable-air-beds/",
          },
          {
            categoryID: 1773,
            name: "Foam Mattresses",
            parent: 425,
            productsCount: 246,
            path: "1/258/425/1773",
            url: "foam-mattresses/",
          },
          {
            categoryID: 3945,
            name: "Hybrid Mattresses",
            parent: 425,
            productsCount: 42,
            path: "1/258/425/3945",
            url: "hybrid-mattresses/",
          },
          {
            categoryID: 1461,
            name: "Toppers, Underlays",
            parent: 425,
            productsCount: 486,
            path: "1/258/425/1461",
            url: "toppers-underlays/",
          },
          {
            categoryID: 1497,
            name: "Protectors",
            parent: 425,
            productsCount: 134,
            path: "1/258/425/1497",
            url: "protectors/",
          },
          {
            categoryID: 2487,
            name: "Spring Mattresses",
            parent: 425,
            productsCount: 264,
            path: "1/258/425/2487",
            url: "spring-mattresses/",
          },
        ],
        url: "mattress-beds/",
      },
      {
        categoryID: 257,
        name: "Bathroom",
        parent: 258,
        productsCount: 1767,
        path: "1/258/257",
        subCategories: [
          {
            categoryID: 2011,
            name: "Bathroom Accessories",
            parent: 257,
            productsCount: 648,
            path: "1/258/257/2011",
            url: "bathroom-accessories/",
          },
          {
            categoryID: 2475,
            name: "Bath Mats & Rugs",
            parent: 257,
            productsCount: 74,
            path: "1/258/257/2475",
            url: "bath-mats-rugs/",
          },
          {
            categoryID: 4043,
            name: "Bathroom Vanity",
            parent: 257,
            productsCount: 11,
            path: "1/258/257/4043",
            url: "bathroom-vanity/",
          },
          {
            categoryID: 4055,
            name: "Taps & Shower Heads",
            parent: 257,
            productsCount: 45,
            path: "1/258/257/4055",
            url: "taps-shower-heads/",
          },
          {
            categoryID: 3899,
            name: "Hot Water Systems",
            parent: 257,
            productsCount: 16,
            path: "1/258/257/3899",
            subCategories: [
              {
                categoryID: 3903,
                name: "Gas Hot Water Systems",
                parent: 3899,
                productsCount: 16,
                path: "1/258/257/3899/3903",
                url: "gas-hot-water-systems/",
              },
            ],
            url: "hot-water-systems/",
          },
          {
            categoryID: 2013,
            name: "Mirrors",
            parent: 257,
            productsCount: 184,
            path: "1/258/257/2013",
            url: "mirrors/",
          },
          {
            categoryID: 2617,
            name: "Bathroom Heaters",
            parent: 257,
            productsCount: 5,
            path: "1/258/257/2617",
            url: "bathroom-heaters/",
          },
          {
            categoryID: 2015,
            name: "Scales",
            parent: 257,
            productsCount: 51,
            path: "1/258/257/2015",
            url: "scales/",
          },
          {
            categoryID: 2017,
            name: "Shower Accessories",
            parent: 257,
            productsCount: 223,
            path: "1/258/257/2017",
            url: "shower-accessories/",
          },
          {
            categoryID: 2019,
            name: "Toilet Accessories",
            parent: 257,
            productsCount: 202,
            path: "1/258/257/2019",
            url: "toilet-accessories/",
          },
          {
            categoryID: 2683,
            name: "Toothbrushes",
            parent: 257,
            productsCount: 125,
            path: "1/258/257/2683",
            url: "toothbrushes/",
          },
          {
            categoryID: 2021,
            name: "Toothbrush Accessories",
            parent: 257,
            productsCount: 66,
            path: "1/258/257/2021",
            url: "toothbrush-accessories/",
          },
          {
            categoryID: 2025,
            name: "Towel Rails, Rings",
            parent: 257,
            productsCount: 64,
            path: "1/258/257/2025",
            url: "towel-rails-rings/",
          },
        ],
        url: "bathroom/",
      },
      {
        categoryID: 260,
        name: "Pillows, Cases & Cushions",
        parent: 258,
        productsCount: 829,
        path: "1/258/260",
        subCategories: [
          {
            categoryID: 2573,
            name: "Pillows",
            parent: 260,
            productsCount: 533,
            path: "1/258/260/2573",
            url: "pillows/",
          },
          {
            categoryID: 2575,
            name: "Cushions",
            parent: 260,
            productsCount: 222,
            path: "1/258/260/2575",
            url: "cushions/",
          },
        ],
        url: "pillows-cases-cushions/",
      },
      {
        categoryID: 507,
        name: "Quilt Cover Sets",
        parent: 258,
        productsCount: 1291,
        path: "1/258/507",
        subCategories: [
          {
            categoryID: 3047,
            name: "Kids Quilt Covers",
            parent: 507,
            productsCount: 122,
            path: "1/258/507/3047",
            url: "kids-quilt-covers/",
          },
          {
            categoryID: 1501,
            name: "King Size Quilt Covers",
            parent: 507,
            productsCount: 292,
            path: "1/258/507/1501",
            url: "king-size-quilt-covers/",
          },
          {
            categoryID: 1503,
            name: "Queen Quilt Cover",
            parent: 507,
            productsCount: 353,
            path: "1/258/507/1503",
            url: "queen-quilt-cover/",
          },
          {
            categoryID: 1499,
            name: "Double Quilt Cover",
            parent: 507,
            productsCount: 210,
            path: "1/258/507/1499",
            url: "double-quilt-cover/",
          },
          {
            categoryID: 1505,
            name: "Single Quilt Cover",
            parent: 507,
            productsCount: 277,
            path: "1/258/507/1505",
            url: "single-quilt-cover/",
          },
        ],
        url: "quilt-cover-sets/",
      },
      {
        categoryID: 259,
        name: "Quilts & Comforters",
        parent: 258,
        productsCount: 694,
        path: "1/258/259",
        subCategories: [
          {
            categoryID: 3045,
            name: "Kids Quilts",
            parent: 259,
            productsCount: 124,
            path: "1/258/259/3045",
            url: "kids-quilts/",
          },
          {
            categoryID: 2585,
            name: "King Size Quilts",
            parent: 259,
            productsCount: 229,
            path: "1/258/259/2585",
            url: "king-size-quilts/",
          },
          {
            categoryID: 2583,
            name: "Queen Size Quilts",
            parent: 259,
            productsCount: 243,
            path: "1/258/259/2583",
            url: "queen-size-quilts/",
          },
          {
            categoryID: 2581,
            name: "Double Quilts",
            parent: 259,
            productsCount: 257,
            path: "1/258/259/2581",
            url: "double-quilts/",
          },
          {
            categoryID: 2579,
            name: "Single Quilts",
            parent: 259,
            productsCount: 254,
            path: "1/258/259/2579",
            url: "single-quilts/",
          },
        ],
        url: "quilts-comforters/",
      },
      {
        categoryID: 263,
        name: "Sheets, Bed Linen & Bedspreads",
        parent: 258,
        productsCount: 1240,
        path: "1/258/263",
        subCategories: [
          {
            categoryID: 1507,
            name: "King Sheets",
            parent: 263,
            productsCount: 177,
            path: "1/258/263/1507",
            url: "king-sheets/",
          },
          {
            categoryID: 1509,
            name: "Queen Sheets",
            parent: 263,
            productsCount: 356,
            path: "1/258/263/1509",
            url: "queen-sheets/",
          },
          {
            categoryID: 1511,
            name: "Double Sheets",
            parent: 263,
            productsCount: 142,
            path: "1/258/263/1511",
            url: "double-sheets/",
          },
          {
            categoryID: 2549,
            name: "King Single",
            parent: 263,
            productsCount: 20,
            path: "1/258/263/2549",
            url: "king-single/",
          },
          {
            categoryID: 1515,
            name: "Single Sheets",
            parent: 263,
            productsCount: 183,
            path: "1/258/263/1515",
            url: "single-sheets/",
          },
        ],
        url: "sheets-bed-linen-bedspreads/",
      },
      {
        categoryID: 2023,
        name: "Bathrobes & Bath Towels",
        parent: 258,
        productsCount: 267,
        path: "1/258/2023",
        subCategories: [
          {
            categoryID: 2469,
            name: "Towels",
            parent: 2023,
            productsCount: 185,
            path: "1/258/2023/2469",
            url: "towels/",
          },
          {
            categoryID: 2471,
            name: "Bath Robes",
            parent: 2023,
            productsCount: 63,
            path: "1/258/2023/2471",
            url: "bath-robes/",
          },
        ],
        url: "bathrobes-bath-towels/",
      },
      {
        categoryID: 1491,
        name: "Blankets",
        parent: 258,
        productsCount: 609,
        path: "1/258/1491",
        subCategories: [
          {
            categoryID: 4041,
            name: "Weighted Blanket",
            parent: 1491,
            productsCount: 31,
            path: "1/258/1491/4041",
            url: "weighted-blanket/",
          },
          {
            categoryID: 2557,
            name: "Electric Blankets",
            parent: 1491,
            productsCount: 108,
            path: "1/258/1491/2557",
            url: "electric-blankets/",
          },
        ],
        url: "blankets/",
      },
      {
        categoryID: 2473,
        name: "Beach Towels",
        parent: 258,
        productsCount: 145,
        path: "1/258/2473",
        url: "beach-towels/",
      },
      {
        categoryID: 2687,
        name: "Shower Curtains",
        parent: 258,
        productsCount: 121,
        path: "1/258/2687",
        url: "shower-curtains/",
      },
      {
        categoryID: 2477,
        name: "Manchester, Beds, Pillows and Bedding",
        parent: 258,
        productsCount: 220,
        path: "1/258/2477",
        subCategories: [
          {
            categoryID: 2481,
            name: "Children's Blankets",
            parent: 2477,
            productsCount: 13,
            path: "1/258/2477/2481",
            url: "children-s-blankets/",
          },
          {
            categoryID: 2479,
            name: "Children's Quilt Covers",
            parent: 2477,
            productsCount: 129,
            path: "1/258/2477/2479",
            url: "children-s-quilt-covers/",
          },
          {
            categoryID: 2619,
            name: "Children's Sheets",
            parent: 2477,
            productsCount: 10,
            path: "1/258/2477/2619",
            url: "children-s-sheets/",
          },
        ],
        url: "manchester-beds-pillows-and-bedding/",
      },
      {
        categoryID: 2685,
        name: "Comforter Sets, Bedspreads & Quilt Covers",
        parent: 258,
        productsCount: 126,
        path: "1/258/2685",
        url: "comforter-sets-bedspreads-quilt-covers/",
      },
    ],
    url: "bedding-bath/",
  },
  {
    categoryID: 62,
    name: "Health & Beauty",
    parent: 1,
    productsCount: 10289,
    path: "1/62",
    subCategories: [
      {
        categoryID: 3967,
        name: "Oral & Dental Care",
        parent: 62,
        productsCount: 69,
        path: "1/62/3967",
        url: "oral-dental-care/",
      },
      {
        categoryID: 145,
        name: "Massage Therapy",
        parent: 62,
        productsCount: 1088,
        path: "1/62/145",
        subCategories: [
          {
            categoryID: 3265,
            name: "Massage Chair",
            parent: 145,
            productsCount: 339,
            path: "1/62/145/3265",
            url: "massage-chair/",
          },
          {
            categoryID: 3879,
            name: "Massage Cushion",
            parent: 145,
            productsCount: 52,
            path: "1/62/145/3879",
            url: "massage-cushion/",
          },
          {
            categoryID: 3269,
            name: "Foot Massager",
            parent: 145,
            productsCount: 136,
            path: "1/62/145/3269",
            url: "foot-massager/",
          },
          {
            categoryID: 3267,
            name: "Massage Table",
            parent: 145,
            productsCount: 155,
            path: "1/62/145/3267",
            url: "massage-table/",
          },
          {
            categoryID: 3271,
            name: "Massager",
            parent: 145,
            productsCount: 259,
            path: "1/62/145/3271",
            url: "massager/",
          },
        ],
        url: "massage-therapy/",
      },
      {
        categoryID: 2447,
        name: "Medical Aids",
        parent: 62,
        productsCount: 457,
        path: "1/62/2447",
        subCategories: [
          {
            categoryID: 3277,
            name: "Heart Rate & Blood Pressure Monitor",
            parent: 2447,
            productsCount: 53,
            path: "1/62/2447/3277",
            url: "heart-rate-blood-pressure-monitor/",
          },
          {
            categoryID: 4017,
            name: "Masks",
            parent: 2447,
            productsCount: 25,
            path: "1/62/2447/4017",
            url: "masks/",
          },
          {
            categoryID: 4015,
            name: "Mobility Scooters",
            parent: 2447,
            productsCount: 13,
            path: "1/62/2447/4015",
            url: "mobility-scooters/",
          },
          {
            categoryID: 3881,
            name: "Wheelchair Ramps",
            parent: 2447,
            productsCount: 16,
            path: "1/62/2447/3881",
            url: "wheelchair-ramps/",
          },
          {
            categoryID: 3273,
            name: "Thermometer",
            parent: 2447,
            productsCount: 55,
            path: "1/62/2447/3273",
            url: "thermometer/",
          },
          {
            categoryID: 3275,
            name: "Wheelchair",
            parent: 2447,
            productsCount: 50,
            path: "1/62/2447/3275",
            url: "wheelchair/",
          },
        ],
        url: "medical-aids/",
      },
      {
        categoryID: 173,
        name: "Makeup & Skin Care",
        parent: 62,
        productsCount: 1102,
        path: "1/62/173",
        subCategories: [
          {
            categoryID: 3263,
            name: "Nail Art",
            parent: 173,
            productsCount: 146,
            path: "1/62/173/3263",
            url: "nail-art/",
          },
          {
            categoryID: 3261,
            name: "Skin Care",
            parent: 173,
            productsCount: 184,
            path: "1/62/173/3261",
            url: "skin-care/",
          },
          {
            categoryID: 3259,
            name: "Makeup Case",
            parent: 173,
            productsCount: 127,
            path: "1/62/173/3259",
            url: "makeup-case/",
          },
          {
            categoryID: 3257,
            name: "Eye Makeup",
            parent: 173,
            productsCount: 73,
            path: "1/62/173/3257",
            url: "eye-makeup/",
          },
          {
            categoryID: 3969,
            name: "LED Make-Up Mirrors",
            parent: 173,
            productsCount: 138,
            path: "1/62/173/3969",
            url: "led-make-up-mirrors/",
          },
        ],
        url: "makeup-skin-care/",
      },
      {
        categoryID: 2009,
        name: "Umbrellas",
        parent: 62,
        productsCount: 41,
        path: "1/62/2009",
        url: "umbrellas/",
      },
      {
        categoryID: 332,
        name: "Watches",
        parent: 62,
        productsCount: 1586,
        path: "1/62/332",
        subCategories: [
          {
            categoryID: 2161,
            name: "Men's Watches",
            parent: 332,
            productsCount: 633,
            path: "1/62/332/2161",
            url: "men-s-watches/",
          },
          {
            categoryID: 2163,
            name: "Ladies Watches",
            parent: 332,
            productsCount: 352,
            path: "1/62/332/2163",
            url: "ladies-watches/",
          },
          {
            categoryID: 2165,
            name: "Unisex Watches",
            parent: 332,
            productsCount: 372,
            path: "1/62/332/2165",
            url: "unisex-watches/",
          },
        ],
        url: "watches/",
      },
      {
        categoryID: 2961,
        name: "Bags",
        parent: 62,
        productsCount: 1631,
        path: "1/62/2961",
        subCategories: [
          {
            categoryID: 1921,
            name: "Backpacks, Bags",
            parent: 2961,
            productsCount: 348,
            path: "1/62/2961/1921",
            url: "backpacks-bags/",
          },
          {
            categoryID: 2963,
            name: "Men's Bag",
            parent: 2961,
            productsCount: 23,
            path: "1/62/2961/2963",
            url: "men-s-bag/",
          },
          {
            categoryID: 1913,
            name: "Luggage & Travel Bags",
            parent: 2961,
            productsCount: 654,
            path: "1/62/2961/1913",
            url: "luggage-travel-bags/",
          },
          {
            categoryID: 1929,
            name: "Women's Bags & Handbags",
            parent: 2961,
            productsCount: 367,
            path: "1/62/2961/1929",
            url: "women-s-bags-handbags/",
          },
        ],
        url: "bags/",
      },
      {
        categoryID: 443,
        name: "Hair Care Products",
        parent: 62,
        productsCount: 276,
        path: "1/62/443",
        url: "hair-care-products/",
      },
      {
        categoryID: 2159,
        name: "Jewellery Boxes",
        parent: 62,
        productsCount: 155,
        path: "1/62/2159",
        url: "jewellery-boxes/",
      },
      {
        categoryID: 2967,
        name: "Jewellery/Accessories",
        parent: 62,
        productsCount: 1305,
        path: "1/62/2967",
        url: "jewellery-accessories/",
      },
      {
        categoryID: 445,
        name: "Hair Removal & Shaving",
        parent: 62,
        productsCount: 376,
        path: "1/62/445",
        url: "hair-removal-shaving/",
      },
      {
        categoryID: 81,
        name: "Fragrances, Perfume & Cologne",
        parent: 62,
        productsCount: 1189,
        path: "1/62/81",
        subCategories: [
          {
            categoryID: 83,
            name: "Men's Perfume",
            parent: 81,
            productsCount: 434,
            path: "1/62/81/83",
            url: "men-s-perfume/",
          },
          {
            categoryID: 82,
            name: "Women's Perfume",
            parent: 81,
            productsCount: 749,
            path: "1/62/81/82",
            url: "women-s-perfume/",
          },
        ],
        url: "fragrances-perfume-cologne/",
      },
      {
        categoryID: 2569,
        name: "Skincare",
        parent: 62,
        productsCount: 99,
        path: "1/62/2569",
        url: "skincare/",
      },
      {
        categoryID: 1911,
        name: "Sunglasses",
        parent: 62,
        productsCount: 91,
        path: "1/62/1911",
        subCategories: [
          {
            categoryID: 1371,
            name: "Womens Sunglasses",
            parent: 1911,
            productsCount: 37,
            path: "1/62/1911/1371",
            url: "womens-sunglasses/",
          },
        ],
        url: "sunglasses/",
      },
      {
        categoryID: 1845,
        name: "Cosmetic Bags",
        parent: 62,
        productsCount: 32,
        path: "1/62/1845",
        url: "cosmetic-bags/",
      },
      {
        categoryID: 2567,
        name: "Makeup",
        parent: 62,
        productsCount: 51,
        path: "1/62/2567",
        url: "makeup/",
      },
      {
        categoryID: 2969,
        name: "Unisex Clothing",
        parent: 62,
        productsCount: 313,
        path: "1/62/2969",
        url: "unisex-clothing/",
      },
      {
        categoryID: 275,
        name: "Tattoos",
        parent: 62,
        productsCount: 25,
        path: "1/62/275",
        url: "tattoos/",
      },
    ],
    url: "health-beauty/",
  },
  {
    categoryID: 50,
    name: "Electronics",
    parent: 1,
    productsCount: 18745,
    path: "1/50",
    subCategories: [
      {
        categoryID: 3857,
        name: " Home & Office Phones",
        parent: 50,
        productsCount: 3,
        path: "1/50/3857",
        url: "home-office-phones/",
      },
      {
        categoryID: 1777,
        name: "Gadgets",
        parent: 50,
        productsCount: 501,
        path: "1/50/1777",
        subCategories: [
          {
            categoryID: 1809,
            name: "Breathalysers",
            parent: 1777,
            productsCount: 27,
            path: "1/50/1777/1809",
            url: "breathalysers/",
          },
          {
            categoryID: 1811,
            name: "eBook Readers",
            parent: 1777,
            productsCount: 6,
            path: "1/50/1777/1811",
            url: "ebook-readers/",
          },
          {
            categoryID: 1813,
            name: "Metal Detectors",
            parent: 1777,
            productsCount: 56,
            path: "1/50/1777/1813",
            url: "metal-detectors/",
          },
          {
            categoryID: 2577,
            name: "Voice Recorders",
            parent: 1777,
            productsCount: 160,
            path: "1/50/1777/2577",
            url: "voice-recorders/",
          },
          {
            categoryID: 1817,
            name: "Thermometers",
            parent: 1777,
            productsCount: 65,
            path: "1/50/1777/1817",
            url: "thermometers/",
          },
          {
            categoryID: 1819,
            name: "Weather Stations",
            parent: 1777,
            productsCount: 70,
            path: "1/50/1777/1819",
            url: "weather-stations/",
          },
          {
            categoryID: 1821,
            name: "Money Counters",
            parent: 1777,
            productsCount: 57,
            path: "1/50/1777/1821",
            url: "money-counters/",
          },
        ],
        url: "gadgets/",
      },
      {
        categoryID: 1885,
        name: "Televisions & Accessories",
        parent: 50,
        productsCount: 2130,
        path: "1/50/1885",
        subCategories: [
          {
            categoryID: 3441,
            name: "Hdmi Adapter",
            parent: 1885,
            productsCount: 38,
            path: "1/50/1885/3441",
            url: "hdmi-adapter/",
          },
          {
            categoryID: 3437,
            name: "Hdmi Cables",
            parent: 1885,
            productsCount: 74,
            path: "1/50/1885/3437",
            url: "hdmi-cables/",
          },
          {
            categoryID: 3439,
            name: "Hdmi Splittes",
            parent: 1885,
            productsCount: 8,
            path: "1/50/1885/3439",
            url: "hdmi-splittes/",
          },
          {
            categoryID: 154,
            name: "LCD TVs LED TVs",
            parent: 1885,
            productsCount: 53,
            path: "1/50/1885/154",
            url: "lcd-tvs-led-tvs/",
          },
          {
            categoryID: 241,
            name: "TV Antennas",
            parent: 1885,
            productsCount: 64,
            path: "1/50/1885/241",
            url: "tv-antennas/",
          },
          {
            categoryID: 1835,
            name: "AV Transmitters",
            parent: 1885,
            productsCount: 73,
            path: "1/50/1885/1835",
            url: "av-transmitters/",
          },
          {
            categoryID: 377,
            name: "Digital Set Top Boxes",
            parent: 1885,
            productsCount: 19,
            path: "1/50/1885/377",
            url: "digital-set-top-boxes/",
          },
          {
            categoryID: 1837,
            name: "Remote Controls",
            parent: 1885,
            productsCount: 78,
            path: "1/50/1885/1837",
            url: "remote-controls/",
          },
          {
            categoryID: 993,
            name: "TV Wall Mounts & Brackets",
            parent: 1885,
            productsCount: 1645,
            path: "1/50/1885/993",
            url: "tv-wall-mounts-brackets/",
          },
          {
            categoryID: 1839,
            name: "Splitters & Combiners",
            parent: 1885,
            productsCount: 11,
            path: "1/50/1885/1839",
            url: "splitters-combiners/",
          },
        ],
        url: "televisions-accessories/",
      },
      {
        categoryID: 1775,
        name: "Home Audio & Video",
        parent: 50,
        productsCount: 3452,
        path: "1/50/1775",
        subCategories: [
          {
            categoryID: 2763,
            name: "Amplifiers",
            parent: 1775,
            productsCount: 50,
            path: "1/50/1775/2763",
            url: "amplifiers/",
          },
          {
            categoryID: 1455,
            name: "Headphones & Earphones",
            parent: 1775,
            productsCount: 2534,
            path: "1/50/1775/1455",
            url: "headphones-earphones/",
          },
          {
            categoryID: 471,
            name: "Hi Fi Systems",
            parent: 1775,
            productsCount: 8,
            path: "1/50/1775/471",
            url: "hi-fi-systems/",
          },
          {
            categoryID: 1791,
            name: "Microphones",
            parent: 1775,
            productsCount: 89,
            path: "1/50/1775/1791",
            url: "microphones/",
          },
          {
            categoryID: 1793,
            name: "Radios, CD Players",
            parent: 1775,
            productsCount: 188,
            path: "1/50/1775/1793",
            url: "radios-cd-players/",
          },
          {
            categoryID: 53,
            name: "Speakers & Subwoofers",
            parent: 1775,
            productsCount: 524,
            path: "1/50/1775/53",
            url: "speakers-subwoofers/",
          },
          {
            categoryID: 1795,
            name: "Turntables & Cassette Players",
            parent: 1775,
            productsCount: 20,
            path: "1/50/1775/1795",
            url: "turntables-cassette-players/",
          },
        ],
        url: "home-audio-video/",
      },
      {
        categoryID: 2715,
        name: "Solar Panels",
        parent: 50,
        productsCount: 116,
        path: "1/50/2715",
        url: "solar-panels/",
      },
      {
        categoryID: 1781,
        name: "Camera & Photo",
        parent: 50,
        productsCount: 3846,
        path: "1/50/1781",
        subCategories: [
          {
            categoryID: 2765,
            name: "Cases, Bags & Covers",
            parent: 1781,
            productsCount: 178,
            path: "1/50/1781/2765",
            url: "cases-bags-covers/",
          },
          {
            categoryID: 2485,
            name: "Camera Batteries",
            parent: 1781,
            productsCount: 68,
            path: "1/50/1781/2485",
            url: "camera-batteries/",
          },
          {
            categoryID: 3443,
            name: "Waterproof Cameras",
            parent: 1781,
            productsCount: 134,
            path: "1/50/1781/3443",
            url: "waterproof-cameras/",
          },
          {
            categoryID: 2803,
            name: "Film Cameras",
            parent: 1781,
            productsCount: 16,
            path: "1/50/1781/2803",
            url: "film-cameras/",
          },
          {
            categoryID: 3445,
            name: "Spy Cameras",
            parent: 1781,
            productsCount: 27,
            path: "1/50/1781/3445",
            url: "spy-cameras/",
          },
          {
            categoryID: 1803,
            name: "Camera Lenses",
            parent: 1781,
            productsCount: 708,
            path: "1/50/1781/1803",
            url: "camera-lenses/",
          },
          {
            categoryID: 3965,
            name: "Drones",
            parent: 1781,
            productsCount: 173,
            path: "1/50/1781/3965",
            url: "drones/",
          },
          {
            categoryID: 152,
            name: "Digital Cameras",
            parent: 1781,
            productsCount: 1194,
            path: "1/50/1781/152",
            url: "digital-cameras/",
          },
          {
            categoryID: 2771,
            name: "Camera Flashes",
            parent: 1781,
            productsCount: 50,
            path: "1/50/1781/2771",
            url: "camera-flashes/",
          },
          {
            categoryID: 1805,
            name: "Video Cameras",
            parent: 1781,
            productsCount: 410,
            path: "1/50/1781/1805",
            url: "video-cameras/",
          },
          {
            categoryID: 2601,
            name: "Digital SLR Cameras",
            parent: 1781,
            productsCount: 238,
            path: "1/50/1781/2601",
            url: "digital-slr-cameras/",
          },
          {
            categoryID: 2767,
            name: "Lens Filter",
            parent: 1781,
            productsCount: 123,
            path: "1/50/1781/2767",
            url: "lens-filter/",
          },
          {
            categoryID: 68,
            name: "Photographic Accessories",
            parent: 1781,
            productsCount: 399,
            path: "1/50/1781/68",
            url: "photographic-accessories/",
          },
          {
            categoryID: 2769,
            name: "Lens Hoods",
            parent: 1781,
            productsCount: 12,
            path: "1/50/1781/2769",
            url: "lens-hoods/",
          },
        ],
        url: "camera-photo/",
      },
      {
        categoryID: 151,
        name: "Home Theatre System",
        parent: 50,
        productsCount: 589,
        path: "1/50/151",
        subCategories: [
          {
            categoryID: 2599,
            name: "DVD Players",
            parent: 151,
            productsCount: 90,
            path: "1/50/151/2599",
            url: "dvd-players/",
          },
          {
            categoryID: 997,
            name: "Projectors",
            parent: 151,
            productsCount: 397,
            path: "1/50/151/997",
            url: "projectors/",
          },
          {
            categoryID: 1783,
            name: "Media Players",
            parent: 151,
            productsCount: 89,
            path: "1/50/151/1783",
            url: "media-players/",
          },
          {
            categoryID: 929,
            name: "3D Glasses",
            parent: 151,
            productsCount: 11,
            path: "1/50/151/929",
            url: "3d-glasses/",
          },
        ],
        url: "home-theatre-system/",
      },
      {
        categoryID: 429,
        name: "Mobile Phones & Accessories",
        parent: 50,
        productsCount: 3842,
        path: "1/50/429",
        subCategories: [
          {
            categoryID: 953,
            name: "Mobile Phones",
            parent: 429,
            productsCount: 1163,
            path: "1/50/429/953",
            url: "mobile-phones/",
          },
          {
            categoryID: 1827,
            name: "Mobile Phone Batteries, Chargers",
            parent: 429,
            productsCount: 326,
            path: "1/50/429/1827",
            url: "mobile-phone-batteries-chargers/",
          },
          {
            categoryID: 957,
            name: "Phone Cases",
            parent: 429,
            productsCount: 1010,
            path: "1/50/429/957",
            subCategories: [
              {
                categoryID: 3475,
                name: "Iphone 5C Cases",
                parent: 957,
                productsCount: 1,
                path: "1/50/429/957/3475",
                url: "iphone-5c-cases/",
              },
              {
                categoryID: 3471,
                name: "Iphone 5 Cases",
                parent: 957,
                productsCount: 91,
                path: "1/50/429/957/3471",
                url: "iphone-5-cases/",
              },
              {
                categoryID: 3473,
                name: "Iphone 5S Cases",
                parent: 957,
                productsCount: 36,
                path: "1/50/429/957/3473",
                url: "iphone-5s-cases/",
              },
              {
                categoryID: 3465,
                name: "Iphone 6 Cases",
                parent: 957,
                productsCount: 255,
                path: "1/50/429/957/3465",
                url: "iphone-6-cases/",
              },
              {
                categoryID: 3469,
                name: "Iphone 6 Plus Cases",
                parent: 957,
                productsCount: 78,
                path: "1/50/429/957/3469",
                url: "iphone-6-plus-cases/",
              },
              {
                categoryID: 3463,
                name: "Iphone Cases",
                parent: 957,
                productsCount: 205,
                path: "1/50/429/957/3463",
                url: "iphone-cases/",
              },
            ],
            url: "phone-cases/",
          },
          {
            categoryID: 911,
            name: "Bluetooth Headphones",
            parent: 429,
            productsCount: 420,
            path: "1/50/429/911",
            url: "bluetooth-headphones/",
          },
          {
            categoryID: 2735,
            name: "Phone Accessories",
            parent: 429,
            productsCount: 759,
            path: "1/50/429/2735",
            url: "phone-accessories/",
          },
          {
            categoryID: 1829,
            name: "Screen Protectors",
            parent: 429,
            productsCount: 10,
            path: "1/50/429/1829",
            url: "screen-protectors/",
          },
          {
            categoryID: 955,
            name: "Telephones",
            parent: 429,
            productsCount: 69,
            path: "1/50/429/955",
            url: "telephones/",
          },
        ],
        url: "mobile-phones-accessories/",
      },
      {
        categoryID: 627,
        name: "Video Games",
        parent: 50,
        productsCount: 1060,
        path: "1/50/627",
        subCategories: [
          {
            categoryID: 1833,
            name: "Consoles",
            parent: 627,
            productsCount: 57,
            path: "1/50/627/1833",
            url: "consoles/",
          },
          {
            categoryID: 3447,
            name: "Video Game Consoles",
            parent: 627,
            productsCount: 295,
            path: "1/50/627/3447",
            url: "video-game-consoles/",
          },
          {
            categoryID: 913,
            name: "Video Game Accessories",
            parent: 627,
            productsCount: 689,
            path: "1/50/627/913",
            url: "video-game-accessories/",
          },
        ],
        url: "video-games/",
      },
      {
        categoryID: 127,
        name: "Mp3 & Mp4 Players",
        parent: 50,
        productsCount: 545,
        path: "1/50/127",
        subCategories: [
          {
            categoryID: 965,
            name: "MP3 Players",
            parent: 127,
            productsCount: 111,
            path: "1/50/127/965",
            url: "mp3-players/",
          },
          {
            categoryID: 967,
            name: "MP4 Players",
            parent: 127,
            productsCount: 72,
            path: "1/50/127/967",
            url: "mp4-players/",
          },
          {
            categoryID: 1873,
            name: "Accessory Bundles",
            parent: 127,
            productsCount: 4,
            path: "1/50/127/1873",
            url: "accessory-bundles/",
          },
          {
            categoryID: 1875,
            name: "Cases",
            parent: 127,
            productsCount: 23,
            path: "1/50/127/1875",
            url: "cases/",
          },
          {
            categoryID: 1877,
            name: "Chargers",
            parent: 127,
            productsCount: 38,
            path: "1/50/127/1877",
            url: "chargers/",
          },
          {
            categoryID: 269,
            name: "Docks, Cradles",
            parent: 127,
            productsCount: 161,
            path: "1/50/127/269",
            url: "docks-cradles/",
          },
          {
            categoryID: 963,
            name: "FM Transmitters",
            parent: 127,
            productsCount: 22,
            path: "1/50/127/963",
            url: "fm-transmitters/",
          },
          {
            categoryID: 1879,
            name: "MP3 Player Cables",
            parent: 127,
            productsCount: 18,
            path: "1/50/127/1879",
            url: "mp3-player-cables/",
          },
          {
            categoryID: 961,
            name: "MP3 Player Accessories",
            parent: 127,
            productsCount: 27,
            path: "1/50/127/961",
            url: "mp3-player-accessories/",
          },
        ],
        url: "mp3-mp4-players/",
      },
      {
        categoryID: 1785,
        name: "Power Cables, Extension Cords & Connectors",
        parent: 50,
        productsCount: 114,
        path: "1/50/1785",
        url: "power-cables-extension-cords-connectors/",
      },
      {
        categoryID: 133,
        name: "Memory Cards",
        parent: 50,
        productsCount: 457,
        path: "1/50/133",
        subCategories: [
          {
            categoryID: 267,
            name: "SD Cards",
            parent: 133,
            productsCount: 223,
            path: "1/50/133/267",
            url: "sd-cards/",
          },
          {
            categoryID: 137,
            name: "Micro SD Cards",
            parent: 133,
            productsCount: 90,
            path: "1/50/133/137",
            url: "micro-sd-cards/",
          },
          {
            categoryID: 198,
            name: "Memory Stick & M2",
            parent: 133,
            productsCount: 41,
            path: "1/50/133/198",
            url: "memory-stick-m2/",
          },
          {
            categoryID: 2531,
            name: "SDXC Cards",
            parent: 133,
            productsCount: 43,
            path: "1/50/133/2531",
            url: "sdxc-cards/",
          },
          {
            categoryID: 135,
            name: "Compact Flash",
            parent: 133,
            productsCount: 23,
            path: "1/50/133/135",
            url: "compact-flash/",
          },
          {
            categoryID: 141,
            name: "XD Cards",
            parent: 133,
            productsCount: 1,
            path: "1/50/133/141",
            url: "xd-cards/",
          },
          {
            categoryID: 139,
            name: "SD Card Readers",
            parent: 133,
            productsCount: 90,
            path: "1/50/133/139",
            url: "sd-card-readers/",
          },
        ],
        url: "memory-cards/",
      },
      {
        categoryID: 3449,
        name: "Smart Electronics",
        parent: 50,
        productsCount: 1671,
        path: "1/50/3449",
        subCategories: [
          {
            categoryID: 3461,
            name: "Smart Remote Control",
            parent: 3449,
            productsCount: 145,
            path: "1/50/3449/3461",
            url: "smart-remote-control/",
          },
          {
            categoryID: 3455,
            name: "Smart Glasses",
            parent: 3449,
            productsCount: 15,
            path: "1/50/3449/3455",
            url: "smart-glasses/",
          },
          {
            categoryID: 3459,
            name: "Smart Health",
            parent: 3449,
            productsCount: 68,
            path: "1/50/3449/3459",
            url: "smart-health/",
          },
          {
            categoryID: 3457,
            name: "Smart Homes",
            parent: 3449,
            productsCount: 49,
            path: "1/50/3449/3457",
            url: "smart-homes/",
          },
          {
            categoryID: 3451,
            name: "Smart Watches",
            parent: 3449,
            productsCount: 1278,
            path: "1/50/3449/3451",
            url: "smart-watches/",
          },
          {
            categoryID: 3453,
            name: "Smart Wristbands",
            parent: 3449,
            productsCount: 28,
            path: "1/50/3449/3453",
            url: "smart-wristbands/",
          },
        ],
        url: "smart-electronics/",
      },
      {
        categoryID: 216,
        name: "Batteries & Chargers",
        parent: 50,
        productsCount: 128,
        path: "1/50/216",
        subCategories: [
          {
            categoryID: 1797,
            name: "Batteries",
            parent: 216,
            productsCount: 75,
            path: "1/50/216/1797",
            url: "batteries/",
          },
          {
            categoryID: 1801,
            name: "Combos",
            parent: 216,
            productsCount: 10,
            path: "1/50/216/1801",
            url: "combos/",
          },
        ],
        url: "batteries-chargers/",
      },
      {
        categoryID: 103,
        name: "Digital Photo Frames",
        parent: 50,
        productsCount: 48,
        path: "1/50/103",
        url: "digital-photo-frames/",
      },
      {
        categoryID: 2971,
        name: "Home & Office Safety",
        parent: 50,
        productsCount: 31,
        path: "1/50/2971",
        url: "home-office-safety/",
      },
    ],
    url: "electronics/",
  },
  {
    categoryID: 65,
    name: "Novelties & Gifts",
    parent: 1,
    productsCount: 2394,
    path: "1/65",
    subCategories: [
      {
        categoryID: 3855,
        name: "Mannequins",
        parent: 65,
        productsCount: 73,
        path: "1/65/3855",
        url: "mannequins/",
      },
      {
        categoryID: 3329,
        name: "Gifts For Him",
        parent: 65,
        productsCount: 99,
        path: "1/65/3329",
        subCategories: [
          {
            categoryID: 3335,
            name: "Gifrs For Boyfriends",
            parent: 3329,
            productsCount: 21,
            path: "1/65/3329/3335",
            url: "gifrs-for-boyfriends/",
          },
          {
            categoryID: 3331,
            name: "Gifts For Dad",
            parent: 3329,
            productsCount: 17,
            path: "1/65/3329/3331",
            url: "gifts-for-dad/",
          },
          {
            categoryID: 3333,
            name: "Gifts For Husband",
            parent: 3329,
            productsCount: 24,
            path: "1/65/3329/3333",
            url: "gifts-for-husband/",
          },
        ],
        url: "gifts-for-him/",
      },
      {
        categoryID: 3337,
        name: "Gifts For Her",
        parent: 65,
        productsCount: 155,
        path: "1/65/3337",
        subCategories: [
          {
            categoryID: 3343,
            name: "Gifts For Girlfriends",
            parent: 3337,
            productsCount: 44,
            path: "1/65/3337/3343",
            url: "gifts-for-girlfriends/",
          },
          {
            categoryID: 3341,
            name: "Gifts For Wife",
            parent: 3337,
            productsCount: 46,
            path: "1/65/3337/3341",
            url: "gifts-for-wife/",
          },
          {
            categoryID: 3339,
            name: "Gifts For Mum",
            parent: 3337,
            productsCount: 36,
            path: "1/65/3337/3339",
            url: "gifts-for-mum/",
          },
        ],
        url: "gifts-for-her/",
      },
      {
        categoryID: 3415,
        name: "Birthday Gifts",
        parent: 65,
        productsCount: 96,
        path: "1/65/3415",
        subCategories: [
          {
            categoryID: 3417,
            name: "Birthday Gifts For Her",
            parent: 3415,
            productsCount: 32,
            path: "1/65/3415/3417",
            url: "birthday-gifts-for-her/",
          },
          {
            categoryID: 3419,
            name: "Birthday Gifts For Him",
            parent: 3415,
            productsCount: 17,
            path: "1/65/3415/3419",
            url: "birthday-gifts-for-him/",
          },
          {
            categoryID: 3421,
            name: "1st Birthday Gifts",
            parent: 3415,
            productsCount: 18,
            path: "1/65/3415/3421",
            url: "1st-birthday-gifts/",
          },
          {
            categoryID: 3423,
            name: "18th Birthday Gifts",
            parent: 3415,
            productsCount: 10,
            path: "1/65/3415/3423",
            url: "18th-birthday-gifts/",
          },
          {
            categoryID: 3425,
            name: "21st Birthday Gifts",
            parent: 3415,
            productsCount: 5,
            path: "1/65/3415/3425",
            url: "21st-birthday-gifts/",
          },
          {
            categoryID: 3427,
            name: "30th Birthday Gifts",
            parent: 3415,
            productsCount: 7,
            path: "1/65/3415/3427",
            url: "30th-birthday-gifts/",
          },
          {
            categoryID: 3429,
            name: "40th Birthday Gifts",
            parent: 3415,
            productsCount: 4,
            path: "1/65/3415/3429",
            url: "40th-birthday-gifts/",
          },
          {
            categoryID: 3431,
            name: "50th Birthday Gifts",
            parent: 3415,
            productsCount: 6,
            path: "1/65/3415/3431",
            url: "50th-birthday-gifts/",
          },
          {
            categoryID: 3433,
            name: "60th Birthday Gifts",
            parent: 3415,
            productsCount: 3,
            path: "1/65/3415/3433",
            url: "60th-birthday-gifts/",
          },
        ],
        url: "birthday-gifts/",
      },
      {
        categoryID: 3357,
        name: "Wedding Gifts",
        parent: 65,
        productsCount: 49,
        path: "1/65/3357",
        subCategories: [
          {
            categoryID: 3359,
            name: "Gifts For The Bride",
            parent: 3357,
            productsCount: 18,
            path: "1/65/3357/3359",
            url: "gifts-for-the-bride/",
          },
          {
            categoryID: 3365,
            name: "Bridesmaid Gifts",
            parent: 3357,
            productsCount: 13,
            path: "1/65/3357/3365",
            url: "bridesmaid-gifts/",
          },
          {
            categoryID: 3361,
            name: "Gifts For The Couple",
            parent: 3357,
            productsCount: 20,
            path: "1/65/3357/3361",
            url: "gifts-for-the-couple/",
          },
          {
            categoryID: 3363,
            name: "Gifts For The Groom",
            parent: 3357,
            productsCount: 6,
            path: "1/65/3357/3363",
            url: "gifts-for-the-groom/",
          },
          {
            categoryID: 3367,
            name: "Groomsmen Gifts",
            parent: 3357,
            productsCount: 8,
            path: "1/65/3357/3367",
            url: "groomsmen-gifts/",
          },
        ],
        url: "wedding-gifts/",
      },
      {
        categoryID: 3369,
        name: "Anniversary Gifts",
        parent: 65,
        productsCount: 44,
        path: "1/65/3369",
        subCategories: [
          {
            categoryID: 3373,
            name: "Anniversary Gifts For Women",
            parent: 3369,
            productsCount: 7,
            path: "1/65/3369/3373",
            url: "anniversary-gifts-for-women/",
          },
          {
            categoryID: 3371,
            name: "Anniversary Gifts For Men",
            parent: 3369,
            productsCount: 6,
            path: "1/65/3369/3371",
            url: "anniversary-gifts-for-men/",
          },
          {
            categoryID: 3375,
            name: "1st Anniversary Gifts",
            parent: 3369,
            productsCount: 18,
            path: "1/65/3369/3375",
            url: "1st-anniversary-gifts/",
          },
          {
            categoryID: 3377,
            name: "2nd Anniversary Gifts",
            parent: 3369,
            productsCount: 15,
            path: "1/65/3369/3377",
            url: "2nd-anniversary-gifts/",
          },
          {
            categoryID: 3379,
            name: "3rd Anniversary Gifts",
            parent: 3369,
            productsCount: 14,
            path: "1/65/3369/3379",
            url: "3rd-anniversary-gifts/",
          },
          {
            categoryID: 3381,
            name: "4th Anniversary Gifts",
            parent: 3369,
            productsCount: 2,
            path: "1/65/3369/3381",
            url: "4th-anniversary-gifts/",
          },
          {
            categoryID: 3383,
            name: "5th Anniversary Gifts",
            parent: 3369,
            productsCount: 2,
            path: "1/65/3369/3383",
            url: "5th-anniversary-gifts/",
          },
          {
            categoryID: 3385,
            name: "10th Anniversary Gifts",
            parent: 3369,
            productsCount: 2,
            path: "1/65/3369/3385",
            url: "10th-anniversary-gifts/",
          },
          {
            categoryID: 3387,
            name: "25th Anniversary Gifts",
            parent: 3369,
            productsCount: 1,
            path: "1/65/3369/3387",
            url: "25th-anniversary-gifts/",
          },
          {
            categoryID: 3389,
            name: "40th Anniversary Gifts",
            parent: 3369,
            productsCount: 4,
            path: "1/65/3369/3389",
            url: "40th-anniversary-gifts/",
          },
          {
            categoryID: 3391,
            name: "50th Anniversary Gifts",
            parent: 3369,
            productsCount: 1,
            path: "1/65/3369/3391",
            url: "50th-anniversary-gifts/",
          },
          {
            categoryID: 3393,
            name: "60th Anniversary Gifts",
            parent: 3369,
            productsCount: 1,
            path: "1/65/3369/3393",
            url: "60th-anniversary-gifts/",
          },
        ],
        url: "anniversary-gifts/",
      },
      {
        categoryID: 3405,
        name: "Mother'S Day Gifts",
        parent: 65,
        productsCount: 56,
        path: "1/65/3405",
        url: "mother-s-day-gifts/",
      },
      {
        categoryID: 3407,
        name: "Father'S Day Gifts",
        parent: 65,
        productsCount: 38,
        path: "1/65/3407",
        url: "father-s-day-gifts/",
      },
      {
        categoryID: 3353,
        name: "Housewarming Gifts",
        parent: 65,
        productsCount: 14,
        path: "1/65/3353",
        url: "housewarming-gifts/",
      },
      {
        categoryID: 3395,
        name: "Graduation Gifts",
        parent: 65,
        productsCount: 21,
        path: "1/65/3395",
        url: "graduation-gifts/",
      },
      {
        categoryID: 3397,
        name: "Christening Gifts",
        parent: 65,
        productsCount: 12,
        path: "1/65/3397",
        subCategories: [
          {
            categoryID: 3399,
            name: "Christening Gifts For Boys",
            parent: 3397,
            productsCount: 4,
            path: "1/65/3397/3399",
            url: "christening-gifts-for-boys/",
          },
          {
            categoryID: 3401,
            name: "Christening Gifts For Girls",
            parent: 3397,
            productsCount: 6,
            path: "1/65/3397/3401",
            url: "christening-gifts-for-girls/",
          },
        ],
        url: "christening-gifts/",
      },
      {
        categoryID: 3351,
        name: "Unique Gifts",
        parent: 65,
        productsCount: 64,
        path: "1/65/3351",
        url: "unique-gifts/",
      },
      {
        categoryID: 3403,
        name: "Baby Shower Gifts",
        parent: 65,
        productsCount: 9,
        path: "1/65/3403",
        url: "baby-shower-gifts/",
      },
      {
        categoryID: 3327,
        name: "Personalised Gifts",
        parent: 65,
        productsCount: 28,
        path: "1/65/3327",
        url: "personalised-gifts/",
      },
      {
        categoryID: 3355,
        name: "Corporate Gifts",
        parent: 65,
        productsCount: 21,
        path: "1/65/3355",
        url: "corporate-gifts/",
      },
      {
        categoryID: 3345,
        name: "Christmas Gifts",
        parent: 65,
        productsCount: 23,
        path: "1/65/3345",
        url: "christmas-gifts/",
      },
      {
        categoryID: 3409,
        name: "Valentine'S Day Gifts",
        parent: 65,
        productsCount: 42,
        path: "1/65/3409",
        subCategories: [
          {
            categoryID: 3413,
            name: "Valentines Gifts For Her",
            parent: 3409,
            productsCount: 24,
            path: "1/65/3409/3413",
            url: "valentines-gifts-for-her/",
          },
          {
            categoryID: 3411,
            name: "Valentines Gifts For Him",
            parent: 3409,
            productsCount: 13,
            path: "1/65/3409/3411",
            url: "valentines-gifts-for-him/",
          },
        ],
        url: "valentine-s-day-gifts/",
      },
      {
        categoryID: 3435,
        name: "Easter Gifts",
        parent: 65,
        productsCount: 249,
        path: "1/65/3435",
        url: "easter-gifts/",
      },
      {
        categoryID: 2805,
        name: "Gadget Gifts",
        parent: 65,
        productsCount: 401,
        path: "1/65/2805",
        subCategories: [
          {
            categoryID: 2807,
            name: "Alarm Clock Gifts",
            parent: 2805,
            productsCount: 32,
            path: "1/65/2805/2807",
            url: "alarm-clock-gifts/",
          },
          {
            categoryID: 2809,
            name: "Eco-friendly Gifts",
            parent: 2805,
            productsCount: 32,
            path: "1/65/2805/2809",
            url: "eco-friendly-gifts/",
          },
          {
            categoryID: 2811,
            name: "USB Gadget Gifts",
            parent: 2805,
            productsCount: 14,
            path: "1/65/2805/2811",
            url: "usb-gadget-gifts/",
          },
          {
            categoryID: 2813,
            name: "Hand Tools & DIY Gifts",
            parent: 2805,
            productsCount: 71,
            path: "1/65/2805/2813",
            url: "hand-tools-diy-gifts/",
          },
          {
            categoryID: 2815,
            name: "Automotive Gifts",
            parent: 2805,
            productsCount: 13,
            path: "1/65/2805/2815",
            url: "automotive-gifts/",
          },
          {
            categoryID: 2817,
            name: "Fun & Frivolous Gifts",
            parent: 2805,
            productsCount: 189,
            path: "1/65/2805/2817",
            url: "fun-frivolous-gifts/",
          },
          {
            categoryID: 2819,
            name: "Lighting Gifts",
            parent: 2805,
            productsCount: 28,
            path: "1/65/2805/2819",
            url: "lighting-gifts/",
          },
          {
            categoryID: 2821,
            name: "Remote Control Gifts",
            parent: 2805,
            productsCount: 13,
            path: "1/65/2805/2821",
            url: "remote-control-gifts/",
          },
        ],
        url: "gadget-gifts/",
      },
    ],
    url: "novelties-gifts/",
  },
  {
    categoryID: 35,
    name: "Musical Instruments",
    parent: 1,
    productsCount: 956,
    path: "1/35",
    subCategories: [
      {
        categoryID: 4011,
        name: "Hand Percussion Instrument",
        parent: 35,
        productsCount: 15,
        path: "1/35/4011",
        url: "hand-percussion-instrument/",
        subCategories: [
          {
            categoryID: 4013,
            name: "Kalimbas",
            parent: 4011,
            productsCount: 15,
            path: "1/35/4011/4013",
            url: "kalimbas/",
          },
        ],
      },
      {
        categoryID: 3981,
        name: "Ukulele",
        parent: 35,
        productsCount: 15,
        path: "1/35/3981",
        url: "ukulele/",
      },
      {
        categoryID: 42,
        name: "Piano",
        parent: 35,
        productsCount: 95,
        path: "1/35/42",
        subCategories: [
          {
            categoryID: 3281,
            name: "Piano Accessories",
            parent: 42,
            productsCount: 18,
            path: "1/35/42/3281",
            url: "piano-accessories/",
          },
          {
            categoryID: 3279,
            name: "Piano Keyboards",
            parent: 42,
            productsCount: 57,
            path: "1/35/42/3279",
            url: "piano-keyboards/",
          },
        ],
        url: "piano/",
      },
      {
        categoryID: 1953,
        name: "Instrument Accessories",
        parent: 35,
        productsCount: 247,
        path: "1/35/1953",
        subCategories: [
          {
            categoryID: 1957,
            name: "Keyboard Accessories",
            parent: 1953,
            productsCount: 21,
            path: "1/35/1953/1957",
            url: "keyboard-accessories/",
          },
          {
            categoryID: 1959,
            name: "Music Accessories",
            parent: 1953,
            productsCount: 202,
            path: "1/35/1953/1959",
            url: "music-accessories/",
          },
          {
            categoryID: 1961,
            name: "String Accessories",
            parent: 1953,
            productsCount: 5,
            path: "1/35/1953/1961",
            url: "string-accessories/",
          },
        ],
        url: "instrument-accessories/",
      },
      {
        categoryID: 1951,
        name: "Guitars",
        parent: 35,
        productsCount: 377,
        path: "1/35/1951",
        subCategories: [
          {
            categoryID: 1955,
            name: "Guitar Accessories",
            parent: 1951,
            productsCount: 66,
            path: "1/35/1951/1955",
            url: "guitar-accessories/",
          },
          {
            categoryID: 36,
            name: "Acoustic Guitars",
            parent: 1951,
            productsCount: 158,
            path: "1/35/1951/36",
            url: "acoustic-guitars/",
          },
          {
            categoryID: 37,
            name: "Electric Guitars",
            parent: 1951,
            productsCount: 125,
            path: "1/35/1951/37",
            url: "electric-guitars/",
          },
        ],
        url: "guitars/",
      },
      {
        categoryID: 41,
        name: "Violins",
        parent: 35,
        productsCount: 24,
        path: "1/35/41",
        url: "violins/",
      },
      {
        categoryID: 2697,
        name: "DJ Equipment",
        parent: 35,
        productsCount: 62,
        path: "1/35/2697",
        url: "dj-equipment/",
      },
      {
        categoryID: 40,
        name: "Drums",
        parent: 35,
        productsCount: 58,
        path: "1/35/40",
        url: "drums/",
      },
      {
        categoryID: 97,
        name: "Saxophones",
        parent: 35,
        productsCount: 8,
        path: "1/35/97",
        url: "saxophones/",
      },
      {
        categoryID: 39,
        name: "Cellos",
        parent: 35,
        productsCount: 3,
        path: "1/35/39",
        url: "cellos/",
      },
    ],
    url: "musical-instruments/",
  },
  {
    categoryID: 991,
    name: "Jewellery",
    parent: 1,
    productsCount: 1549,
    path: "1/991",
    subCategories: [
      {
        categoryID: 1375,
        name: "Earrings",
        parent: 991,
        productsCount: 278,
        path: "1/991/1375",
        url: "earrings/",
      },
      {
        categoryID: 1377,
        name: "Necklaces ",
        parent: 991,
        productsCount: 262,
        path: "1/991/1377",
        subCategories: [
          {
            categoryID: 3977,
            name: "coin pendant necklace",
            parent: 1377,
            productsCount: 25,
            path: "1/991/1377/3977",
            url: "coin-pendant-necklace/",
          },
        ],
        url: "necklaces/",
      },
      {
        categoryID: 4007,
        name: "Personalised",
        parent: 991,
        productsCount: 41,
        path: "1/991/4007",
        url: "personalised/",
      },
      {
        categoryID: 3973,
        name: "Rings",
        parent: 991,
        productsCount: 863,
        path: "1/991/3973",
        url: "rings/",
      },
    ],
    url: "jewellery/",
  },
  {
    categoryID: 3781,
    name: "LED Lights",
    parent: 1,
    productsCount: 2282,
    path: "1/3781",
    subCategories: [
      {
        categoryID: 4021,
        name: "Grow Lights",
        parent: 3781,
        productsCount: 23,
        path: "1/3781/4021",
        url: "grow-lights/",
      },
      {
        categoryID: 4019,
        name: "Industrial Light",
        parent: 3781,
        productsCount: 13,
        path: "1/3781/4019",
        url: "industrial-light/",
      },
      {
        categoryID: 326,
        name: "LED Lighting",
        parent: 3781,
        productsCount: 906,
        path: "1/3781/326",
        subCategories: [
          {
            categoryID: 4045,
            name: "Aquarium LED Lights",
            parent: 326,
            productsCount: 10,
            path: "1/3781/326/4045",
            url: "aquarium-led-lights/",
          },
          {
            categoryID: 3783,
            name: "LED Solar Lights",
            parent: 326,
            productsCount: 191,
            path: "1/3781/326/3783",
            url: "led-solar-lights/",
          },
          {
            categoryID: 3319,
            name: "LED Strip Lights",
            parent: 326,
            productsCount: 99,
            path: "1/3781/326/3319",
            url: "led-strip-lights/",
          },
          {
            categoryID: 3785,
            name: "LED Flashlights",
            parent: 326,
            productsCount: 62,
            path: "1/3781/326/3785",
            url: "led-flashlights/",
          },
          {
            categoryID: 3315,
            name: "LED Downlights",
            parent: 326,
            productsCount: 6,
            path: "1/3781/326/3315",
            url: "led-downlights/",
          },
          {
            categoryID: 3787,
            name: "LED Panel Lights ",
            parent: 326,
            productsCount: 10,
            path: "1/3781/326/3787",
            url: "led-panel-lights/",
          },
          {
            categoryID: 3789,
            name: "LED Grow Lights",
            parent: 326,
            productsCount: 29,
            path: "1/3781/326/3789",
            url: "led-grow-lights/",
          },
          {
            categoryID: 3317,
            name: "LED Spotlights",
            parent: 326,
            productsCount: 32,
            path: "1/3781/326/3317",
            url: "led-spotlights/",
          },
          {
            categoryID: 3311,
            name: "LED Bar Lights",
            parent: 326,
            productsCount: 69,
            path: "1/3781/326/3311",
            url: "led-bar-lights/",
          },
          {
            categoryID: 3791,
            name: "LED String Lights",
            parent: 326,
            productsCount: 101,
            path: "1/3781/326/3791",
            url: "led-string-lights/",
          },
          {
            categoryID: 3793,
            name: "LED Car Lights",
            parent: 326,
            productsCount: 93,
            path: "1/3781/326/3793",
            url: "led-car-lights/",
          },
        ],
        url: "led-lighting/",
      },
      {
        categoryID: 3795,
        name: "LED Globes",
        parent: 3781,
        productsCount: 24,
        path: "1/3781/3795",
        subCategories: [
          {
            categoryID: 3797,
            name: "Dimmable LED Globes",
            parent: 3795,
            productsCount: 2,
            path: "1/3781/3795/3797",
            url: "dimmable-led-globes/",
          },
          {
            categoryID: 3799,
            name: "E27 LED Globes",
            parent: 3795,
            productsCount: 11,
            path: "1/3781/3795/3799",
            url: "e27-led-globes/",
          },
          {
            categoryID: 3803,
            name: "GU10 LED Globes",
            parent: 3795,
            productsCount: 3,
            path: "1/3781/3795/3803",
            url: "gu10-led-globes/",
          },
          {
            categoryID: 3805,
            name: "MR16 LED Globes",
            parent: 3795,
            productsCount: 3,
            path: "1/3781/3795/3805",
            url: "mr16-led-globes/",
          },
        ],
        url: "led-globes/",
      },
      {
        categoryID: 3817,
        name: "LED Outdoor Lighting",
        parent: 3781,
        productsCount: 613,
        path: "1/3781/3817",
        subCategories: [
          {
            categoryID: 3819,
            name: "LED Motion sensor",
            parent: 3817,
            productsCount: 39,
            path: "1/3781/3817/3819",
            url: "led-motion-sensor/",
          },
          {
            categoryID: 3821,
            name: "LED Flashlights & Torches",
            parent: 3817,
            productsCount: 124,
            path: "1/3781/3817/3821",
            url: "led-flashlights-torches/",
          },
          {
            categoryID: 3823,
            name: "LED Floodlights",
            parent: 3817,
            productsCount: 8,
            path: "1/3781/3817/3823",
            url: "led-floodlights/",
          },
          {
            categoryID: 3825,
            name: "LED Solar Lamps",
            parent: 3817,
            productsCount: 72,
            path: "1/3781/3817/3825",
            url: "led-solar-lamps/",
          },
          {
            categoryID: 2229,
            name: "LED Garden Light",
            parent: 3817,
            productsCount: 187,
            path: "1/3781/3817/2229",
            url: "led-garden-light/",
          },
        ],
        url: "led-outdoor-lighting/",
      },
      {
        categoryID: 3831,
        name: "LED Lamps",
        parent: 3781,
        productsCount: 445,
        path: "1/3781/3831",
        subCategories: [
          {
            categoryID: 3833,
            name: "LED Bedside Lamps",
            parent: 3831,
            productsCount: 12,
            path: "1/3781/3831/3833",
            url: "led-bedside-lamps/",
          },
          {
            categoryID: 3835,
            name: "LED Wall Lamps",
            parent: 3831,
            productsCount: 14,
            path: "1/3781/3831/3835",
            url: "led-wall-lamps/",
          },
          {
            categoryID: 3837,
            name: "LED Table Lamps",
            parent: 3831,
            productsCount: 37,
            path: "1/3781/3831/3837",
            url: "led-table-lamps/",
          },
          {
            categoryID: 3841,
            name: "LED Desk Lamps",
            parent: 3831,
            productsCount: 64,
            path: "1/3781/3831/3841",
            url: "led-desk-lamps/",
          },
          {
            categoryID: 3845,
            name: "LED Night Lights",
            parent: 3831,
            productsCount: 216,
            path: "1/3781/3831/3845",
            url: "led-night-lights/",
          },
          {
            categoryID: 3847,
            name: "LED Underground Lamps",
            parent: 3831,
            productsCount: 1,
            path: "1/3781/3831/3847",
            url: "led-underground-lamps/",
          },
        ],
        url: "led-lamps/",
      },
    ],
    url: "led-lights/",
  },
  {
    categoryID: 109,
    name: "Fashion & Bags",
    parent: 1,
    productsCount: 433,
    path: "1/109",
    subCategories: [
      {
        categoryID: 4047,
        name: "Sleepwear",
        parent: 109,
        productsCount: 39,
        path: "1/109/4047",
        url: "sleepwear/",
      },
      {
        categoryID: 1915,
        name: "Men's Accessories",
        parent: 109,
        productsCount: 1,
        path: "1/109/1915",
        url: "men-s-accessories/",
      },
      {
        categoryID: 1925,
        name: "Women's Accessories",
        parent: 109,
        productsCount: 76,
        path: "1/109/1925",
        subCategories: [
          {
            categoryID: 4049,
            name: "Socks",
            parent: 1925,
            productsCount: 52,
            path: "1/109/1925/4049",
            url: "socks/",
          },
          {
            categoryID: 4051,
            name: "Stockings",
            parent: 1925,
            productsCount: 1,
            path: "1/109/1925/4051",
            url: "stockings/",
          },
        ],
        url: "women-s-accessories/",
      },
      {
        categoryID: 333,
        name: "Handbags, Luggage & Wallets",
        parent: 109,
        productsCount: 17,
        path: "1/109/333",
        url: "handbags-luggage-wallets/",
      },
    ],
    url: "fashion-bags/",
  },
  {
    categoryID: 70,
    name: "Computers & IT",
    parent: 1,
    productsCount: 7716,
    path: "1/70",
    subCategories: [
      {
        categoryID: 2973,
        name: "Apple Accessories",
        parent: 70,
        productsCount: 995,
        path: "1/70/2973",
        url: "apple-accessories/",
        subCategories: [
          {
            categoryID: 2589,
            name: "iPads & Tablets",
            parent: 2973,
            productsCount: 401,
            path: "1/70/2973/2589",
            url: "ipads-tablets/",
          },
          {
            categoryID: 1683,
            name: "iPad Accessories",
            parent: 2973,
            productsCount: 530,
            path: "1/70/2973/1683",
            url: "ipad-accessories/",
          },
        ],
      },
      {
        categoryID: 182,
        name: "Cd & Dvd Storage",
        parent: 70,
        productsCount: 82,
        path: "1/70/182",
        subCategories: [
          {
            categoryID: 2451,
            name: "Blank CDs, DVDs & BDs",
            parent: 182,
            productsCount: 25,
            path: "1/70/182/2451",
            url: "blank-cds-dvds-bds/",
          },
          {
            categoryID: 1711,
            name: "Storage Cases",
            parent: 182,
            productsCount: 52,
            path: "1/70/182/1711",
            url: "storage-cases/",
          },
        ],
        url: "cd-dvd-storage/",
      },
      {
        categoryID: 1731,
        name: "Headsets & Microphones",
        parent: 70,
        productsCount: 220,
        path: "1/70/1731",
        url: "headsets-microphones/",
      },
      {
        categoryID: 1763,
        name: "Scanners",
        parent: 70,
        productsCount: 74,
        path: "1/70/1763",
        url: "scanners/",
      },
      {
        categoryID: 1381,
        name: "Printer Cartridges",
        parent: 70,
        productsCount: 1039,
        path: "1/70/1381",
        subCategories: [
          {
            categoryID: 1575,
            name: "Canon Inkjet Cartridges",
            parent: 1381,
            productsCount: 54,
            path: "1/70/1381/1575",
            url: "canon-inkjet-cartridges/",
          },
          {
            categoryID: 1577,
            name: "Canon Laser Cartridges",
            parent: 1381,
            productsCount: 92,
            path: "1/70/1381/1577",
            url: "canon-laser-cartridges/",
          },
          {
            categoryID: 1579,
            name: "Epson Inkjet Cartridges",
            parent: 1381,
            productsCount: 159,
            path: "1/70/1381/1579",
            url: "epson-inkjet-cartridges/",
          },
          {
            categoryID: 1581,
            name: "Epson Laser Cartridges",
            parent: 1381,
            productsCount: 4,
            path: "1/70/1381/1581",
            url: "epson-laser-cartridges/",
          },
          {
            categoryID: 1583,
            name: "Brother Inkjet Cartridges",
            parent: 1381,
            productsCount: 48,
            path: "1/70/1381/1583",
            url: "brother-inkjet-cartridges/",
          },
          {
            categoryID: 1585,
            name: "Brother Laser Cartridges",
            parent: 1381,
            productsCount: 37,
            path: "1/70/1381/1585",
            url: "brother-laser-cartridges/",
          },
          {
            categoryID: 1587,
            name: "HP Inkjet Cartridges",
            parent: 1381,
            productsCount: 121,
            path: "1/70/1381/1587",
            url: "hp-inkjet-cartridges/",
          },
          {
            categoryID: 1589,
            name: "HP Laser Cartridges",
            parent: 1381,
            productsCount: 171,
            path: "1/70/1381/1589",
            url: "hp-laser-cartridges/",
          },
          {
            categoryID: 1591,
            name: "Dell Inkjet Cartridges",
            parent: 1381,
            productsCount: 8,
            path: "1/70/1381/1591",
            url: "dell-inkjet-cartridges/",
          },
          {
            categoryID: 1593,
            name: "Dell Laser Cartridges",
            parent: 1381,
            productsCount: 25,
            path: "1/70/1381/1593",
            url: "dell-laser-cartridges/",
          },
          {
            categoryID: 1595,
            name: "Lexmark Inkjet Cartridges",
            parent: 1381,
            productsCount: 24,
            path: "1/70/1381/1595",
            url: "lexmark-inkjet-cartridges/",
          },
          {
            categoryID: 1597,
            name: "Lexmark Laser Cartridges",
            parent: 1381,
            productsCount: 30,
            path: "1/70/1381/1597",
            url: "lexmark-laser-cartridges/",
          },
          {
            categoryID: 1599,
            name: "Samsung Inkjet Cartridges",
            parent: 1381,
            productsCount: 3,
            path: "1/70/1381/1599",
            url: "samsung-inkjet-cartridges/",
          },
          {
            categoryID: 1601,
            name: "Samsung Laser Cartridges",
            parent: 1381,
            productsCount: 79,
            path: "1/70/1381/1601",
            url: "samsung-laser-cartridges/",
          },
          {
            categoryID: 1605,
            name: "Xerox Laser Cartridges",
            parent: 1381,
            productsCount: 27,
            path: "1/70/1381/1605",
            url: "xerox-laser-cartridges/",
          },
          {
            categoryID: 2759,
            name: "Konica Laser Cartridges",
            parent: 1381,
            productsCount: 4,
            path: "1/70/1381/2759",
            url: "konica-laser-cartridges/",
          },
          {
            categoryID: 1609,
            name: "Kyocera Laser Cartridges",
            parent: 1381,
            productsCount: 52,
            path: "1/70/1381/1609",
            url: "kyocera-laser-cartridges/",
          },
          {
            categoryID: 1613,
            name: "Oki Laser Cartridges",
            parent: 1381,
            productsCount: 55,
            path: "1/70/1381/1613",
            url: "oki-laser-cartridges/",
          },
          {
            categoryID: 1383,
            name: "Ink Refills",
            parent: 1381,
            productsCount: 14,
            path: "1/70/1381/1383",
            url: "ink-refills/",
          },
        ],
        url: "printer-cartridges/",
      },
      {
        categoryID: 183,
        name: "Printers",
        parent: 70,
        productsCount: 270,
        path: "1/70/183",
        subCategories: [
          {
            categoryID: 3983,
            name: "3D Printer",
            parent: 183,
            productsCount: 19,
            path: "1/70/183/3983",
            url: "3d-printer/",
          },
          {
            categoryID: 2457,
            name: "Inkjet Printers",
            parent: 183,
            productsCount: 40,
            path: "1/70/183/2457",
            url: "inkjet-printers/",
          },
          {
            categoryID: 2459,
            name: "Laser Printers",
            parent: 183,
            productsCount: 65,
            path: "1/70/183/2459",
            url: "laser-printers/",
          },
          {
            categoryID: 2461,
            name: "Multifunction Printers",
            parent: 183,
            productsCount: 99,
            path: "1/70/183/2461",
            url: "multifunction-printers/",
          },
          {
            categoryID: 2463,
            name: "Photo Printers",
            parent: 183,
            productsCount: 10,
            path: "1/70/183/2463",
            url: "photo-printers/",
          },
          {
            categoryID: 2563,
            name: "Label Printers",
            parent: 183,
            productsCount: 18,
            path: "1/70/183/2563",
            url: "label-printers/",
          },
        ],
        url: "printers/",
      },
      {
        categoryID: 1709,
        name: "Computer Components",
        parent: 70,
        productsCount: 751,
        path: "1/70/1709",
        subCategories: [
          {
            categoryID: 299,
            name: "Cables",
            parent: 1709,
            productsCount: 153,
            path: "1/70/1709/299",
            url: "cables/",
          },
          {
            categoryID: 105,
            name: "Computer Adapters",
            parent: 1709,
            productsCount: 45,
            path: "1/70/1709/105",
            url: "computer-adapters/",
          },
          {
            categoryID: 1771,
            name: "Computer Cases",
            parent: 1709,
            productsCount: 97,
            path: "1/70/1709/1771",
            url: "computer-cases/",
          },
          {
            categoryID: 2707,
            name: "Computer CPUs",
            parent: 1709,
            productsCount: 30,
            path: "1/70/1709/2707",
            url: "computer-cpus/",
          },
          {
            categoryID: 1713,
            name: "Fans, Heatsinks, Cooling",
            parent: 1709,
            productsCount: 45,
            path: "1/70/1709/1713",
            url: "fans-heatsinks-cooling/",
          },
          {
            categoryID: 164,
            name: "Hard Drives",
            parent: 1709,
            productsCount: 99,
            path: "1/70/1709/164",
            url: "hard-drives/",
          },
          {
            categoryID: 1715,
            name: "Memory, RAM",
            parent: 1709,
            productsCount: 39,
            path: "1/70/1709/1715",
            url: "memory-ram/",
          },
          {
            categoryID: 2709,
            name: "Motherboards",
            parent: 1709,
            productsCount: 26,
            path: "1/70/1709/2709",
            url: "motherboards/",
          },
          {
            categoryID: 1727,
            name: "Optical Drives",
            parent: 1709,
            productsCount: 47,
            path: "1/70/1709/1727",
            url: "optical-drives/",
          },
          {
            categoryID: 1717,
            name: "Power Supplies",
            parent: 1709,
            productsCount: 79,
            path: "1/70/1709/1717",
            url: "power-supplies/",
          },
          {
            categoryID: 1719,
            name: "Sound Cards",
            parent: 1709,
            productsCount: 13,
            path: "1/70/1709/1719",
            url: "sound-cards/",
          },
          {
            categoryID: 1721,
            name: "USB Accessories",
            parent: 1709,
            productsCount: 22,
            path: "1/70/1709/1721",
            url: "usb-accessories/",
          },
          {
            categoryID: 1723,
            name: "Video Capture Cards",
            parent: 1709,
            productsCount: 15,
            path: "1/70/1709/1723",
            url: "video-capture-cards/",
          },
        ],
        url: "computer-components/",
      },
      {
        categoryID: 2511,
        name: "Flash Memory Cards",
        parent: 70,
        productsCount: 135,
        path: "1/70/2511",
        subCategories: [
          {
            categoryID: 2513,
            name: "SD & SDHC Cards",
            parent: 2511,
            productsCount: 78,
            path: "1/70/2511/2513",
            url: "sd-sdhc-cards/",
          },
          {
            categoryID: 2523,
            name: "Memory Card Readers",
            parent: 2511,
            productsCount: 57,
            path: "1/70/2511/2523",
            url: "memory-card-readers/",
          },
        ],
        url: "flash-memory-cards/",
      },
      {
        categoryID: 1733,
        name: "Input Peripherals",
        parent: 70,
        productsCount: 754,
        path: "1/70/1733",
        subCategories: [
          {
            categoryID: 1735,
            name: "Graphics Tablets",
            parent: 1733,
            productsCount: 53,
            path: "1/70/1733/1735",
            url: "graphics-tablets/",
          },
          {
            categoryID: 1737,
            name: "Keyboard-Mouse Combos",
            parent: 1733,
            productsCount: 92,
            path: "1/70/1733/1737",
            url: "keyboard-mouse-combos/",
          },
          {
            categoryID: 1739,
            name: "Keyboards",
            parent: 1733,
            productsCount: 198,
            path: "1/70/1733/1739",
            url: "keyboards/",
          },
          {
            categoryID: 163,
            name: "Wireless Mouse",
            parent: 1733,
            productsCount: 280,
            path: "1/70/1733/163",
            url: "wireless-mouse/",
          },
          {
            categoryID: 1741,
            name: "USB Hubs",
            parent: 1733,
            productsCount: 101,
            path: "1/70/1733/1741",
            url: "usb-hubs/",
          },
          {
            categoryID: 2505,
            name: "Mouse Pad",
            parent: 1733,
            productsCount: 27,
            path: "1/70/1733/2505",
            url: "mouse-pad/",
          },
        ],
        url: "input-peripherals/",
      },
      {
        categoryID: 999,
        name: "Webcams & Surveillance Cameras",
        parent: 70,
        productsCount: 200,
        path: "1/70/999",
        url: "webcams-surveillance-cameras/",
      },
      {
        categoryID: 136,
        name: "USB Flash Drives",
        parent: 70,
        productsCount: 235,
        path: "1/70/136",
        url: "usb-flash-drives/",
      },
      {
        categoryID: 165,
        name: "Computer Speakers & Subwoofers",
        parent: 70,
        productsCount: 192,
        path: "1/70/165",
        url: "computer-speakers-subwoofers/",
      },
      {
        categoryID: 1881,
        name: "Notebooks, Laptops & Accessories",
        parent: 70,
        productsCount: 758,
        path: "1/70/1881",
        subCategories: [
          {
            categoryID: 202,
            name: "Laptops, Notebooks",
            parent: 1881,
            productsCount: 213,
            path: "1/70/1881/202",
            url: "laptops-notebooks/",
          },
          {
            categoryID: 1743,
            name: "AC Adapters",
            parent: 1881,
            productsCount: 24,
            path: "1/70/1881/1743",
            url: "ac-adapters/",
          },
          {
            categoryID: 1769,
            name: "Bluetooth Dongles",
            parent: 1881,
            productsCount: 7,
            path: "1/70/1881/1769",
            url: "bluetooth-dongles/",
          },
          {
            categoryID: 1745,
            name: "Docking Stations",
            parent: 1881,
            productsCount: 26,
            path: "1/70/1881/1745",
            url: "docking-stations/",
          },
          {
            categoryID: 423,
            name: "Laptop Batteries",
            parent: 1881,
            productsCount: 227,
            path: "1/70/1881/423",
            url: "laptop-batteries/",
          },
          {
            categoryID: 1747,
            name: "Laptop Cases, Bags",
            parent: 1881,
            productsCount: 37,
            path: "1/70/1881/1747",
            url: "laptop-cases-bags/",
          },
          {
            categoryID: 1749,
            name: "Laptop Tables & Stands",
            parent: 1881,
            productsCount: 80,
            path: "1/70/1881/1749",
            url: "laptop-tables-stands/",
          },
          {
            categoryID: 467,
            name: "Laptop Accessories",
            parent: 1881,
            productsCount: 27,
            path: "1/70/1881/467",
            url: "laptop-accessories/",
          },
        ],
        url: "notebooks-laptops-accessories/",
      },
      {
        categoryID: 181,
        name: "Networking, Routers",
        parent: 70,
        productsCount: 468,
        path: "1/70/181",
        subCategories: [
          {
            categoryID: 1757,
            name: "Network Interface Cards",
            parent: 181,
            productsCount: 88,
            path: "1/70/181/1757",
            url: "network-interface-cards/",
          },
          {
            categoryID: 2527,
            name: "Wifi Extender & Repeater",
            parent: 181,
            productsCount: 86,
            path: "1/70/181/2527",
            url: "wifi-extender-repeater/",
          },
          {
            categoryID: 1753,
            name: "Modem Routers & Switches",
            parent: 181,
            productsCount: 15,
            path: "1/70/181/1753",
            url: "modem-routers-switches/",
          },
          {
            categoryID: 2721,
            name: "Network Storage",
            parent: 181,
            productsCount: 47,
            path: "1/70/181/2721",
            url: "network-storage/",
          },
          {
            categoryID: 1759,
            name: "Routers",
            parent: 181,
            productsCount: 135,
            path: "1/70/181/1759",
            url: "routers/",
          },
          {
            categoryID: 1761,
            name: "Wireless Adapter",
            parent: 181,
            productsCount: 151,
            path: "1/70/181/1761",
            url: "wireless-adapter/",
          },
        ],
        url: "networking-routers/",
      },
      {
        categoryID: 1725,
        name: "External Hard Drives",
        parent: 70,
        productsCount: 99,
        path: "1/70/1725",
        url: "external-hard-drives/",
      },
      {
        categoryID: 1883,
        name: "Monitors & Screens",
        parent: 70,
        productsCount: 203,
        path: "1/70/1883",
        subCategories: [
          {
            categoryID: 285,
            name: "Monitors",
            parent: 1883,
            productsCount: 160,
            path: "1/70/1883/285",
            url: "monitors/",
          },
          {
            categoryID: 1755,
            name: "Monitor Accessories",
            parent: 1883,
            productsCount: 17,
            path: "1/70/1883/1755",
            url: "monitor-accessories/",
          },
        ],
        url: "monitors-screens/",
      },
      {
        categoryID: 379,
        name: "Desktop Computers",
        parent: 70,
        productsCount: 249,
        path: "1/70/379",
        url: "desktop-computers/",
      },
      {
        categoryID: 1767,
        name: "Hard Disk Enclosures",
        parent: 70,
        productsCount: 55,
        path: "1/70/1767",
        url: "hard-disk-enclosures/",
      },
      {
        categoryID: 185,
        name: "Computer Software & Applications",
        parent: 70,
        productsCount: 5,
        path: "1/70/185",
        url: "computer-software-applications/",
      },
      {
        categoryID: 108,
        name: "Computer Accessories",
        parent: 70,
        productsCount: 57,
        path: "1/70/108",
        url: "computer-accessories/",
      },
    ],
    url: "computers-it/",
  },
];

export default categoryData;
