export const loadGtm = () => {
  const GTM_ID = "GTM-5ZJJKZ";
  const GTM_SCRIPT_SRC = `https://www.googletagmanager.com/gtm.js?id=${GTM_ID}`;

  if (!window.location.pathname.includes('/online')) {
    (function (w: any, d: any, s: any, l: any, i: any) {
      w[l] = w[l] || [];
      w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s),
        dl = l !== 'dataLayer' ? '&l=' + l : ''; // 显式指定 l 的类型
      j.async = true;
      j.src = `${GTM_SCRIPT_SRC}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', GTM_ID);
  }
};
