export function loadLiveChat() {
  setTimeout(function () {
    // 延迟加载liveChat SDK脚本
    const script = document.createElement('script');
    script.src = 'https://helpcentre.crazysales.com.au/__apps/livechat/assets/visitor/js/chat.js';
    script.defer = true;
    document.body.appendChild(script);

    // 延迟加载liveChat配置
    const configScript = document.createElement('script');
    const config = {
      path: 'https://helpcentre.crazysales.com.au/',
      position: 'bottom',
      color: 'red',
      timestamp: new Date().getTime(),
      sessionid: '-NA-',
      windowstate: 'minimized',
      botname: 'Crazysales',
    };
    configScript.innerHTML = `window._Payload = ${JSON.stringify(config)};`;
    document.body.appendChild(configScript);

    // 延迟加载liveChat的CSS样式
    const cssLink = document.createElement('link');
    cssLink.rel = 'stylesheet';
    cssLink.href = 'https://helpcentre.crazysales.com.au/__apps/livechat/assets/visitor/css/chat.red.bottom.css';
    document.head.appendChild(cssLink);
  }, 1); // 6000毫秒（即6秒）后加载脚本和样式
}