// 封装localStorage方法
export const localStorageService = {
  setItem: (key, value) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.setItem(key, value);
    }
  },
  getItem: (key) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      const value = localStorage.getItem(key);
      return value;
    }
    return null;
  },
  removeItem: (key) => {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.removeItem(key);
    }
  },
  clear: () => {
    if (typeof window !== 'undefined' && window.localStorage) {
      localStorage.clear();
    }
  }
};

// 封装sessionStorage方法
export const sessionStorageService = {
  setItem: (key, value) => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      sessionStorage.setItem(key, value);
    }
  },
  getItem: (key) => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      const value = sessionStorage.getItem(key);
      return JSON.parse(value);
    }
    return null;
  },
  removeItem: (key) => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      sessionStorage.removeItem(key);
    }
  },
  clear: () => {
    if (typeof window !== 'undefined' && window.sessionStorage) {
      sessionStorage.clear();
    }
  }
}
