import getConfig from 'next/config'
const { publicRuntimeConfig } = getConfig();
const apiDomain = publicRuntimeConfig.apiDomain;

export function loadMatomo() {
  setTimeout(function () {
    // 延迟加载Matomo脚本
    const MATOMO_SCRIPT_SRC =
      apiDomain === "https://www.crazysales.com.au"
        ? "https://matomo.crazysales.com.au/js/container_ZpFsvpMD.js"
        : "https://matomo-uat.crazysales.com.au/js/container_N462kcUb.js";

    let script = document.createElement('script')
    script.id = 'matomo'
    script.async = true
    script.innerHTML = `var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='${MATOMO_SCRIPT_SRC}'; s.parentNode.insertBefore(g,s);`

    document.head.appendChild(script)
  }, 1); // 2000毫秒（即2秒）后加载脚本和样式
}