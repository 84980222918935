import { ajax } from '../index'

/**
 * myaccount相关接口
 */
export default {
  // 刷新用户token（主要通过刷新来维持session有效期）
  getCurrentCustomerInfo() {
    return ajax.get('api', `/api/getCurrentCustomerInfo`, {})
  },

  // 模拟获取当前用户token，供开发使用
  getToken(params: any) {
    return ajax.post('api', `/api/v1/login`, params)
  },

  // 获取当前用户status信息
  customersStatus() {
    return ajax.get('api', `/api/v1/customers/status`, {})
  },

  // 获取customer 信息
  getCustomerInfo() {
    return ajax.get('api', `/api/v1/customer/info`, {})
  },

  // 修改customer 信息
  updateCustomerInfo(params: any) {
    return ajax.put('api', `/api/v1/customer/update`, params)
  },

  // 获取front subscribe数据
  getFrontSubscribe() {
    return ajax.get('api', `/api/v1/newsletters/front-subscribe`, {})
  },

  // 修改front subscribe数据
  updateFrontSubscribe(params: any) {
    return ajax.post('api', `/api/v1/newsletters/front-unsubscribe`, params)
  },

  // 获取subscribe数据
  subscribeInfo() {
    return ajax.get('api', `/api/v1/newsletters/info`, {})
  },

  // subscribe订阅
  subscribe(params: any) {
    return ajax.post('api', `/api/v1/newsletters/front-subscribe`, params)
  },

  // 未登录获取front subscribe数据
  loginOutGetFrontSubscribe(params: any) {
    return ajax.get('api', `/api/v1/newsletters/front-subscribe`, params)
  },

  // 订阅成功后获取信息
  frontSuccess() {
    return ajax.get('api', `/api/v1/newsletters/front-success`, {})
  },

  // 获取邮箱有没有订阅过
  checkSubscribe(params: any) {
    return ajax.post('api', `/api/v1/newsletters/check-subscribe`, params)
  },

  /**
   * 地址相关
   */

  // 获取地址列表
  customerAddresses() {
    return ajax.get('api', `/api/v1/address/list`, {})
  },

  // 添加地址
  addAddresses(params: any) {
    return ajax.post('api', `/api/v1/address/add`, params)
  },

  // 更新地址
  editAddresses(params: any) {
    return ajax.put('api', `/api/v1/address/${params.addressID}`, params)
  },

  // 获取suburb state
  getSubAndStatByPostcode(params: any) {
    return ajax.get('api', `/api/v1/address/suburb-state`, params)
  },

  // 删除地址
  deleteAddresses(id: string) {
    return ajax.delete('api', `/api/v1/address/${id}`, {})
  },

  // 设置默认地址
  setDefaultShippingAddress(params: any) {
    return ajax.post('api', `/api/v1/address/set-default`, params)
  },

  // 设置默认shipping地址
  setDefaultBillingAddress(params: any) {
    return ajax.post('api', `/api/v1/address/set-billing`, params)
  },

  // 获取登录日志
  loginDetail(params: any) {
    return ajax.get('api', `/api/v1/customer/login-detail`, params)
  },

  // 修改密码
  updatePassword(params: any) {
    return ajax.put('api', `/api/v1/customer/update-password`, params)
  },

  // 获取心愿单
  getWishList() {
    return ajax.get('api', `/api/v1/wishlist/list`, {})
  },

  // 删除心愿单
  deleteWishItem(id: string | number) {
    return ajax.delete('api', `/api/v1/wishlist/${id}`, {})
  },

  // 申请积分
  applyVoucher(params: any) {
    return ajax.post('api', `/api/v1/customer/apply-voucher`, params)
  },

  // 积分日志
  creditsList() {
    return ajax.get('api', `/api/v1/customer/credits`, {})
  }
}