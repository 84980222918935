import { ajax } from '../index'

/**
 * 系统相关接口
 */
export default {
  // 获取用户hotsearch数据
  getHotSearch() {
    return ajax.get('api', `/api/getHotSearch`, {})
  },

  // 获取用户trending search数据(搜索框)
  getTrendingSearch() {
    return ajax.get('api', `/api/v1/search/trending`, {})
  },

  // 获取搜索数据(搜索框)
  getSearchSuggest(dataObj: any) {
    return ajax.get('api', `/api/v1/search/suggest`, dataObj)
  },

  // 获取页面面包屑
  getBreadcrumb(type: string) {
    return ajax.get('api', `/api/v1/breadcrumb/${type}`, {})
  },

  // 获取csotd浮窗数据
  getCrazyDealOtd() {
    return ajax.get('api', `/api/v1/product/crazy-deal/otd`, {})
  },

  // 获取subcribe 背景图片数据
  getFullBanners() {
    return ajax.get('api', `/api/v1/banner/full-banners`, {})
  }
}